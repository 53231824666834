/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Input, InputPicker, Radio, RadioGroup, FlexboxGrid } from 'rsuite';
import TextField from '../../components/form/textfield';
import Field from '../../components/form/field';
import { CustomLoader } from '../../components/basic/loader';

import { showToast } from '../../utils/toast';
import { Datepicker } from '@ijavad805/react-datepicker';

import {
  handlePostSeekerPersonal,
  handleGetSeekerPersonal,
  handlePutSeekerPersonal
} from '../../api-handler';
import { SeekerPersonalFormSchema } from '../../models';

import { ROUTES } from '../../router';
import { base64ToJson, convertDateToSlashi, jsonToBase64 } from '../../utils/helpers';
import { UploadField } from '../../components/form/uploader';
import { useData } from '../../contexts/data';
import { CountryStateCity } from '../../components/form/country-state-city';
import MilitaryServiceOptions from '../../data/global/military-service.json';
import EducationDegreeOptions from '../../data/global/education-degree.json';
import MarriedOptions from '../../data/global/married.json';
import HealthStatusOptions from '../../data/seeker/health-status.json';
import ResidentialUseOptions from '../../data/seeker/residential-use.json';
import ReligionOptions from '../../data/global/religion.json';
import NationalityOptions from '../../data/global/nationality.json';

export const SeekerPersonal = (props: {
  disabledMode?: boolean;
  isAdmin?: boolean;
  username?: string;
}) => {
  const { setSeekerPersonal } = useData();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initial, setInitial] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});

  const [sex, setSex] = useState('آقا');

  const [timeStamp, setTimeStamp] = useState<Date>(new Date());
  const [loadingUpload, setLoadingUpload] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadedNIDPicUrl, setUploadedNIDPicUrl] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadedEDUPicUrl, setUploadedEDUPicUrl] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initEDUPicJson, setInitEDUPicJson] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initNIDPicJson, setInitNIDPicJson] = useState<any>([]);
  const normalizeFormValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fv: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const NIDPicJson: any = [...initNIDPicJson];
      console.log('initNIDPicJson', initNIDPicJson, 'should be empty now');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(uploadedNIDPicUrl).forEach((file: any) => {
        NIDPicJson.push(uploadedNIDPicUrl[file]);
      });
      console.log(uploadedNIDPicUrl, NIDPicJson, 'should have data(s)');
      const NIDPicEncoded = jsonToBase64(NIDPicJson);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const EDUPicJson: any = [...initEDUPicJson];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(uploadedEDUPicUrl).forEach((file: any) => {
        EDUPicJson.push(uploadedEDUPicUrl[file]);
      });
      console.log(EDUPicJson);
      const EDUPicEncoded = jsonToBase64(EDUPicJson);
      const payload = {
        ...fv,
        Sex: sex === 'آقا',
        CellPhone: `+98${fv.CellPhone.substring(1)}`,
        Birth_Date: new Date(timeStamp),
        CountryB: 'ایران',
        CountryE: fv['CountryE-t'] == 'ایران' ? 'ایران' : fv.CountryE ?? 'ایران',
        NIDPic: NIDPicEncoded,
        EDUPic: EDUPicEncoded
      };

      if (!payload.Sex) {
        payload.MilitaryService = 'انتخاب کنید';
      }
      if (payload.File) delete payload.File;
      if (payload['CountryE-t']) delete payload['CountryE-t'];

      return payload;
    },
    [uploadedNIDPicUrl, uploadedEDUPicUrl, initNIDPicJson, initEDUPicJson]
  );

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }
    console.log(formValue);
    if (!formValue) return;
    const payload = normalizeFormValue(formValue);
    if (!payload) return;
    setLoading(true);
    if (editMode) {
      // todo check update
      const updateRes = await handlePutSeekerPersonal(payload, 'Username', initial.Username);
      if (updateRes.ok) {
        showToast('مشخصات فردی به روزرسانی شد!', 'success');
        navigate(ROUTES.roles.reqSeeker.workBackground);
      }
      setLoading(false);
      return;
    }
    console.log(payload);
    const res = await handlePostSeekerPersonal(payload);
    if (res.ok) {
      showToast('مشخصات فردی ثبت شد!', 'success');
      navigate(ROUTES.roles.reqSeeker.workBackground);
      setLoading(false);
      return;
    }
    setLoading(false);
  }, [
    formValue,
    formError,
    timeStamp,
    sex,
    formRef,
    uploadedNIDPicUrl,
    uploadedEDUPicUrl,
    initNIDPicJson,
    initEDUPicJson
  ]);

  const handleGetInitialData = async (username: string | null | undefined) => {
    setUploadedNIDPicUrl({});
    setUploadedEDUPicUrl({});
    setLoading(true);
    const res = await handleGetSeekerPersonal(username);
    if (res.ok) {
      setInitial(res.data);
      setFormValue(res.data);
      setSex(res.data.Sex ? 'آقا' : 'خانم');
      setTimeStamp(res.data.Birth_Date);
      setEditMode(true);
      setInitEDUPicJson(base64ToJson(res.data?.EDUPic));
      setInitNIDPicJson(base64ToJson(res.data?.NIDPic));
      setSeekerPersonal(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetInitialData(props.isAdmin ? props.username : null);
    })();
  }, [props.username]);

  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <h4 className="text-h4">{props.isAdmin ? 'مشخصات فردی' : 'مشخصات فردی خود را وارد کنید'}</h4>

      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={SeekerPersonalFormSchema}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading}
        disabled={props.disabledMode}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="FirstName"
              label="نام"
              type="text"
              accepter={Input}
              full
              dir="rtl"
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="LastName"
              label="نام خانوادگی"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="NID"
              label="کد ملی"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              name="Sex"
              label="جنسیت"
              accepter={RadioGroup}
              value={sex}
              onChange={(value: string) => setSex(value)}>
              <div className="flex">
                <Radio className="w-[50%]" value={'خانم'}>
                  خانم
                </Radio>
                <Radio className="w-[50%]" value={'آقا'}>
                  آقا
                </Radio>
              </div>
            </Field>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              name="MilitaryService"
              label="وضعیت خدمت"
              accepter={InputPicker}
              data={MilitaryServiceOptions}
              dir="rtl"
              full
              disabled={sex !== 'آقا' || props.disabledMode || props.isAdmin}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="FathersName"
              label="نام پدر"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="Email"
              label="ایمیل"
              type="email"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={16} className="max-w-[100%] !px-0">
            <FlexboxGrid>
              <CountryStateCity
                hasCountry={true}
                setFormValue={setFormValue}
                stateOptions={{
                  default: formValue?.StateE,
                  name: 'StateE',
                  label: 'استان محل صدور شناسنامه',
                  colspan: 24,
                  sm: 24,
                  md: 8
                }}
                cityOptions={{
                  default: formValue?.CityE,
                  name: 'CityE',
                  label: 'شهر محل صدور شناسنامه',
                  colspan: 24,
                  sm: 24,
                  md: 8
                }}
                countryOptions={{
                  default: formValue?.CountryE,
                  name: 'CountryE',
                  label: 'کشور محل صدور شناسنامه',
                  colspan: 24,
                  sm: 24,
                  md: 8
                }}
              />
              {/* <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <Field
                  name="CountryE"
                  label="کشور محل صدور شناسنامه"
                  accepter={InputPicker}
                  data={[{ label: 'ایران', value: 'ایران' }]}
                  dir="rtl"
                  full
                  defaultValue="ایران"
                  disabled
                  req
                />
              </FlexboxGrid.Item> */}
              {/* <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <Field
                  name="StateE"
                  label="استان محل صدور شناسنامه"
                  accepter={InputPicker}
                  data={[{ label: 'استان 1', value: 'استان 1' }]}
                  dir="rtl"
                  full
                  req
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <Field
                  name="CityE"
                  label="شهر محل صدور شناسنامه"
                  accepter={InputPicker}
                  data={[{ label: 'شهر 1', value: 'شهر 1' }]}
                  dir="rtl"
                  full
                  req
                />
              </FlexboxGrid.Item> */}
            </FlexboxGrid>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CellPhone"
              label="شماره تلفن همراه"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder="09123456789"
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={16} className="max-w-[100%] !px-0">
            <FlexboxGrid>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <Field
                  name="CountryB"
                  label="کشور محل تولد"
                  accepter={InputPicker}
                  data={[{ label: 'ایران', value: 'ایران' }]}
                  dir="rtl"
                  full
                  defaultValue="ایران"
                  disabled
                  req
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <Field
                  name="Religion"
                  label="دین (مذهب)"
                  accepter={InputPicker}
                  data={ReligionOptions}
                  dir="rtl"
                  full
                  req
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <Field
                  name="Nationality"
                  label="ملیت"
                  accepter={InputPicker}
                  data={NationalityOptions}
                  dir="rtl"
                  full
                  req
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="RID"
              label="شماره شناسنامه"
              type="text"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              accepter={InputPicker}
              data={HealthStatusOptions}
              name="HealthStatus"
              label="وضعیت سلامت جسمانی"
              dir="rtl"
              full
              req
            />
          </FlexboxGrid.Item>
          {formValue.HealthStatus === 'دارای مشکل جسمانی' ? (
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="WhatHealthProblem"
                label="توضیحات مشکل جسمانی"
                type="text"
                accepter={Input}
                dir="rtl"
                full
                placeholder=""
                req
              />
            </FlexboxGrid.Item>
          ) : null}
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              accepter={InputPicker}
              data={ResidentialUseOptions}
              name="ResidentialUse"
              label="محل سکونت"
              dir="rtl"
              full
              req
            />
          </FlexboxGrid.Item>
          {formValue.ResidentialUse === 'سایر' ? (
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="WhatResidentialUse"
                label="توضیحات محل سکونت"
                type="text"
                accepter={Input}
                dir="rtl"
                full
                placeholder=""
                req
              />
            </FlexboxGrid.Item>
          ) : null}
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              accepter={InputPicker}
              data={MarriedOptions}
              name="Marriage"
              label="وضعیت تاهل"
              dir="rtl"
              full
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <div style={{ position: 'relative' }}>
              <Field
                accepter={Datepicker}
                className="font-fd"
                name="Birth_Date"
                lang={'fa'}
                theme={'green'}
                format={'D MMMM yyyy'}
                value={convertDateToSlashi(timeStamp)}
                input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(val: any) => setTimeStamp(new Date(val))}
                label="تاریخ تولد"
                dir="ltr"
                full
                req
              />
            </div>
          </FlexboxGrid.Item>
          <br />
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              accepter={InputPicker}
              data={EducationDegreeOptions}
              name="EduDegree"
              label="آخرین مدرک تحصیلی"
              dir="rtl"
              full
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] !hidden">
            <UploadField
              label="تصویر کارت ملی / رسید کارت ملی"
              full
              setLoadingUpload={setLoadingUpload}
              name="NIDPic"
              fileName="NIDPic"
              domain="Uploads/"
              initJson={initNIDPicJson}
              setInitJson={setInitNIDPicJson}
              uploadedUrl={uploadedNIDPicUrl}
              setUploadedUrl={setUploadedNIDPicUrl}
              readOnly={props.isAdmin ?? props.disabledMode}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <UploadField
              label="تصویر آخرین مدرک تحصیلی"
              req
              full
              setLoadingUpload={setLoadingUpload}
              name="EDUPic"
              fileName="EDUPic"
              domain="Uploads/"
              initJson={initEDUPicJson}
              setInitJson={setInitEDUPicJson}
              uploadedUrl={uploadedEDUPicUrl}
              setUploadedUrl={setUploadedEDUPicUrl}
              readOnly={props.isAdmin ?? props.disabledMode}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        {!props.disabledMode ? (
          <div className="flex justify-between">
            <Button
              appearance="primary"
              className="mr-auto mt-8"
              type="submit"
              loading={loading || loadingUpload}>
              مرحله بعد
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  );
};
