import { Button, Col, Form, Input, InputPicker, Schema } from 'rsuite';
import { useCallback, useRef, useState } from 'react';
import { FlexboxGrid } from 'rsuite';

const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  Type: StringType('').isRequired('الزامی است!'),
  Topic: StringType('').isRequired('الزامی است!'),
  Category: StringType('').isRequired('الزامی است!'),
  Duration: NumberType('').isRequired('الزامی است!')
});

import { useNavigate } from 'react-router-dom';
import { showToast } from '../../../utils/toast';
import Field from '../../../components/form/field';
import { useUser } from '../../../contexts/user';
import { handlePostConsultRequest } from '../../../api-handler/consult';
import TextField from '../../../components/form/textfield';
// import ConsultantFieldsOptions from '../../../data/consultant/consultant-fileds.json';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RequestConsultByUser = (props: { consultantUsername: string; social: any }) => {
  const { user } = useUser();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loading, setLoading] = useState(false);
  // const [editMode, setEditMode] = useState(false);
  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }

    if (!formValue) return;
    const payload = {
      Username: user.Username,
      Consultant: props.consultantUsername,
      ...formValue
    };
    setLoading(true);
    // if (editMode) {
    //   setLoading(false);
    //   navigate('/consultant/tnc');
    //   return;
    // }
    const res = await handlePostConsultRequest(payload);
    if (res.ok) {
      showToast('درخواست شما با موفقیت ثبت شد!', 'success');
      // showToast('فرم با موفقیت ثبت شد و پش از تایید توسط ادمین نمایش داده خواهد شد!', 'success');
      // todo: navigate to next step
      navigate('/user/requests');
    }
    setLoading(false);
  }, [formValue, formError, formRef, props.consultantUsername, user]);

  const makeConsultantFieldsOptions = useCallback(() => {
    if (!props.social.WorkCategory) return [];
    return props.social.WorkCategory.split(',').map((item: string) => {
      return { label: item, value: item };
    });
  }, [props.social]);
  const makeConsultantTypesOptions = useCallback(() => {
    if (!props.social.Type) return [];
    return props.social.Type.split(',').map((item: string) => {
      return { label: item, value: item };
    });
    // if (!props.social.Type) return [];

    // if (props.social?.Type === 'هردو') {
    //   return [
    //     { label: 'حضوری', value: 'حضوری' },
    //     { label: 'مجازی', value: 'مجازی' }
    //   ];
    // }
    // return [{ label: props.social.Type, value: props.social.Type }];
  }, [props.social]);
  return (
    <>
      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={model}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={12} className="max-w-[100%]">
            <TextField
              name="Topic"
              label="عنوان"
              dir="rtl"
              full
              req
              placeholder=""
              accepter={Input}
              type="text"
            />
            <div className="mt-5"></div>
            {/* todo: get from backend */}
            <Field
              name="Category"
              label="زمینه مشاوره"
              accepter={InputPicker}
              data={makeConsultantFieldsOptions}
              dir="rtl"
              full
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={12} className="max-w-[100%]">
            {/* todo: get from backend */}
            <Field
              name="Type"
              label="نوع مشاوره"
              accepter={InputPicker}
              data={makeConsultantTypesOptions}
              dir="rtl"
              full
              req
            />
            <div className="mt-5">
              <Field
                name="Duration"
                label="مدت زمان"
                accepter={InputPicker}
                data={[
                  // { label: 'نیم ساعته', value: 30 },
                  { label: 'یک ساعته', value: 60 }
                ]}
                dir="rtl"
                full
                req
              />
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <div className="flex justify-between">
          <Button appearance="primary" className="mr-auto mt-8" type="submit" loading={loading}>
            ثبت درخواست مشاوره
          </Button>
        </div>
      </Form>
    </>
  );
};
