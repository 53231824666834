import { Avatar, Form, Uploader } from 'rsuite';
import { UPLOAD_FILE_URL } from '../../core';
import { useCallback, useEffect, useState } from 'react';

export const UploadField = (props: {
  setLoadingUpload: (state: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUploadedUrl: any;
  // removeImage: (name: string, index: number) => void;
  label: string;
  full: boolean;
  req?: boolean;
  name: string; //NIDPic
  fileName: string; //
  domain: string; // Uploads/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initJson: any; //initNIDPicJson
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setInitJson: any; //setInitNIDPicJson
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadedUrl: any; //uploadedNIDPicUrl
  readOnly?: boolean;
  clearTrigger?: boolean;
  dontClear?: boolean;
  isSingle?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // handleFileUpload: (name: string, response: any, file: any) => void; //handleFileUpload(name);
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [fileList, setFileList] = useState([]);
  const handleClear = () => {
    setFileList([]);
  };

  useEffect(() => {
    if (!props.dontClear) {
      handleClear();
    }
  }, [props.initJson]);

  const removeImage = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (index: any) => {
      const temp = props.initJson;
      temp.splice(index, 1);
      props.setInitJson([...temp]);
    },
    [props.initJson, props.setInitJson]
  );
  const handleFileUpload = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function (response: any, file: any) {
      props.setLoadingUpload(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (props.isSingle) {
        console.log('single');
        props.setUploadedUrl({ [file.fileKey]: { name: file.name, url: response } });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props.setUploadedUrl((pre: any) => {
          return { ...pre, [file.fileKey]: { name: file.name, url: response } };
        });
      }
      // uploadedUrl[file.fileKey] = { name: file.name, url: response };
      // console.log(uploadedUrl);
    },
    [props.setLoadingUpload, props.setUploadedUrl, props.uploadedUrl, props.isSingle]
  );

  return (
    <Form.Group
      controlId={`${props.name}`}
      className={`mb-5 max-w-[100%] ${props.full ? 'full' : ''}`}>
      <Form.ControlLabel>
        {props.label} <span className="text-[--rs-error-500]">{props.req ? '*' : ''}</span>
      </Form.ControlLabel>
      {!props.readOnly ? (
        <Form.Control
          name="File"
          className="mb-4"
          // multiple
          fileList={fileList}
          listType="picture"
          accepter={Uploader}
          action={`${UPLOAD_FILE_URL}?FileName=${props.fileName}&Domain=${props.domain}`}
          onUpload={() => props.setLoadingUpload(true)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSuccess={(response: any, file: any) => {
            handleFileUpload(response, file);
          }}
          onError={() => {
            console.log('errror');
            props.setLoadingUpload(false);
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onRemove={(file: any) => {
            delete props.uploadedUrl[file.fileKey];
          }}
        />
      ) : null}
      <div className="mb-3">
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {Object.keys(props.initJson).map((key: any, index: number) => (
          <Avatar
            onClick={() => {
              return props.readOnly ? null : removeImage(index);
            }}
            className={`ml-2 ${props.readOnly ? '' : 'hover-opacity'} `}
            size="lg"
            key={index}
            src={`${UPLOAD_FILE_URL}?${props.initJson[key].url}&Attachment=False`}
          />
        ))}
      </div>
    </Form.Group>
  );
};
