import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export interface RequestInvestmentDataFormValueModel {
  ID?: String;
  Username?: String;
  InvestorName: String;
  CompanyName: String;
  CEOName: String;
  InvestmentInterests: String;
  InvestmentType: String;
  AvailableCapital: String;
  PostInvestmentRequest: String;
  ExperienceDetails: String;
  GeographicScope: String;
  CompanyAddress: String;
  CompanyWebsite: String;
  RequestReason: String;
}

export interface PostRequestInvestmentDataModel extends RequestInvestmentDataFormValueModel {
  FormID: String;
}

export const RequestInvestmentDataFormSchema = Schema.Model({
  InvestorName: StringType(''),
  CEOName: StringType('').isRequired('الزامی است!'),
  CEONationalID: StringType('').isRequired('الزامی است!'),
  InvestmentInterests: StringType('').isRequired('الزامی است!'),
  InvestmentType: StringType('').isRequired('الزامی است!'),
  AvailableCapital: StringType('').isRequired('الزامی است!'),
  PostInvestmentRequest: StringType('').isRequired('الزامی است!'),
  ExperienceDetails: StringType('').isRequired('الزامی است!'),
  GeographicScope: StringType('').isRequired('الزامی است!'),
  CompanyWebsite: StringType(''),
  CompanyAddress: StringType('').isRequired('الزامی است!'),
  RequestReason: StringType('').isRequired('الزامی است!')
});

// good
export interface InvestmentGoodFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
  Produced: Boolean;
  PICs: string;
}
export const defaultInvestmentGoodFormValue = {
  Name: '',
  Description: '',
  Produced: '',
  PICs: '',
  File: ''
};

export const defaultInvestmentGoodSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
  // PICs: StringType('').isRequired('الزامی است!')
});

export interface PostInvestmentGoodModel extends InvestmentGoodFormValueModel {
  // StudyNow: boolean;
  // Country: String;
  // Start_Date: String;
  // End_Date?: String;
}

// service
export interface InvestmentServiceFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
}
export const defaultInvestmentServiceFormValue = {
  Name: '',
  Description: ''
};

export const defaultInvestmentServiceSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
});

export interface PostInvestmentServiceModel extends InvestmentServiceFormValueModel {}
