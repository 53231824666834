/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { Col, FlexboxGrid } from 'rsuite';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  handleGetCorporationGood,
  handleGetCorporationIntro,
  handleGetCorporationPersonal,
  handleGetCorporationService,
  handleGetUserProfilePictureByUsername
} from '../../../api-handler';
import { CustomLoader } from '../../../components/basic/loader';
// import Logo from '../../../assets/images/brand/rcg2.svg';
import ConversationImg from '../../../assets/icons/corporations/conversation.svg';
import TeamImg from '../../../assets/icons/corporations/team.svg';
import WelcomeImg from '../../../assets/icons/corporations/welcome.svg';
import InspirationImg from '../../../assets/icons/corporations/inspiration.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation, Autoplay } from 'swiper';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
SwiperCore.use([Autoplay, Pagination]);

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
// import 'swiper/css/navigation';
import { faIR } from 'date-fns-jalali/locale';
import { FormatYear, base64ToJson } from '../../../utils/helpers';
import { UPLOAD_FILE_URL } from '../../../core';

export const SingleCorporation = () => {
  const { username } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const [activeKey, setActiveKey] = useState<any>('CorporationIntro');
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [corporationPersonal, setCorporationPersonal] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [corporationIntro, setCorporationIntro] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [corporationGood, setCorporationGood] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [corporationService, setCorporationService] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [corporationProfile, setCorporationProfile] = useState<any>({});

  const handleGetInitialData = useCallback(async () => {
    setLoading(true);
    const res = await handleGetCorporationPersonal(username);
    if (res.ok) {
      setCorporationPersonal(res.data);
    }
    const res2 = await handleGetCorporationIntro(username);
    if (res2.ok) {
      setCorporationIntro(res2.data);
    }
    const res3 = await handleGetCorporationGood(username);
    if (res3.ok) {
      setCorporationGood(res3.data);
    }
    const res4 = await handleGetCorporationService(username);
    if (res4.ok) {
      setCorporationService(res4.data);
    }
    const res5 = await handleGetUserProfilePictureByUsername(username ?? '');
    if (res5.ok) {
      setCorporationProfile(res5.data);
    }
    setLoading(false);
  }, [username]);

  useEffect(() => {
    handleGetInitialData();
  }, [username]);

  if (loading) return <CustomLoader />;
  return (
    <>
      <div
        className="bg-[--rs-primary-400] w-full mt-[-10px] relative flex items-center justify-center overflow-hidden"
        style={{ aspectRatio: 10 / 2.5, zIndex: 1 }}>
        <div
          className="w-full h-full absolute"
          style={{
            backgroundImage: 'linear-gradient(#00000000, #00000080)',
            overflow: 'hidden'
          }}></div>
        <img
          src={`${UPLOAD_FILE_URL}?${base64ToJson(corporationIntro?.PICLogo)[0]?.url}&Attachment=False`}
          alt=""
          className="w-full"
        />
      </div>
      <div className="ml-2 mr-2 md:mr-16 md:ml-16">
        <div className="mt-[-120px] relative flex items-start" style={{ zIndex: 2 }}>
          <div
            className="w-[160px] p-4 bg-[--rs-primary-100] shadow-md rounded-lg flex items-center justify-center"
            style={{ aspectRatio: 1 }}>
            {corporationProfile.Status === 'Accepted' ? (
              <img
                src={`${UPLOAD_FILE_URL}?${base64ToJson(corporationProfile.PictureLink)[0]?.url}&Attachment=False`}
                alt=""
                className="w-full"
              />
            ) : null}
          </div>
          <div className="mr-8 mt-6 text-[--rs-primary-50]">
            <h4 className="text-h3">{corporationPersonal?.FullName}</h4>
            <div className="flex items-center mt-3">
              <span className="text-b1 mb-0">
                تاسیس در {FormatYear(corporationPersonal?.DateOfEstablishment, faIR)}
              </span>
              <span className="text-b1 mb-0  mr-5">
                {corporationPersonal?.Country} - {corporationPersonal?.City}
              </span>
              <a
                className="text-b1 text-[--rs-primary-50] hover:text-[--rs-primary-50] mr-5"
                href={corporationPersonal?.Website}>
                {corporationPersonal?.Website}
              </a>
            </div>
          </div>
        </div>
        <FlexboxGrid className="mt-4">
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
            <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 md:p-8 w-full mt-3 flex items-center">
              <div className="w-[20%] ml-auto">
                <img className="w-full" src={InspirationImg} alt="" />
              </div>
              <div className="w-[75%]">
                <h5 className="text-h5">تاریخچه ما</h5>
                <p className="text-base mt-2" style={{ textAlign: 'justify' }}>
                  {corporationIntro?.History}
                </p>
              </div>
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
            <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 md:p-8 w-full mt-3 flex items-center">
              <div className="w-[20%] ml-auto">
                <img className="w-full" src={WelcomeImg} alt="" />
              </div>
              <div className="w-[75%]">
                <h5 className="text-h5">ارزش‌های ما</h5>
                <p className="text-base mt-2" style={{ textAlign: 'justify' }}>
                  {corporationIntro?.Value}
                </p>
              </div>
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
            <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 md:p-8 w-full mt-3 flex items-center">
              <div className="w-[20%] ml-auto">
                <img className="w-full" src={TeamImg} alt="" />
              </div>
              <div className="w-[75%]">
                <h5 className="text-h5">چشم انداز‌های ما</h5>
                <p className="text-base mt-2" style={{ textAlign: 'justify' }}>
                  {corporationIntro?.Value}
                </p>
              </div>
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
            <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 md:p-8 w-full mt-3 flex items-center">
              <div className="w-[20%] ml-auto">
                <img className="w-full" src={ConversationImg} alt="" />
              </div>
              <div className="w-[75%]">
                <h5 className="text-h5">فعالیت ما</h5>
                <p className="text-base mt-2" style={{ textAlign: 'justify' }}>
                  {corporationIntro?.Activity}
                </p>
              </div>
            </div>
          </FlexboxGrid.Item>
          {corporationIntro?.IntroVideo ? (
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%] mt-10">
              <h3 className="text-h3 text-center mb-3">ویدیو معرفی</h3>
              <div
                style={{
                  width: '100%',
                  height: 'auto',
                  position: 'relative',
                  display: 'block',
                  aspectRatio: 1160 / 652
                }}>
                <iframe
                  style={{
                    width: '100%',
                    height: 'auto',
                    position: 'relative',
                    display: 'block',
                    aspectRatio: 1160 / 652
                  }}
                  src={`https://www.aparat.com/video/video/embed/videohash/${corporationIntro?.IntroVideo.split('/').at(-1)}/vt/frame`}
                  allowFullScreen={true}></iframe>
              </div>
            </FlexboxGrid.Item>
          ) : null}
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%] mt-10">
            <h3 className="text-h3 text-center mb-3">کالا‌های ما</h3>
            <Swiper
              spaceBetween={20}
              slidesPerView={4}
              loop
              autoplay={{
                delay: 2500,
                disableOnInteraction: true
              }}
              modules={[Autoplay]}>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {corporationGood.map((item: any, index: number) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-0 overflow-hidden relative">
                    {item.Produced ? (
                      <div
                        className="bg-[--rs-primary-500] text-[--rs-primary-50] text-center px-4 py-3 absolute"
                        style={{
                          transform: 'rotate(45deg)',
                          zIndex: 2,
                          right: '-35%',
                          top: '5%',
                          width: '100%'
                        }}>
                        تولید شده
                      </div>
                    ) : null}
                    <Swiper
                      spaceBetween={0}
                      slidesPerView={1}
                      modules={[Pagination]}
                      pagination={{
                        clickable: true
                      }}>
                      {base64ToJson(item.PICs, true).map((item2: any, index2: number) => {
                        if (!item2) {
                          return (
                            <SwiperSlide key={index2}>
                              <div
                                className="w-full bg-[--rs-primary-200] flex items-center justify-center"
                                style={{ aspectRatio: 1, overflow: 'hidden' }}></div>
                            </SwiperSlide>
                          );
                        }
                        return (
                          <SwiperSlide key={index2}>
                            <div
                              className="w-full bg-[--rs-primary-200] flex items-center justify-center"
                              style={{ aspectRatio: 1, overflow: 'hidden' }}>
                              <img
                                src={`${UPLOAD_FILE_URL}?${item2.url}&Attachment=False`}
                                className="w-full"
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    <div className="p-4 pb-6">
                      <h6 className="text-h6 mb-2">{item.Name}</h6>
                      <p>{item.Description}</p>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            sm={24}
            md={24}
            className="max-w-[100%] mt-10 mb-6">
            <h3 className="text-h3 text-center mb-3">خدمات ما</h3>
            <Swiper
              spaceBetween={20}
              slidesPerView={4}
              loop
              autoplay={{
                delay: 2500,
                disableOnInteraction: true
              }}
              modules={[Autoplay]}>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {corporationService.map((item: any, index: number) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-0 overflow-hidden relative">
                    {item.Produced ? (
                      <div
                        className="bg-[--rs-primary-500] text-[--rs-primary-50] text-center px-4 py-3 absolute"
                        style={{
                          transform: 'rotate(45deg)',
                          zIndex: 2,
                          right: '-35%',
                          top: '5%',
                          width: '100%'
                        }}>
                        تولید شده
                      </div>
                    ) : null}
                    <Swiper
                      spaceBetween={0}
                      slidesPerView={1}
                      modules={[Pagination]}
                      pagination={{
                        clickable: true
                      }}>
                      {base64ToJson(item.PICs, true).map((item2: any, index2: number) => {
                        if (!item2) {
                          return (
                            <SwiperSlide key={index2}>
                              <div
                                className="w-full bg-[--rs-primary-200] flex items-center justify-center"
                                style={{ aspectRatio: 1, overflow: 'hidden' }}></div>
                            </SwiperSlide>
                          );
                        }
                        return (
                          <SwiperSlide key={index2}>
                            <div
                              className="w-full bg-[--rs-primary-200] flex items-center justify-center"
                              style={{ aspectRatio: 1, overflow: 'hidden' }}>
                              <img
                                src={`${UPLOAD_FILE_URL}?${item2.url}&Attachment=False`}
                                className="w-full"
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    <div className="p-4 pb-6">
                      <h6 className="text-h6 mb-2">{item.Name}</h6>
                      <p>{item.Description}</p>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    </>
  );
};
