/* eslint-disable @typescript-eslint/no-explicit-any */
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import WorkshopImage from '../../../assets/images/workshop-1.png';
SwiperCore.use([Autoplay, Pagination]);
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Button, Col, FlexboxGrid } from 'rsuite';
// import { UPLOAD_FILE_URL } from '../../../core';
// import { base64ToJson } from '../../../utils/helpers';
import { useCallback, useEffect, useState } from 'react';
// import { handleGetCorporationGood } from '../../../api-handler';
import { CustomLoader } from '../../../components/basic/loader';
// import { RegisterEvent } from './register-event';
// import { FormatDateTime } from '../../../utils/helpers';
// import { faIR } from 'date-fns-jalali/locale';
import { useNavigate } from 'react-router-dom';
// import { FormatDateTime } from '../../../utils/helpers';
// import { faIR } from 'date-fns-jalali/locale';
import { handleGetWorkshops } from '../../../api-handler/workshop';

export const Events = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [eventList, setEventList] = useState<any>([]);

  const handleGetInitialData = useCallback(async () => {
    setLoading(true);
    const res3 = await handleGetWorkshops();
    if (res3.ok) {
      setEventList(res3.data);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    handleGetInitialData();
  }, []);
  if (loading) return <CustomLoader />;
  return (
    <div>
      <FlexboxGrid className="mb-8">
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
          <h1 className="text-h2 text-center mt-8 mb-6">رویداد‌ها</h1>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          sm={24}
          md={24}
          className="max-w-[90%] mx-auto !p-0 !mt-[-10px]">
          <Swiper
            spaceBetween={10}
            // loop={true}
            slidesPerView={2}
            breakpoints={{
              // when window width is <= 499px
              499: {
                slidesPerView: 2,
                spaceBetween: 8
              },
              // when window width is <= 999px
              768: {
                slidesPerView: 3,
                spaceBetween: 10
              },
              992: {
                slidesPerView: 4,
                spaceBetween: 10
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 10
              }
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true
            }}
            modules={[Autoplay]}>
            {eventList.map((event: any, index: number) => {
              return (
                <SwiperSlide
                  key={index}
                  style={
                    new Date() > new Date(event.RegistrationDeadline)
                      ? { filter: 'grayscale(100)', opacity: 0.8 }
                      : {}
                  }
                  className="bg-[--rs-white] p-0 overflow-hidden relative flex flex-col rounded-md border-[1px] border-[--rs-primary-300]"
                  onClick={() => navigate(`/events/${event.ID}`)}>
                  <div
                    className="w-full bg-[--rs-primary-200] flex flex-col aspect-[.7] sm:aspect-[.7] md:aspect-[.7] cursor-pointer"
                    style={{ overflow: 'hidden' }}>
                    <img src={WorkshopImage} className="min-w-[100%] min-h-[100%]" alt="" />
                  </div>
                  <div className="px-3 pt-4 pb-2 bg-[--rs-primary-100]">
                    <div
                      className="text-[10px] font-bold px-0 py-1 mb-2 text-[var(--rs-primary-800)] rounded-md w-fit"
                      style={{ opacity: '90%' }}>
                      {event.DateAndTime}
                      {/* {FormatDateTime(event.DateTime, faIR)} */}
                    </div>
                    <h5 className="text-[--rs-primary-700]">{event.EventTitle}</h5>
                    {/* <p className="text-b3 py-2 rounded-md mt-2">{event.Subtitle}</p> */}
                    <div className="flex mt-2 mb-2 flex-row items-center justify-center">
                      <Button
                        appearance="primary"
                        type="button"
                        className="block"
                        onClick={() => {
                          navigate(`/events/${event.ID}`);
                        }}>
                        {new Date() > new Date(event.RegistrationDeadline)
                          ? 'مشاهده'
                          : 'نام‌نویسی در رویداد'}
                      </Button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};
