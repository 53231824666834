// import { LoginOTP } from './forms/login-otp';
import { LoginAdmin } from './forms/admin-login';
import { LoginPassword } from './forms/login-password';
import { LoginPhone } from './forms/login-phone';
import { SignupPersonal } from './forms/signup-personal';
// import { SignupCorporate } from './forms/signup-corporate';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setStep: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  step: any;
  isAdminMode: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const titles: any = {
  signup: 'ثبت نام',
  login: 'ورود یا ثبت نام',
  password: 'رمز عبور خود را وارد کنید',
  admin: 'ورود ادمین'
};
export const LoginForm = (props: Props) => {
  return (
    <>
      {/* رمز عبور خور را وارد کنید */}
      {/* کد یک‌بار مصرف را وارد کنید */}
      {/* ورود یا ثبت نام */}
      <h2 className="text-h2 text-primary text-dark text-center">
        {props.isAdminMode ? titles.admin : titles[props.step.action]}
      </h2>
      {props.isAdminMode ? (
        <div className="md:px-4">
          <LoginAdmin />
        </div>
      ) : (
        <div className="md:px-4">
          {props.step.action === 'signup' ? (
            <SignupPersonal step={props.step} setStep={props.setStep} />
          ) : null}

          {props.step.action === 'login' ? (
            <LoginPhone step={props.step} setStep={props.setStep} />
          ) : null}

          {props.step.action === 'password' ? (
            <LoginPassword step={props.step} setStep={props.setStep} />
          ) : null}

          {/* <LoginPassword /> */}

          {/* <LoginOTP /> */}
        </div>
      )}

      {/* <SignupCorporate /> */}
      {/* <SignupPersonal /> */}
    </>
  );
};
