import { Button, Loader } from 'rsuite';
import { useEffect, useState } from 'react';
// import { FlexboxGrid } from 'rsuite';
import {
  FormatDate,
  SESSION_STATUS_CONVERTOR,
  durationToHour,
  sortData
} from '../../utils/helpers';

import { Table, Cell, Column, HeaderCell } from 'rsuite-table';
import {
  handleGetConsultRequestsOfAConsultant,
  handleGetConsultRequestsOfAUser
} from '../../api-handler';
import { faIR } from 'date-fns-jalali/locale';
// import { format } from 'date-fns-jalali';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/user';
// import { faIR } from 'date-fns/locale';
// import { CustomLoader } from './../../components/basic/loader';

// todo: request for consults
export const ConsultRequests = () => {
  const navigate = useNavigate();
  const { user, isUserRole } = useUser();
  const [data, setData] = useState([]);
  // const [loading] = useState(false);
  const [sortColumn, setSortColumn] = useState('id');
  const [sortType, setSortType] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const [defaultData, setDefaultData] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setTableLoading(true);
    setTimeout(() => {
      setTableLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };
  useEffect(() => {
    (async () => {
      setTableLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let res: any = null;
      if (isUserRole('Consultant')) {
        res = await handleGetConsultRequestsOfAConsultant(user.Username);
      } else {
        res = await handleGetConsultRequestsOfAUser(user.Username);
      }
      if (res.ok) {
        setData(res.data);
      }
      setTableLoading(false);
    })();
  }, []);

  return (
    <>
      <h4 className="text-h4 mb-6">لیست درخواست های مشاوره</h4>

      <Table
        data={sortData(data, sortColumn, sortType)}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={tableLoading}
        // affixHeader
        renderLoading={() => (
          <div className="flex items-center justify-center h-[100%]">
            <Loader size="md" />
          </div>
        )}
        renderEmpty={() => (
          <div className="flex items-center justify-center h-[100%]">درخواستی موجود نیست</div>
        )}
        autoHeight
        affixHorizontalScrollbar>
        <Column width={100} verticalAlign="middle" align="center">
          <HeaderCell>شماره</HeaderCell>
          <Cell dataKey="ID" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نام کاربری</HeaderCell>
          <Cell dataKey="Username" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>{isUserRole('Consultant') ? 'نام' : 'نام مشاور'}</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return (
                <>
                  {' '}
                  {isUserRole('Consultant')
                    ? rowData.UsernameInfo?.FirstName
                    : rowData.ConsultantInfo?.FirstName}
                </>
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>
            {isUserRole('Consultant') ? 'نام خانوادگی' : 'نام خانوادگی مشاور'}
          </HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return (
                <>
                  {' '}
                  {isUserRole('Consultant')
                    ? rowData.UsernameInfo?.LastName
                    : rowData.ConsultantInfo?.LastName}
                </>
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>موضوع</HeaderCell>
          <Cell dataKey="Topic" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نوع مشاوره</HeaderCell>
          <Cell dataKey="Type" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center ">
          <HeaderCell>مدت زمان</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return durationToHour(rowData.Duration);
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>زمینه مشاوره</HeaderCell>
          <Cell dataKey="Category" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>وضعیت</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {SESSION_STATUS_CONVERTOR[rowData.Status]}</>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center font-fd">
          <HeaderCell>زمان جلسه</HeaderCell>
          <Cell dataKey="" className="font-fd">
            {(rowData) => {
              return (
                <>
                  {!rowData.SessionTimeStamp
                    ? 'تنظیم نشده'
                    : FormatDate(rowData.SessionTimeStamp, faIR)}
                </>
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>مشاهده</HeaderCell>
          <Cell dataKey="">
            {(rowData) => (
              <div className="flex">
                {rowData.Status == 'Done' ? (
                  <Button
                    appearance="primary"
                    size="xs"
                    type="button"
                    className="ml-1"
                    disabled={
                      !isUserRole('Consultant') && rowData.Status === 'PendingConsultant'
                        ? true
                        : false
                    }
                    onClick={() => {
                      if (isUserRole('Consultant')) {
                        return navigate(`/consultant/meeting-report/${rowData.ID}`);
                      }
                      return navigate(`/user/meeting-report/${rowData.ID}`);
                    }}>
                    نظرسنجی
                  </Button>
                ) : null}

                <Button
                  appearance="primary"
                  size="xs"
                  type="button"
                  disabled={
                    !isUserRole('Consultant') && rowData.Status === 'PendingConsultant'
                      ? true
                      : false
                  }
                  onClick={() => {
                    if (!isUserRole('Consultant')) {
                      return navigate(`/user/consult-sessions/${rowData.ID}`);
                    }
                    return navigate(`/consultant/consult-sessions/${rowData.ID}`);
                  }}>
                  مشاهده
                </Button>
              </div>
            )}
          </Cell>
        </Column>
      </Table>
    </>
  );
};
