import { CustomProvider } from 'rsuite';
import faIR from 'rsuite/locales/fa_IR';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './css/tostify-colors.css';
import RouteHandler from './components/route-handler/route-handler';
import { UserProvider } from './contexts/user';
import { ModalProvider } from './contexts/modal';
import { DataProvider } from './contexts/data';
// import { ConsultantConsultationBackground } from './pages/consultants/consultant-consultation-background';
// import { ConsultantEducationBackground } from './pages/consultants/consultant-education-background';
// import { ConsultantPersonal } from './pages/consultants/consultant-personal';
// import { ConsultantWorkBackground } from './pages/consultants/consultant-work-background';

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <DataProvider>
          <ModalProvider>
            <CustomProvider locale={faIR} rtl={true} theme="light">
              <RouteHandler />
              <ToastContainer
                theme="light"
                position="bottom-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </CustomProvider>
          </ModalProvider>
        </DataProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
