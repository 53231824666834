import { Header } from './../../components/headers/header';
import { Col, FlexboxGrid } from 'rsuite';
import { LayoutProps } from './props';

export const LayoutFullWithoutBox = (props: LayoutProps) => (
  <>
    <Header />
    <FlexboxGrid className="w-full mt-12 mb-5">
      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24}>
        <div className="ml-2 mr-2 md:mr-16 md:ml-16">{props.children}</div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  </>
);
