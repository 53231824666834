import { Button, Checkbox, Col, FlexboxGrid, Form, Input } from 'rsuite';
import TextField from '../../../../components/form/textfield';
import { useCallback, useEffect, useRef, useState } from 'react';
import Field from '../../../../components/form/field';
import TextAreaField from '../../../../components/form/textarea-field';
import { showToast } from '../../../../utils/toast';
import { handlePostRecruitmentGood, handlePutRecruitmentGood } from '../../../../api-handler';
import {
  PostRecruitmentGoodModel,
  defaultRecruitmentGoodFormValue,
  defaultRecruitmentGoodSchema
} from '../../../../models';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../../../router';
import { base64ToJson, jsonToBase64 } from '../../../../utils/helpers';
import { UploadField } from '../../../../components/form/uploader';

export const GoodForm = (props: {
  readonly?: boolean;
  handleAddDoingData: (newData: PostRecruitmentGoodModel) => void;
  defaultValue?: PostRecruitmentGoodModel | null;
  hideNextButton?: boolean;
  forceReadOnly?: boolean;
  disabledMode?: boolean;
  hasEditMode?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  isAdmin: boolean;
  onDelete: (id: string | number) => void;
}) => {
  const [searchParams] = useSearchParams();
  const [FormID] = useState(searchParams.get('FormID'));

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [disabledMode, setDisabledMode] = useState(props.hasEditMode);
  const [forceReadOnly] = useState(props.forceReadOnly);
  const [readOnly, setReadOnly] = useState(props.readonly);
  const [produced, setProduced] = useState(props.defaultValue?.Produced);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});

  const [loadingUpload, setLoadingUpload] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>(
    props.defaultValue ?? defaultRecruitmentGoodFormValue
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadedPICsUrl, setUploadedPICsUrl] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initPICsJson, setInitPICsJson] = useState<any>(
    base64ToJson(props.defaultValue?.PICs ?? '')
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [defaultValue, setDefaultValue] = useState<any>(
    props.defaultValue ?? defaultRecruitmentGoodFormValue
  );

  const handleSubmit = useCallback(
    async (isNext: boolean, updateMode: boolean) => {
      if (formRef.current && !formRef.current.check()) {
        console.log('formError', formError);
        return;
      }

      if (!formValue) return;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const PICsJson: any = [...initPICsJson];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(uploadedPICsUrl).forEach((file: any) => {
        PICsJson.push(uploadedPICsUrl[file]);
      });
      const PICsEncoded = jsonToBase64(PICsJson);
      const payload = {
        ...formValue,
        Produced: produced === true,
        PICs: PICsEncoded,
        FormID
      };
      if (payload.File) delete payload.File;

      setLoading(true);
      if (updateMode) {
        if (payload.Username) delete payload.Username;
        const ress = await handlePutRecruitmentGood(payload, 'ID', payload.ID);
        if (ress.ok) {
          showToast('فرم به روزرسانی شد!', 'success');
          setDisabledMode(true);
          // setForceReadOnly(true);
          setReadOnly(true);
          setLoading(false);
          setDefaultValue(payload);
          if (isNext) {
            navigate(`${ROUTES.roles.reqRecruitment.service}?FormID=${FormID}`);
            return;
          }
        }
        setLoading(false);
        return;
      }
      const res = await handlePostRecruitmentGood(payload);
      setLoading(false);
      if (res.ok) {
        showToast('فرم ثبت شد!', 'success');
        // or getting submitted data from backend
        if (isNext) {
          navigate(`${ROUTES.roles.reqRecruitment.service}?FormID=${FormID}`);
          return;
        }
        props.handleAddDoingData({ ...payload, ID: res.data.ID });
        setFormValue(defaultRecruitmentGoodFormValue);
        setProduced(false);
        setUploadedPICsUrl({});
        setInitPICsJson([]);
      }
    },
    [formValue, formError, formRef, uploadedPICsUrl, initPICsJson, produced, FormID]
  );

  useEffect(() => {
    console.log(formValue);
  }, [formValue]);

  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={defaultRecruitmentGoodSchema}
      onSubmit={() => handleSubmit(false, false)}
      formValue={formValue}
      checkTrigger="change"
      className="mt-6 text-dark"
      readOnly={forceReadOnly ?? loading}
      disabled={disabledMode}>
      <FlexboxGrid className="bg-[--rs-primary-50] rounded-md border-[1px] border-[--rs-primary-500] p-3">
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%] !px-0">
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
            <TextField
              name="Name"
              label="نام کالا"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
              req
              readOnly={readOnly}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%] !px-0">
            <Field
              name="Produced"
              onChange={(value: string | number, checked: boolean) => {
                setProduced(checked);
              }}
              label=""
              checked={produced}
              accepter={Checkbox}
              dir="rtl"
              full
              readOnly={readOnly}>
              کالا را تولید کرده ام.
            </Field>
          </FlexboxGrid.Item>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
          <TextAreaField
            name="Description"
            label={'توضیحات'}
            dir="rtl"
            full
            req
            rows={5}
            placeholder=""
            readOnly={readOnly}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={16} className="max-w-[100%]">
          <UploadField
            label="تصاویر نمونه کالا"
            req
            full
            setLoadingUpload={setLoadingUpload}
            name="PICs"
            fileName="PICs"
            domain="Uploads/"
            initJson={initPICsJson}
            setInitJson={setInitPICsJson}
            uploadedUrl={uploadedPICsUrl}
            setUploadedUrl={setUploadedPICsUrl}
            readOnly={readOnly}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
          {props.isLast && !props.isAdmin ? (
            <div className="flex justify-between">
              {defaultValue.ID ? (
                <Button
                  appearance="primary"
                  type="button"
                  onClick={() => {
                    setDisabledMode(false);
                    // setForceReadOnly(false);
                    setReadOnly(false);
                  }}
                  loading={loading || loadingUpload}>
                  ویرایش
                </Button>
              ) : null}
              {!props.isFirst ? (
                <Button
                  appearance="ghost"
                  type="button"
                  className="mr-auto"
                  onClick={() => {
                    props.onDelete(defaultValue.ID ?? null);
                  }}
                  loading={loading || loadingUpload}>
                  حذف
                </Button>
              ) : null}
            </div>
          ) : null}
          {props.hasEditMode && !props.isAdmin ? (
            <div>
              {disabledMode ? (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      setDisabledMode(false);
                      // setForceReadOnly(false);
                      setReadOnly(false);
                    }}
                    loading={loading || loadingUpload}>
                    ویرایش
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      props.onDelete(defaultValue.ID ?? null);
                    }}
                    loading={loading || loadingUpload}>
                    حذف
                  </Button>
                </div>
              ) : (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      handleSubmit(false, true);
                    }}
                    loading={loading || loadingUpload}>
                    به روزرسانی
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      setFormValue(defaultValue);
                      setDisabledMode(true);
                      // setForceReadOnly(true);
                      setReadOnly(true);
                    }}
                    loading={loading || loadingUpload}>
                    لغو
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {!props.isLast ? null : (
        <>
          <div className="flex justify-between">
            <Button
              appearance="primary"
              className="ml-auto mt-4"
              type="button"
              onClick={() => handleSubmit(false, false)}
              loading={loading || loadingUpload}>
              {props.hideNextButton ? 'ثبت کالا' : 'افزودن کالای جدید'}
            </Button>
          </div>
          {props.hideNextButton ? null : (
            <div className="flex justify-between">
              <Button
                appearance="default"
                className="ml-auto mt-8"
                type="button"
                onClick={() => {
                  navigate(`${ROUTES.roles.reqRecruitment.personal}?FormID=${FormID}`);
                }}
                loading={loading || loadingUpload}>
                مرحله قبل
              </Button>
              <Button
                appearance="primary"
                className="mr-auto mt-8"
                type="button"
                onClick={() => handleSubmit(true, false)}
                loading={loading || loadingUpload}>
                ثبت و مرحله بعد
              </Button>
            </div>
          )}
        </>
      )}
    </Form>
  );
};
