import { Form, InputProps } from 'rsuite';

interface TextFieldProps extends InputProps {
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accepter: any;
  hideLabel?: boolean;
  full?: boolean;
  req?: boolean;
  formGroupStyle?: React.CSSProperties;
}

export default function TextField(props: TextFieldProps) {
  const { name, label, accepter, hideLabel, formGroupStyle, full, req, ...rest } = props;
  return (
    <Form.Group
      controlId={`${name}`}
      style={formGroupStyle}
      className={`mb-5 max-w-[100%] ${full ? 'full' : ''}`}>
      {hideLabel ? null : (
        <Form.ControlLabel>
          {label} <span className="text-[--rs-error-500]">{req ? '*' : ''}</span>
        </Form.ControlLabel>
      )}
      <Form.Control name={name} accepter={accepter} className="w-[100%]" {...rest} />
    </Form.Group>
  );
}
