import { useState, useEffect } from 'react';
import { WorkBackgroundForm } from './components/work-background-form';
import { PostConsultantWorkBackgroundModel } from '../../models';
import { Button } from 'rsuite';
import {
  handleGetConsultantWorkBackground,
  handleDeleteConsultantWorkBackground
} from '../../api-handler';
import { useNavigate } from 'react-router-dom';
import { CustomLoader } from '../../components/basic/loader';
import { showToast } from '../../utils/toast';
import { ROUTES } from '../../router';

export const ConsultantWorkBackground = (props: {
  disabledMode?: boolean;
  isAdmin: boolean;
  username?: string;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(true);

  const [data, setData] = useState<(PostConsultantWorkBackgroundModel | null)[]>([]);
  const [showNew, setShowNew] = useState(true);

  const handleAddDoingData = (newData: PostConsultantWorkBackgroundModel | null) => {
    return setData((prev) => {
      return [...prev, newData];
    });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      const res = await handleGetConsultantWorkBackground(props.isAdmin ? props.username : null);
      if (res.ok) {
        setData(res.data);
        console.log(editMode);
        setEditMode(true);
        setShowNew(false);
      }
      setLoading(false);
    })();
  }, [props.username]);

  const onDelete = async (id: string | number | null) => {
    if (!id) {
      setShowNew(false);
    } else {
      const dataCopy = [...data];
      setData(dataCopy.filter((item) => item?.ID !== id));
      try {
        const res = await handleDeleteConsultantWorkBackground(id);
        if (res.ok) {
          showToast('با موفقیت حذف شد.', 'success');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <h4 className="text-h4">{props.isAdmin ? 'سوابق شغلی' : 'سوابق شغلی خود را وارد کنید'}</h4>

      {/* <FlexboxGrid className="mt-8">
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} lg={8} className="max-w-[100%]">
          <RadioGroup
            value={isWorking}
            dir="rtl"
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            disabled={props.disabledMode || editMode}
            onChange={(value: ValueType, _event: SyntheticEvent<Element, Event>) =>
              setIsWorking(value)
            }>
            <div className="flex items-center">
              <div className="w-[50%]">مشغول به کار هستید؟</div>
              <Radio className="w-[25%]" value={'yes'}>
                بله
              </Radio>
              <Radio className="w-[25%]" value={'no'}>
                خیر
              </Radio>
            </div>
          </RadioGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid> */}

      {data.map((item) => {
        return (
          <WorkBackgroundForm
            key={`${item?.ID}`}
            handleAddDoingData={handleAddDoingData}
            readonly={true}
            hideNextButton={false}
            defaultValue={item}
            isAdmin={props.isAdmin}
            disabledMode={props.disabledMode}
            hasEditMode={editMode}
            onDelete={onDelete}
          />
        );
      })}

      {editMode && showNew && !props.isAdmin ? (
        <WorkBackgroundForm
          handleAddDoingData={handleAddDoingData}
          forceReadOnly={loading}
          isAdmin={props.isAdmin}
          disabledMode={false}
          isLast={true}
          isFirst={data.length < 1}
          onDelete={onDelete}
        />
      ) : null}

      {editMode && !showNew && !props.isAdmin ? (
        <Button
          appearance="primary"
          className="ml-auto mt-4"
          type="button"
          onClick={() => setShowNew(true)}>
          افزودن سابقه جدید
        </Button>
      ) : null}

      {!editMode && data.length && showNew ? (
        <WorkBackgroundForm
          handleAddDoingData={handleAddDoingData}
          forceReadOnly={loading}
          disabledMode={props.disabledMode}
          isLast={true}
          isAdmin={props.isAdmin}
          isFirst={data.length < 2}
          onDelete={onDelete}
        />
      ) : !editMode && !props.disabledMode ? (
        <Button
          appearance="primary"
          className="ml-auto mt-4"
          type="button"
          onClick={() => handleAddDoingData(null)}>
          افزودن سابقه جدید
        </Button>
      ) : null}

      {editMode && !props.disabledMode && !showNew ? (
        <div className="flex justify-between">
          <Button
            appearance="default"
            className="ml-auto mt-8"
            type="button"
            onClick={() => {
              navigate(ROUTES.roles.reqConsultant.personal);
            }}
            loading={loading}>
            مرحله قبل
          </Button>
          <Button
            appearance="primary"
            className="mr-auto mt-8"
            type="button"
            onClick={() => {
              navigate(ROUTES.roles.reqConsultant.educationBackground);
            }}
            loading={loading}>
            مرحله بعد
          </Button>
        </div>
      ) : null}
    </>
  );
};
export default ConsultantWorkBackground;
