import { appNetworkManager } from './network-manager';
import { getUsername } from './config';

export const getWorkshops = () => {
  return appNetworkManager.get(`/workshopdetailsforms`);
};
export const getWorkshop = (id: string) => {
  return appNetworkManager.get(`/workshopdetailsform?ID=${id}`);
};
export const getUserBoughtEvents = () => {
  return appNetworkManager.get(`/workshopuser?Username=${getUsername()}`);
};
export const getWorkshopBoughts = (id: string | undefined) => {
  return appNetworkManager.get(`/workshopuser?WorkShopID=${id}`);
};
