import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

export const CustomNavLink = (props: { to: string; title: string; noActive?: boolean }) => {
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: resolved.pathname, end: false });

  return (
    <NavLink
      to={props.to}
      className={`nav-item flex items-center text-sub2 text-[--rs-primary-800] focus:text-[--rs-primary-800] hover:text-[--rs-primary-800] py-2 lg:py-auto lg:h-full whitespace-nowrap ${match && !props.noActive ? 'nav-active' : ''} px-4 h-full`}>
      <span>{props.title}</span>
    </NavLink>
  );
};
