/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Button, Col, Form, Input, InputPicker } from 'rsuite';
import TextField from '../../../components/form/textfield';
import { useCallback, useRef, useState } from 'react';
import { FlexboxGrid } from 'rsuite';
import Field from '../../../components/form/field';
import TextAreaField from '../../../components/form/textarea-field';
import { showToast } from '../../../utils/toast';
import {
  handlePostConsultantConsultationBackground,
  handlePutConsultantConsultationBackground
} from '../../../api-handler';
import {
  PostConsultantConsultationBackgroundModel,
  defaultConsultantConsultationBackgroundFormValue,
  defaultConsultantConsultationBackgroundSchema
} from '../../../models';
import { useNavigate } from 'react-router-dom';

import ConsultantFieldsOptions from '../../../data/consultant/consultant-fileds.json';
import ConsultantDurationOptions from '../../../data/consultant/consultant-duration.json';
import { ROUTES } from '../../../router';

export const ConsultationBackgroundForm = (props: {
  currentDoing: boolean;
  readonly?: boolean;
  handleAddDoingData: (
    newData: PostConsultantConsultationBackgroundModel,
    isCurrent: boolean
  ) => void;
  defaultValue?: PostConsultantConsultationBackgroundModel | null;
  hideNextButton?: boolean;
  forceReadOnly?: boolean;
  disabledMode?: boolean;
  hasEditMode?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  isAdmin: boolean;
  onDelete: (id: string | number) => void;
}) => {
  const navigate = useNavigate();
  const [disabledMode, setDisabledMode] = useState(props.hasEditMode);
  const [forceReadOnly] = useState(props.forceReadOnly);
  const [readOnly, setReadOnly] = useState(props.readonly);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>(
    props.defaultValue ?? defaultConsultantConsultationBackgroundFormValue
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [defaultValue, setDefaultValue] = useState<any>(
    props.defaultValue ?? defaultConsultantConsultationBackgroundFormValue
  );

  const handleSubmit = useCallback(
    async (isNext: boolean, updateMode: boolean) => {
      if (formRef.current && !formRef.current.check()) {
        console.log('formError', formError);
        return;
      }
      if (!formValue) return;
      const payload = {
        ...formValue
      };
      console.log(payload);
      setLoading(true);
      if (updateMode) {
        const ress = await handlePutConsultantConsultationBackground(
          payload,
          'ID',
          defaultValue.ID
        );
        if (ress.ok) {
          showToast('فرم به روزرسانی شد!', 'success');
          setDisabledMode(true);
          // setForceReadOnly(true);
          setReadOnly(true);
          setLoading(false);
          setDefaultValue(payload);
          if (isNext) {
            navigate('/consultant/social');
            return;
          }
        }
        setLoading(false);
        return;
      }
      const res = await handlePostConsultantConsultationBackground(payload);
      setLoading(false);
      if (res.ok) {
        showToast('فرم ثبت شد!', 'success');
        // or getting submitted data from backend
        if (isNext) {
          navigate('/consultant/social');
          return;
        }
        props.handleAddDoingData({ ...payload, ID: res.data.ID }, props.currentDoing);
        setFormValue(defaultConsultantConsultationBackgroundFormValue);
      }
    },
    [formValue, formError, formRef, props.currentDoing, defaultValue]
  );

  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={defaultConsultantConsultationBackgroundSchema}
      onSubmit={() => handleSubmit(false, false)}
      formValue={formValue}
      checkTrigger="change"
      className="mt-6 text-dark"
      readOnly={forceReadOnly ?? loading}
      disabled={disabledMode ?? props.isAdmin}>
      <FlexboxGrid
        className={`${!props.currentDoing ? 'bg-[--rs-primary-50] rounded-md border-[1px] border-[--rs-primary-500] p-3' : null} `}>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
          <FlexboxGrid>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%] !px-0">
              <Field
                accepter={InputPicker}
                data={ConsultantFieldsOptions}
                name="Field"
                label="زمینه مشاوره"
                dir="rtl"
                full
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%] !px-0">
              <TextField
                name="NameCounselor"
                label={'نام و نام خانوادگی مشاوره گیرنده'}
                type="text"
                accepter={Input}
                dir="rtl"
                full
                placeholder=""
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>

            {/* todo: */}
          </FlexboxGrid>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
          <TextAreaField
            name="WorkedOn"
            label={'کار انجام شده'}
            dir="rtl"
            full
            req
            rows={5}
            placeholder=""
            readOnly={readOnly}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
          <TextAreaField
            name="Result"
            label={'نتیجه'}
            dir="rtl"
            full
            req
            rows={5}
            placeholder=""
            readOnly={readOnly}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
          <Field
            accepter={InputPicker}
            data={ConsultantDurationOptions}
            name="Period"
            label="مدت زمان فعالیت به عنوان مشاور"
            dir="rtl"
            full
            req
            readOnly={readOnly}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
          {props.isLast && !props.isAdmin ? (
            <div className="flex justify-between">
              {defaultValue.ID ? (
                <Button
                  appearance="primary"
                  type="button"
                  onClick={() => {
                    setDisabledMode(false);
                    // setForceReadOnly(false);
                    setReadOnly(false);
                  }}
                  loading={loading}>
                  ویرایش
                </Button>
              ) : null}
              {!props.isFirst ? (
                <Button
                  appearance="ghost"
                  type="button"
                  className="mr-auto"
                  onClick={() => {
                    props.onDelete(defaultValue.ID ?? null);
                  }}
                  loading={loading}>
                  حذف
                </Button>
              ) : null}
            </div>
          ) : null}
          {props.hasEditMode && !props.isAdmin ? (
            <div>
              {disabledMode ? (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      setDisabledMode(false);
                      // setForceReadOnly(false);
                      setReadOnly(false);
                    }}
                    loading={loading}>
                    ویرایش
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      props.onDelete(defaultValue.ID ?? null);
                    }}
                    loading={loading}>
                    حذف
                  </Button>
                </div>
              ) : (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      handleSubmit(false, true);
                    }}
                    loading={loading}>
                    به روزرسانی
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      setFormValue(defaultValue);
                      setDisabledMode(true);
                      // setForceReadOnly(true);
                      setReadOnly(true);
                    }}
                    loading={loading}>
                    لغو
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {!props.isLast ?? props.isAdmin ? null : (
        <>
          <div className="flex justify-between">
            <Button
              appearance="primary"
              className="ml-auto mt-4"
              type="button"
              onClick={() => handleSubmit(false, false)}
              loading={loading}>
              {props.hideNextButton ? 'ثبت تحصیل فعلی' : 'افزودن سابقه جدید'}
            </Button>
          </div>
          {props.hideNextButton ? null : (
            <div className="flex justify-between">
              <Button
                appearance="default"
                className="ml-auto mt-8"
                type="button"
                onClick={() => {
                  navigate(ROUTES.roles.reqConsultant.educationBackground);
                }}
                loading={loading}>
                مرحله قبل
              </Button>
              <Button
                appearance="primary"
                className="mr-auto mt-8"
                type="button"
                onClick={() => handleSubmit(true, false)}
                loading={loading}>
                ثبت و مرحله بعد
              </Button>
            </div>
          )}
        </>
      )}
    </Form>
  );
};
