/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Form, Input, InputPicker, FlexboxGrid } from 'rsuite';
import TextAreaField from '../../../components/form/textarea-field';
import TextField from '../../../components/form/textfield';
import Field from '../../../components/form/field';
import { CustomLoader } from '../../../components/basic/loader';

import { showToast } from '../../../utils/toast';

import {
  handlePostRecruitmentPersonal,
  handleGetRecruitmentPersonal,
  handlePutRecruitmentPersonal
} from '../../../api-handler';
import { RequestRecruitmentDataFormSchema } from '../../../models';

import { ROUTES } from '../../../router';

import PartnershipOptions from '../../../data/global/partnership-type.json';
import GenderOptions from '../../../data/global/gender.json';
export const RecruitmentPersonal = (props: {
  disabledMode?: boolean;
  isAdmin?: boolean;
  username?: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [FormID] = useState(searchParams.get('FormID'));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initial, setInitial] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normalizeFormValue = (fv: any) => {
    let payload = {
      ...fv
    };
    if (payload.CompanyPhone) {
      payload = { ...payload };
    }

    return payload;
  };

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }

    if (!formValue) return;
    const payload = normalizeFormValue(formValue);
    if (!payload) return;
    setLoading(true);
    if (editMode) {
      const updateRes = await handlePutRecruitmentPersonal(payload, 'ID', initial.ID);
      if (updateRes.ok) {
        showToast('مشخصات به روزرسانی شد!', 'success');
        navigate(`${ROUTES.roles.reqRecruitment.good}?FormID=${FormID}`);
      }
      setLoading(false);
      return;
    }
    const res = await handlePostRecruitmentPersonal(payload);
    if (res.ok) {
      showToast('مشخصات ثبت شد!', 'success');
      const formID = res.data.Data.Data.ID;
      navigate(`${ROUTES.roles.reqRecruitment.good}?FormID=${formID}`);

      setLoading(false);
      return;
    }
    setLoading(false);
  }, [formValue, formError, formRef, FormID]);

  const handleGetInitialData = async (username: string | null | undefined) => {
    if (FormID) {
      setLoading(true);
      const res = await handleGetRecruitmentPersonal(username, FormID);
      if (res.ok) {
        setInitial(res.data);
        setFormValue(res.data);
        setEditMode(true);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetInitialData(props.isAdmin ? props.username : null);
    })();
  }, [props.username]);

  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <h4 className="text-h4">
        {props.isAdmin ? 'درخواست نیرو به صورت ویژه' : 'درخواست نیرو به صورت ویژه'}
      </h4>

      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={RequestRecruitmentDataFormSchema}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading}
        disabled={props.disabledMode}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CompanyName"
              label="نام کسب‌و‌کار"
              type="text"
              accepter={Input}
              full
              dir="rtl"
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEOName"
              label="نام و نام خانوادگی مدیر عامل"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEONationalID"
              label="کد ملی مدیر"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] !px-0">
            <FlexboxGrid>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <TextField
                  name="RegistrationNumber"
                  label="شماره ثبت"
                  type="number"
                  accepter={Input}
                  dir="ltr"
                  full
                  placeholder=""
                  req
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <TextField
                  name="NationalIDNumber"
                  label="شناسه ملی"
                  type="number"
                  accepter={Input}
                  dir="ltr"
                  full
                  placeholder=""
                  req
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField name="Industry" label="حوزه فعالیت" dir="rtl" full req placeholder="" />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="JobTitle"
              label="عنوان شغلی مورد درخواست"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] !px-0">
            <FlexboxGrid>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <TextField
                  name="NumberOfPositions"
                  label="تعداد نیروی مورد نیاز"
                  type="number"
                  accepter={Input}
                  dir="ltr"
                  full
                  placeholder=""
                  req
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <Field
                  name="Gender"
                  label="جنسیت"
                  accepter={InputPicker}
                  data={GenderOptions}
                  dir="rtl"
                  full
                  req
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="InsuranceDetails"
              label="شرایط بیمه"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField name="Salary" label="میزان حقوق" dir="rtl" full req placeholder="" />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="TransportServiceDetails"
              label="شرایط سرویس رفت و آمد"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="JobDescription"
              label="خلاصه شرح شغل و وظایف"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] !px-0">
            <FlexboxGrid>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <Field
                  name="EmploymentType"
                  label="نوع همکاری"
                  accepter={InputPicker}
                  data={PartnershipOptions}
                  dir="rtl"
                  full
                  req
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <TextField
                  name="WorkHours"
                  label="میزان ساعت کار"
                  type="text"
                  accepter={Input}
                  dir="ltr"
                  full
                  placeholder=""
                  req
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="ExperienceRequired"
              label="میزان سابقه کاری مورد نیاز"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="RequiredSkills"
              label="مهارت های مورد نیاز"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CompanyWebsite"
              label="آدرس سایت"
              type="text"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
            <TextAreaField name="CompanyAddress" label="آدرس" dir="rtl" full req placeholder="" />
          </FlexboxGrid.Item>
          <div className="border-[1px] border-[--rs-primary-300] bg-[--rs-primary-100] rounded-md p-3">
            {' '}
            در این نوع از درخواست نیرو، بر اساس نیاز اعلام شده از سوی شرکت، در بین افراد واجد شرایط،
            بررسی های لازم و مصاحبه توسط گروه مشاوره دکتررمضانی انجام خواهد شد و بهترین و مناسب ترین
            نیرو به شرکت، معرفی خواهد شد. در صورتی که نیروی پیشنهادی از نظر شرکت، شایسته بود و تصمیم
            به همکاری با نیرو گرفته شد، هزینه کاریابی به مبلغ 2 میلیون تومان از سوی کارفرما به حساب
            شرکت پژواک جاوید مدیریت و نوآوری واریز خواهد شد که کارفرما می تواند به صورت توافقی از
            حساب خود یا از حساب حقوق ماه اول کارجو پرداخت نماید. در صورتی که با نیروی پیشنهادی توافق
            همکاری حاصل نشود، فرآیند کاریابی تا یافتن نیروی شایسته برای شرکت ادامه خواهد یافت.{' '}
          </div>
        </FlexboxGrid>
        {!props.disabledMode ? (
          <div className="flex justify-between">
            <Button appearance="primary" className="mr-auto mt-8" type="submit" loading={loading}>
              مرحله بعد
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  );
};
