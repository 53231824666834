import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export interface RequestIdeaDataFormValueModel {
  ID?: String;
  Username?: String;
  CompanyName: String;
  CEOName: String;
  CEONationalID: String;
  IdeaName: String;
  IdeaType: String;
  CollaborationType: String;
  IdeaCategory: String;
  DistributionDetails: String;
  BusinessDetails: String;
  CustomerDetails: String;
  GeographicDistribution: String;
  CompanyWebsite: String;
  CompanyAddress: String;
  RequestReason: String;
}

export interface PostRequestIdeaDataModel extends RequestIdeaDataFormValueModel {
  FormID: String;
}

export const RequestIdeaDataFormSchema = Schema.Model({
  CompanyName: StringType(''),
  CEOName: StringType('').isRequired('الزامی است!'),
  CEONationalID: StringType('').isRequired('الزامی است!'),
  IdeaName: StringType('').isRequired('الزامی است!'),
  IdeaType: StringType('').isRequired('الزامی است!'),
  // CollaborationType: StringType('').isRequired('الزامی است!'),
  IdeaCategory: StringType('').isRequired('الزامی است!'),
  DistributionDetails: StringType('').isRequired('الزامی است!'),

  BusinessDetails: StringType('').isRequired('الزامی است!'),
  CustomerDetails: StringType('').isRequired('الزامی است!'),
  GeographicDistribution: StringType('').isRequired('الزامی است!'),
  CompanyWebsite: StringType(''),
  CompanyAddress: StringType('').isRequired('الزامی است!'),
  RequestReason: StringType('').isRequired('الزامی است!')
});

// good
export interface IdeaGoodFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
  Produced: Boolean;
  PICs: string;
}
export const defaultIdeaGoodFormValue = {
  Name: '',
  Description: '',
  Produced: '',
  PICs: '',
  File: ''
};

export const defaultIdeaGoodSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
  // PICs: StringType('').isRequired('الزامی است!')
});

export interface PostIdeaGoodModel extends IdeaGoodFormValueModel {
  // StudyNow: boolean;
  // Country: String;
  // Start_Date: String;
  // End_Date?: String;
}

// service
export interface IdeaServiceFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
}
export const defaultIdeaServiceFormValue = {
  Name: '',
  Description: ''
};

export const defaultIdeaServiceSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
});

export interface PostIdeaServiceModel extends IdeaServiceFormValueModel {}
