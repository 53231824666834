import {
  PostOrganizationalProjectsGoodModel,
  PostRequestOrganizationalProjectsDataModel,
  PostOrganizationalProjectsServiceModel
} from '../models';
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const postOrganizationalProjectsPersonal = (
  payload: PostRequestOrganizationalProjectsDataModel
) =>
  appNetworkManager.post(`/orgprojectform`, {
    ...payload,
    Username: getUsername()
  });

export const getOrganizationalProjectsPersonal = (
  username: string | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formID: any
) => {
  return appNetworkManager.get(
    `/orgprojectform?Username=${username ?? getUsername()}&ID=${formID}`
  );
};
// good
export const postOrganizationalProjectsGood = (payload: PostOrganizationalProjectsGoodModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formgoods`, {
    ...rest,
    Username: getUsername(),
    Form: 'organizational-projects'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOrganizationalProjectsGood = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formgoods?Username=${username ?? getUsername()}&Form=organizational-projects&FormID=${formID}`
  );
};

export const deleteOrganizationalProjectsGood = (id: number | string) => {
  return appNetworkManager.delete(`/formgoods?ID=${id}`);
};

// service
export const postOrganizationalProjectsService = (
  payload: PostOrganizationalProjectsServiceModel
) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formservices`, {
    ...rest,
    Username: getUsername(),
    Form: 'organizational-projects'
  });
};

export const getOrganizationalProjectsService = (
  username: string | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formID: any
) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formservices?Username=${username ?? getUsername()}&Form=organizational-projects&FormID=${formID}`
  );
};
export const deleteOrganizationalProjectsService = (id: number | string) => {
  return appNetworkManager.delete(`/formservices?ID=${id}`);
};

// register request
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const postOrganizationalProjectsRegisterRequest = (FormID: any) => {
  return appNetworkManager.post(`/orgprojectformregreq`, {
    Username: getUsername(),
    Status: 'Pending',
    FormID
  });
};
export const putOrganizationalProjectsRegisterRequest = (payload: { Status: String }) => {
  return appNetworkManager.put(`/orgprojectformregreq`, {
    ...payload,
    Username: getUsername()
  });
};

export const getOrganizationalProjectsRegisterRequest = () => {
  return appNetworkManager.get(`/orgprojectformregreq?Username=${getUsername()}`);
};
export const getOrganizationalProjects = () => {
  return appNetworkManager.get(`/organizationalprojects`);
};
// export const postOrganizationalProjectsBuycv = (seekerUsername: string) => {
//   return appNetworkManager.post(`/buycv`, {
//     EmployerUsername: getUsername(),
//     JobSeekerUsername: seekerUsername
//   });
// };
// export const getOrganizationalProjectsBuycv = () => {
//   return appNetworkManager.get(`/buycv?EmployerUsername=${getUsername()}`);
// };
