/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputPicker,
  Radio,
  RadioGroup,
  FlexboxGrid
} from 'rsuite';
import TextAreaField from '../../components/form/textarea-field';
import TextField from '../../components/form/textfield';
import Field from '../../components/form/field';
import { CustomLoader } from '../../components/basic/loader';

import { showToast } from '../../utils/toast';
import { Datepicker } from '@ijavad805/react-datepicker';

import {
  handlePostConsultantPersonal,
  handleGetConsultantPersonal,
  handlePutConsultantPersonal
} from '../../api-handler';
import { ConsultantPersonalFormSchema } from '../../models';

import { ROUTES } from '../../router';
import { convertDateToSlashi } from '../../utils/helpers';
// import moment from 'moment';

import MilitaryServiceOptions from '../../data/global/military-service.json';
import EducationDegreeOptions from '../../data/global/education-degree.json';
import CorperationRoleOptions from '../../data/global/corperation-role.json';
import CorperationTypeOptions from '../../data/global/corperation-type.json';
import { CountryStateCity } from '../../components/form/country-state-city';
export const ConsultantPersonal = (props: {
  disabledMode?: boolean;
  isAdmin?: boolean;
  username?: string;
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initial, setInitial] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});

  const [sex, setSex] = useState('آقا');
  const [hasCompany, setHasCompany] = useState(false);

  const [timeStamp, setTimeStamp] = useState<Date>(new Date());
  // const [calendarOpen, setCalendarOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normalizeFormValue = (fv: any) => {
    console.log(fv);
    let payload = {
      ...fv,
      HasCompany: hasCompany,
      Sex: sex === 'آقا',
      // todo check of came from backend
      CellPhone: `+98${fv.CellPhone.substring(1)}`,
      Birth_Date: new Date(timeStamp),
      // CountryB: 'ایران'
      CountryB: fv['CountryB-t'] == 'ایران' ? 'ایران' : fv.CountryB ?? 'ایران'
    };
    if (payload.CompanyPhone) {
      payload = { ...payload, CompanyPhone: `+98${payload.CompanyPhone.substring(1)}` };
    }

    if (!payload.HasCompany) {
      delete payload.Role;
      delete payload.CompanyName;
      delete payload.CompanyID;
      delete payload.CompanyEmail;
      delete payload.CompanyType;
      delete payload.CompanySite;
      delete payload.CompanyPhone;
      delete payload.CompanyAddress;
      delete payload.CompanyField;
    } else {
      let hadError = false;
      for (const item of [
        'Role',
        'CompanyName',
        'CompanyID',
        // 'CompanyEmail',
        'CompanyType',
        // 'CompanySite',
        'CompanyAddress',
        'CompanyField'
      ]) {
        if (!payload[item]) {
          hadError = true;
          setFormError((prev) => {
            return { ...prev, [item]: 'الزامی است!' };
          });
        }
      }
      if (hadError) return false;
    }
    if (!payload.Sex) {
      payload.MilitaryService = 'انتخاب کنید';
    }
    if (payload['CountryB-t']) delete payload['CountryB-t'];

    return payload;
  };

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }

    if (!formValue) return;
    const payload = normalizeFormValue(formValue);
    if (!payload) return;
    setLoading(true);
    if (editMode) {
      const updateRes = await handlePutConsultantPersonal(payload, 'Username', initial.Username);
      if (updateRes.ok) {
        showToast('مشخصات فردی به روزرسانی شد!', 'success');
        navigate(ROUTES.roles.reqConsultant.workBackground);
      }
      setLoading(false);
      return;
    }
    const res = await handlePostConsultantPersonal(payload);
    if (res.ok) {
      showToast('مشخصات فردی ثبت شد!', 'success');
      navigate(ROUTES.roles.reqConsultant.workBackground);
      setLoading(false);
      return;
    }
    setLoading(false);
  }, [formValue, formError, timeStamp, sex, formRef, hasCompany]);

  const handleGetInitialData = async (username: string | null | undefined) => {
    setLoading(true);
    const res = await handleGetConsultantPersonal(username);
    if (res.ok) {
      setInitial(res.data);
      setFormValue(res.data);
      if (res.data.HasCompany) {
        setHasCompany(true);
      }
      setSex(res.data.Sex ? 'آقا' : 'خانم');
      setTimeStamp(res.data.Birth_Date);
      setEditMode(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetInitialData(props.isAdmin ? props.username : null);
    })();
  }, [props.username]);

  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <h4 className="text-h4">{props.isAdmin ? 'مشخصات فردی' : 'مشخصات فردی خود را وارد کنید'}</h4>

      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={ConsultantPersonalFormSchema}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading}
        disabled={props.disabledMode}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="FirstName"
              label="نام"
              type="text"
              accepter={Input}
              full
              dir="rtl"
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="LastName"
              label="نام خانوادگی"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="NID"
              label="کد ملی"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              name="Sex"
              label="جنسیت"
              accepter={RadioGroup}
              value={sex}
              onChange={(value: string) => setSex(value)}>
              <div className="flex">
                <Radio className="w-[50%]" value={'خانم'}>
                  خانم
                </Radio>
                <Radio className="w-[50%]" value={'آقا'}>
                  آقا
                </Radio>
              </div>
            </Field>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              name="MilitaryService"
              label="وضعیت خدمت"
              accepter={InputPicker}
              data={MilitaryServiceOptions}
              dir="rtl"
              full
              disabled={sex !== 'آقا' || props.disabledMode || props.isAdmin}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="FathersName"
              label="نام پدر"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="Email"
              label="ایمیل"
              type="email"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={16} className="max-w-[100%] !px-0">
            <FlexboxGrid>
              <CountryStateCity
                hasCountry={true}
                setFormValue={setFormValue}
                stateOptions={{
                  default: formValue?.StateB,
                  name: 'StateB',
                  label: 'استان محل تولد',
                  colspan: 24,
                  sm: 24,
                  md: 8
                }}
                cityOptions={{
                  default: formValue?.CityB,
                  name: 'CityB',
                  label: 'شهر محل تولد',
                  colspan: 24,
                  sm: 24,
                  md: 8
                }}
                countryOptions={{
                  default: formValue?.CountryB,
                  name: 'CountryB',
                  label: 'کشور محل تولد',
                  colspan: 24,
                  sm: 24,
                  md: 8
                }}
              />
            </FlexboxGrid>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CellPhone"
              label="شماره تلفن همراه"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder="09123456789"
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="Website"
              label="سایت شخصی"
              type="text"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              accepter={Datepicker}
              className="font-fd"
              name="Birth_Date"
              lang={'fa'}
              theme={'green'}
              format={'D MMMM yyyy'}
              value={convertDateToSlashi(timeStamp)}
              input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(val: any) => setTimeStamp(new Date(val))}
              label="تاریخ"
              dir="ltr"
              full
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              accepter={InputPicker}
              data={EducationDegreeOptions}
              name="EduDegree"
              label="آخرین مدرک تحصیلی"
              dir="rtl"
              full
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="RID"
              label="شماره شناسنامه"
              type="text"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
            <Field
              name="HasCompany"
              onChange={(value: string | number, checked: boolean) => {
                setHasCompany(checked);
              }}
              label=""
              checked={hasCompany}
              accepter={Checkbox}
              dir="rtl"
              full>
              دارای شرکت ثبت شده به این نام؟ یا عضو هیئت مدیره؟
            </Field>
          </FlexboxGrid.Item>
          {hasCompany ? (
            <>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <Field
                  name="Role"
                  label="سمت در شرکت"
                  accepter={InputPicker}
                  data={CorperationRoleOptions}
                  dir="rtl"
                  full
                  req
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <TextField
                  name="CompanyName"
                  label="نام شرکت"
                  type="text"
                  accepter={Input}
                  dir="rtl"
                  full
                  req
                  placeholder=""
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <TextField
                  name="CompanyID"
                  label="شناسه ثبت شرکت"
                  type="text"
                  accepter={Input}
                  dir="ltr"
                  full
                  req
                  placeholder=""
                />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item
                as={Col}
                colspan={24}
                sm={24}
                md={16}
                className="max-w-[100%] !px-0">
                <FlexboxGrid>
                  <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
                    <TextField
                      name="CompanyPhone"
                      label="شماره تماس شرکت"
                      type="number"
                      accepter={Input}
                      dir="ltr"
                      full
                      req
                      placeholder=""
                    />
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
                    <TextField
                      name="CompanyEmail"
                      label="پست الکترونیکی شرکت"
                      type="email"
                      accepter={Input}
                      dir="ltr"
                      full
                      placeholder=""
                    />
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
                    <Field
                      name="CompanyType"
                      label="نوع شرکت"
                      groupBy="type"
                      accepter={InputPicker}
                      data={CorperationTypeOptions}
                      dir="rtl"
                      full
                      req
                    />
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
                    <TextField
                      name="CompanySite"
                      label="سایت شرکت"
                      type="text"
                      accepter={Input}
                      dir="ltr"
                      full
                      placeholder=""
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </FlexboxGrid.Item>

              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <TextAreaField
                  name="CompanyAddress"
                  label="آدرس شرکت"
                  dir="rtl"
                  full
                  req
                  placeholder=""
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <TextField
                  name="CompanyField"
                  label="محصول / حوزه فعالیت شرکت"
                  type="text"
                  accepter={Input}
                  dir="rtl"
                  full
                  req
                  placeholder=""
                />
              </FlexboxGrid.Item>
            </>
          ) : null}
        </FlexboxGrid>
        {!props.disabledMode ? (
          <div className="flex justify-between">
            <Button appearance="primary" className="mr-auto mt-8" type="submit" loading={loading}>
              مرحله بعد
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  );
};

export default ConsultantPersonal;
