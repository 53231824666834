import { forwardRef } from 'react';
import { Form } from 'rsuite';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Field = forwardRef((props: any, ref: any) => {
  const { name, label, accepter, full, hideLabel, inline, req, ...rest } = props;
  return (
    <Form.Group
      controlId={`${name}`}
      ref={ref}
      className={`mb-5 max-w-[100%] ${full ? 'full' : ''} ${inline ? 'flex' : ''}`}>
      {hideLabel ? null : (
        <Form.ControlLabel>
          {label} <span className="text-[--rs-error-500]">{req ? '*' : ''}</span>
        </Form.ControlLabel>
      )}

      <Form.Control className="w-[100%]" name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});
Field.displayName = 'Field';

export default Field;
