/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteFounderGood,
  deleteFounderService,
  getFounderGood,
  getFounderPersonal,
  getFounderRegisterRequest,
  getFounders,
  getFounderService,
  postFounderGood,
  postFounderPersonal,
  postFounderRegisterRequest,
  postFounderService,
  putFounderRegisterRequest
} from '../core';
import {
  PostFounderGoodModel,
  PostRequestFounderDataModel,
  PostFounderServiceModel
} from '../models';
import { showToast } from '../utils/toast';
import { handleUpdateAny } from './global';

export const handlePostFounderPersonal = async (payload: PostRequestFounderDataModel) => {
  try {
    const { data, status } = await postFounderPersonal(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetFounderPersonal = async (
  username: string | null | undefined,
  formId: string | null | undefined
) => {
  try {
    const { data, status } = await getFounderPersonal(username, formId);
    // return { ok: true, payload };
    if (status === 200) {
      return {
        data: {
          ...data.Data[0]
        },
        ok: true
      };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutFounderPersonal = (
  payload: PostRequestFounderDataModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'InvestmentForm');
};

// good
export const handlePostFounderGood = async (payload: PostFounderGoodModel) => {
  try {
    const { data, status } = await postFounderGood(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetFounderGood = async (username: string | null | undefined, formID: any) => {
  try {
    const { data, status } = await getFounderGood(username, formID);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutFounderGood = async (
  payload: PostFounderGoodModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'FormGoodsTable');
};
export const handleDeleteFounderGood = async (id: number | string) => {
  try {
    const { data, status } = await deleteFounderGood(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// service
export const handlePostFounderService = async (payload: PostFounderServiceModel) => {
  try {
    const { data, status } = await postFounderService(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetFounderService = async (username: string | null | undefined, formID: any) => {
  try {
    const { data, status } = await getFounderService(username, formID);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutFounderService = async (
  payload: PostFounderServiceModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'FormServicesTable');
};
export const handleDeleteFounderService = async (id: number | string) => {
  try {
    const { data, status } = await deleteFounderService(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// // request
// eslint-disable-next-line @typescript-eslint/naming-convention
export const handlePostFounderRegisterRequest = async (FormID: any) => {
  try {
    const { data, status } = await postFounderRegisterRequest(FormID);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutFounderRegisterRequest = async (payload: { Status: string; FormID: any }) => {
  try {
    const { data, status } = await putFounderRegisterRequest(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetFounderRegisterRequest = async () => {
  try {
    const { data, status } = await getFounderRegisterRequest();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetFounders = async () => {
  try {
    const { data, status } = await getFounders();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// export const handlePostFounderBuycv = async (seekerUsername: string) => {
//   try {
//     const { data, status } = await postFounderBuycv(seekerUsername);
//     // return { ok: true, payload };
//     if (status === 200) {
//       return { data: data.Data, ok: true };
//     } else {
//       showToast(data.Message, 'error');
//     }
//     console.log('NOT 200', data);
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     if (e.response?.status === 404) {
//       console.log('not submitted');
//       return { data: {}, ok: false };
//     }
//     console.log(e.response?.data.Message?.FA);
//     showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
//   }
//   return { ok: false };
// };
// export const handleGetFounderBuycv = async () => {
//   try {
//     const { data, status } = await getFounderBuycv();
//     // return { ok: true, payload };
//     if (status === 200) {
//       return { data: data.Data, ok: true };
//     } else {
//       showToast(data.Message, 'error');
//     }
//     console.log('NOT 200', data);
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     if (e.response?.status === 404) {
//       console.log('not submitted');
//       return { data: [], ok: false };
//     }
//     console.log(e.response?.data.Message?.FA);
//     showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
//   }
//   return { ok: false };
// };
