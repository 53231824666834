/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, CheckPicker, Col, Form, Input, InputPicker, Schema } from 'rsuite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FlexboxGrid } from 'rsuite';

const { StringType, NumberType, ArrayType } = Schema.Types;
// todo: validation
const model = Schema.Model({
  FullName: StringType(''),
  Category: ArrayType(''),
  City: StringType(''),
  Education: StringType(''),
  // ProfessionBeginTimeStamp: StringType(''),
  Job: StringType(''),
  PriceFor30M: NumberType(''),
  PriceFor1H: NumberType(''),
  Description: StringType('')
});

// import { handlePostConsulteeReport } from '../../api-handler';
import { showToast } from '../../../utils/toast';
import Field from '../../../components/form/field';
import TextAreaField from '../../../components/form/textarea-field';
import TextField from '../../../components/form/textfield';
import ConsultantFieldsOptions from '../../../data/consultant/consultant-fileds.json';
// import ConsultantJobsOptions from '../../../data/consultant/consultant-job.json';
import ConsultantEducationOptions from '../../../data/global/education-degree.json';
import {
  handleGetAdminConsultantRegister,
  handlePostAdminConsultantRegister,
  handlePutAdminConsultantRegister
} from '../../../api-handler/admin';
import { Datepicker } from '@ijavad805/react-datepicker';
import { convertDateToSlashi, HandleNum2persian } from '../../../utils/helpers';

export const AdminRegisterConsultant = (props: {
  username: string;
  onAcceptConsultant: () => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeStamp, setTimeStamp] = useState<any>(new Date());
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selected, setSelected] = useState<any>([]);

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }

    if (!formValue) return;
    const cats = formValue.Category.join(', ');
    const payload = {
      ...formValue,
      ProfessionBeginTimeStamp: timeStamp,
      Category: cats
    };
    setLoading(true);
    if (editMode) {
      const res = await handlePutAdminConsultantRegister({
        ...payload,
        ProfessionBeginTimeStamp: new Date(timeStamp)
      });
      if (res.ok) {
        showToast('اطلاعات با موفقیت به روزرسانی شد!', 'success');
        props.onAcceptConsultant();
        // navigate('/consultant/tnc');
      }
      setLoading(false);
      return;
    }
    const res = await handlePostAdminConsultantRegister({ ...payload, Username: props.username });
    if (res.ok) {
      showToast('فرم با موفقیت ثبت شد!', 'success');
      props.onAcceptConsultant();
      // navigate('/consultant/tnc');
    }
    setLoading(false);
  }, [formValue, formError, formRef, props.username, timeStamp, editMode]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetAdminConsultantRegister(props.username);

      console.log(res);
      if (res.ok) {
        setSelected(res.data.Category ? res.data.Category.split(', ') : []);
        setFormValue({
          ...res.data,
          Category: res.data.Category ? res.data.Category.split(', ') : []
        });
        setTimeStamp(new Date(res.data.ProfessionBeginTimeStamp));
        setEditMode(true);
      }
      setLoading(false);
    })();
  }, [props.username]);

  return (
    <>
      <h4 className="text-h4">اطلاعات مورد نیاز برای نمایش عمومی مشاور را وارد کنید</h4>
      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={model}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <TextField
              name="FullName"
              label="نام و نام خانوادگی"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <Field
              name="Category"
              label="زمینه مشاوره"
              accepter={CheckPicker}
              data={ConsultantFieldsOptions}
              dir="rtl"
              full
              req
              defaultValue={[]}
              value={selected}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(val: any) => {
                setSelected(val);
              }}
            />
          </FlexboxGrid.Item>
          {/* todo: fill */}
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <TextField
              name="City"
              label="شهر"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          {/* todo: fill */}
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <TextField
              name="Job"
              label="شغل"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          {/* todo: fill */}
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <Field
              name="Education"
              label="تحصیلات"
              accepter={InputPicker}
              data={ConsultantEducationOptions}
              dir="rtl"
              full
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              accepter={Datepicker}
              className="font-fd"
              name="ProfessionBeginTimeStamp"
              lang={'fa'}
              theme={'green'}
              format={'D MMMM yyyy'}
              value={convertDateToSlashi(timeStamp)}
              input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(val: any) => setTimeStamp(new Date(val))}
              label="زمان شروع فعالیت به عنوان مشاور"
              dir="ltr"
              full
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <TextField
              name="PriceFor1H"
              label="قیمت برای یک ساعت (ریال)"
              type="text"
              accepter={Input}
              dir="ltr"
              full
              req
              placeholder=""
            />
            <div className="mt-1 mb-1 font-bold">
              {HandleNum2persian(formValue.PriceFor1H, 'ریال')}
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <TextField
              name="PriceFor30M"
              label="قیمت برای نیم ساعت (ریال)"
              type="text"
              accepter={Input}
              dir="ltr"
              full
              req
              placeholder=""
            />
            <div className="mt-1 mb-1 font-bold">
              {HandleNum2persian(formValue.PriceFor30M, 'ریال')}
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
            <TextAreaField
              name="Description"
              label="توضیحات درباره مشاور"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <div className="flex justify-between">
          <Button appearance="primary" className="mr-auto mt-8" type="submit" loading={loading}>
            ثبت نهایی مشاور
          </Button>
        </div>
      </Form>
    </>
  );
};
