import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useUser } from '../../contexts/user';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
  const navigate = useNavigate();
  const { setUser, userTrigger, setUserTrigger } = useUser();
  useEffect(() => {
    Cookies.remove('token');
    Cookies.remove('username');
    Cookies.remove('token');
    Cookies.remove('admin');
    Cookies.remove('selectedRole');
    setUser({ Username: null, isLoggedIn: false, Roles: [], selectedRole: 'Guest' });
    setUserTrigger(!userTrigger);
    navigate('/auth/login');
  }, []);
  return <></>;
};
