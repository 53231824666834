import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, Schema } from 'rsuite';
import { base64ToJson, jsonToBase64 } from '../../../utils/helpers';
import { CustomLoader } from '../../../components/basic/loader';
import { showToast } from '../../../utils/toast';
import { UploadField } from '../../../components/form/uploader';
import {
  handleGetUserProfilePicture,
  handlePostUserProfilePicture,
  handlePutUserProfilePictureUser
} from '../../../api-handler/user';
const model = Schema.Model({});

export const UserProfile = () => {
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});

  const [loadingUpload, setLoadingUpload] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadedPictureLinkUrl, setUploadedPictureLinkUrl] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initPictureLinkJson, setInitPictureLinkJson] = useState<any>([]);

  const normalizeFormValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fv: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const PictureLinkJson: any = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(uploadedPictureLinkUrl).forEach((file: any) => {
        PictureLinkJson.push(uploadedPictureLinkUrl[file]);
      });

      if (PictureLinkJson.length === 0) {
        showToast('تصویر پروفایل را انتخاب کنید.', 'error');
        return null;
      }
      const PictureLinkEncoded = jsonToBase64(PictureLinkJson);

      const payload = {
        ...fv,
        PictureLink: PictureLinkEncoded
      };

      if (payload.File) delete payload.File;

      return payload;
    },
    [uploadedPictureLinkUrl, initPictureLinkJson]
  );

  const handleGetInitialData = async () => {
    setUploadedPictureLinkUrl({});
    setLoading(true);
    const res = await handleGetUserProfilePicture();
    if (res.ok) {
      setEditMode(true);
      setData(res.data);
      setInitPictureLinkJson(base64ToJson(res.data?.PictureLink));
    }
    setLoading(false);
  };

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }
    console.log(formValue);
    if (!formValue) return;
    const payload = normalizeFormValue(formValue);
    if (!payload) return;
    setLoading(true);
    if (editMode) {
      // todo check update
      const updateRes = await handlePutUserProfilePictureUser(payload);
      if (updateRes.ok) {
        showToast('تصویر پروفایل به روزرسانی شد!', 'success');
        // navigate(ROUTES.roles.reqSeeker.workBackground);
        setInitPictureLinkJson(base64ToJson(payload.PictureLink));
        handleGetInitialData();
      }
      setLoading(false);
      return;
    }
    console.log(payload);
    const res = await handlePostUserProfilePicture(payload);
    if (res.ok) {
      showToast('تصویر پروفایل ثبت شد!', 'success');
      setInitPictureLinkJson(base64ToJson(payload.PictureLink));
      handleGetInitialData();
      // navigate(ROUTES.roles.reqSeeker.workBackground);
      setLoading(false);
      return;
    }
    setLoading(false);
  }, [formValue, formError, formRef, uploadedPictureLinkUrl, initPictureLinkJson]);

  useEffect(() => {
    (async () => {
      await handleGetInitialData();
    })();
  }, []);

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <>
      <h4 className="text-h4">پروفایل</h4>
      <div>
        {data?.Status && data?.Status !== 'Accepted' ? (
          <div className="bg-[--rs-primary-50] border-[1px] border-[--rs-primary-300] rounded-md p-3 mb-3 mt-2">
            <h6 className="text-h6">
              {data?.Status === 'Pending'
                ? 'در انتظار تایید ادمین'
                : data?.Status === 'Rejected'
                  ? 'تصویر پروفایل رد شده'
                  : null}
            </h6>
            {data?.Status === 'Rejected' ? (
              <>
                <br />
                <strong>علت:</strong>
                <br />
                {data.Description}
              </>
            ) : null}
          </div>
        ) : null}
        <Form
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formError={formError}
          formValue={formValue}
          model={model}
          onSubmit={handleSubmit}
          checkTrigger="change"
          className="mt-6 text-dark"
          readOnly={loading}>
          <UploadField
            label="تصویر پروفایل"
            req
            full
            setLoadingUpload={setLoadingUpload}
            name="PictureLink"
            fileName="PictureLink"
            domain="Uploads/"
            initJson={initPictureLinkJson}
            setInitJson={setInitPictureLinkJson}
            uploadedUrl={uploadedPictureLinkUrl}
            setUploadedUrl={setUploadedPictureLinkUrl}
            dontClear={true}
            isSingle={true}
            // readOnly={props.isAdmin ?? props.disabledMode}
          />
          <Button appearance="primary" type="submit" loading={loading || loadingUpload}>
            بروزرسانی
          </Button>
        </Form>
      </div>
    </>
  );
};
