import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export interface RequestOrganizationalTrainingsDataFormValueModel {
  ID?: String;
  Username?: String;
  CompanyName: String;
  CEOName: String;
  CEONationalID: String;
  TrainingType: String;
  EmployeeDetails: String;
  BusinessDetails: String;
  CustomerDetails: String;
  GeographicDistribution: String;
  CompanyWebsite: String;
  CompanyAddress: String;
  RequestReason: String;
}

export interface PostRequestOrganizationalTrainingsDataModel
  extends RequestOrganizationalTrainingsDataFormValueModel {
  FormID: String;
}

export const RequestOrganizationalTrainingsDataFormSchema = Schema.Model({
  CompanyName: StringType(''),
  CEOName: StringType('').isRequired('الزامی است!'),
  CEONationalID: StringType('').isRequired('الزامی است!'),
  TrainingType: StringType('').isRequired('الزامی است!'),
  EmployeeDetails: StringType('').isRequired('الزامی است!'),
  BusinessDetails: StringType('').isRequired('الزامی است!'),
  CustomerDetails: StringType('').isRequired('الزامی است!'),
  GeographicDistribution: StringType('').isRequired('الزامی است!'),
  CompanyWebsite: StringType(''),
  CompanyAddress: StringType('').isRequired('الزامی است!'),
  RequestReason: StringType('').isRequired('الزامی است!')
});

// good
export interface OrganizationalTrainingsGoodFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
  Produced: Boolean;
  PICs: string;
}
export const defaultOrganizationalTrainingsGoodFormValue = {
  Name: '',
  Description: '',
  Produced: '',
  PICs: '',
  File: ''
};

export const defaultOrganizationalTrainingsGoodSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
  // PICs: StringType('').isRequired('الزامی است!')
});

export interface PostOrganizationalTrainingsGoodModel
  extends OrganizationalTrainingsGoodFormValueModel {
  // StudyNow: boolean;
  // Country: String;
  // Start_Date: String;
  // End_Date?: String;
}

// service
export interface OrganizationalTrainingsServiceFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
}
export const defaultOrganizationalTrainingsServiceFormValue = {
  Name: '',
  Description: ''
};

export const defaultOrganizationalTrainingsServiceSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
});

export interface PostOrganizationalTrainingsServiceModel
  extends OrganizationalTrainingsServiceFormValueModel {}
