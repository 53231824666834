/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getConsultantConsultationBackground,
  getConsultantEducationBackground,
  getConsultantSocial,
  getConsultantWorkBackground,
  postConsultantPersonal,
  postConsultantConsultationBackground,
  postConsultantEducationBackground,
  postConsultantSocial,
  postConsultantWorkBackground,
  getConsultantPersonal,
  postConsultantRegisterRequest,
  getConsultantRegisterRequest,
  deleteConsultantConsultationBackground,
  deleteConsultantEducationBackground,
  deleteConsultantWorkBackground,
  putConsultantRegisterRequest,
  getConsultantComments
} from '../core';
import {
  PostConsultantConsultationBackgroundModel,
  PostConsultantEducationBackgroundModel,
  PostConsultantPersonalModel,
  PostConsultantSocialModel,
  PostConsultantWorkBackgroundModel
} from '../models';
import { showToast } from '../utils/toast';
import { handleUpdateAny } from './global';

export const handlePostConsultantPersonal = async (payload: PostConsultantPersonalModel) => {
  try {
    const { data, status } = await postConsultantPersonal(payload);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetConsultantPersonal = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getConsultantPersonal(username);
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return {
        data: {
          ...data.Data,
          CellPhone: `0${data.Data.CellPhone.substring(3)}`,
          CompanyPhone: data.Data.CompanyPhone ? `0${data.Data.CompanyPhone.substring(3)}` : ''
        },
        ok: true
      };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutConsultantPersonal = (
  payload: PostConsultantPersonalModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'ConsultantP');
};

// work
export const handlePostConsultantWorkBackground = async (
  payload: PostConsultantWorkBackgroundModel
) => {
  try {
    const { data, status } = await postConsultantWorkBackground(payload);
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetConsultantWorkBackground = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getConsultantWorkBackground(username);
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return { ok: true, data: data.Data };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleDeleteConsultantWorkBackground = async (id: number | string) => {
  try {
    const { data, status } = await deleteConsultantWorkBackground(id);
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutConsultantWorkBackground = (
  payload: PostConsultantPersonalModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'ConsultantW');
};

// education
export const handlePostConsultantEducationBackground = async (
  payload: PostConsultantEducationBackgroundModel
) => {
  try {
    const { data, status } = await postConsultantEducationBackground(payload);
    if (status === 200) {
      console.log(data);
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetConsultantEducationBackground = async (
  username: string | null | undefined
) => {
  try {
    const { data, status } = await getConsultantEducationBackground(username);
    if (status === 200) {
      console.log(data);
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutConsultantEducationBackground = async (
  payload: PostConsultantPersonalModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'ConsultantE');
};
export const handleDeleteConsultantEducationBackground = async (id: number | string) => {
  try {
    const { data, status } = await deleteConsultantEducationBackground(id);
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
// consultation
export const handlePostConsultantConsultationBackground = async (
  payload: PostConsultantConsultationBackgroundModel
) => {
  try {
    const { data, status } = await postConsultantConsultationBackground(payload);
    if (status === 200) {
      console.log(data);
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetConsultantConsultationBackground = async (
  username: string | null | undefined
) => {
  try {
    const { data, status } = await getConsultantConsultationBackground(username);
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleDeleteConsultantConsultationBackground = async (id: number | string) => {
  try {
    const { data, status } = await deleteConsultantConsultationBackground(id);
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutConsultantConsultationBackground = async (
  payload: PostConsultantPersonalModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'ConsultantC');
};

// socials
export const handlePostConsultantSocial = async (payload: PostConsultantSocialModel) => {
  try {
    const { data, status } = await postConsultantSocial(payload);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutConsultantSocial = (
  payload: PostConsultantSocialModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'ConsultantS');
};

export const handleGetConsultantSocial = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getConsultantSocial(username);
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// request
export const handlePostConsultantRegisterRequest = async (payload: { Group: string }) => {
  try {
    const { data, status } = await postConsultantRegisterRequest(payload);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutConsultantRegisterRequest = async (payload: {
  Group: string;
  Status: string;
}) => {
  try {
    const { data, status } = await putConsultantRegisterRequest(payload);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetConsultantRegisterRequest = async () => {
  try {
    const { data, status } = await getConsultantRegisterRequest();
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetConsultantComments = async (username: string) => {
  try {
    const { data, status } = await getConsultantComments(username);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
