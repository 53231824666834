import { PostIdeaGoodModel, PostRequestIdeaDataModel, PostIdeaServiceModel } from '../models';
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const postIdeaPersonal = (payload: PostRequestIdeaDataModel) =>
  appNetworkManager.post(`/ideatransformationform`, {
    ...payload,
    Username: getUsername()
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getIdeaPersonal = (username: string | null | undefined, formID: any) => {
  return appNetworkManager.get(
    `/ideatransformationform?Username=${username ?? getUsername()}&ID=${formID}`
  );
};

// good
export const postIdeaGood = (payload: PostIdeaGoodModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formgoods`, {
    ...rest,
    Username: getUsername(),
    Form: 'idea'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getIdeaGood = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formgoods?Username=${username ?? getUsername()}&Form=idea&FormID=${formID}`
  );
};
export const deleteIdeaGood = (id: number | string) => {
  return appNetworkManager.delete(`/formgoods?ID=${id}`);
};

// service
export const postIdeaService = (payload: PostIdeaServiceModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formservices`, {
    ...rest,
    Username: getUsername(),
    Form: 'idea'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getIdeaService = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formservices?Username=${username ?? getUsername()}&Form=idea&FormID=${formID}`
  );
};
export const deleteIdeaService = (id: number | string) => {
  return appNetworkManager.delete(`/formservices?ID=${id}`);
};

// register request
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const postIdeaRegisterRequest = (FormID: any) => {
  return appNetworkManager.post(`/ideatransformationformregreq`, {
    Username: getUsername(),
    Status: 'Pending',
    FormID
  });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putIdeaRegisterRequest = (payload: { Status: String; FormID: any }) => {
  return appNetworkManager.put(`/ideatransformationformregreq`, {
    ...payload,
    Username: getUsername()
  });
};
export const getIdeaRegisterRequest = () => {
  return appNetworkManager.get(`/ideatransformationformregreq?Username=${getUsername()}`);
};
export const getIdea = () => {
  return appNetworkManager.get(`/organizationalprojects`);
};
// export const postIdeaBuycv = (seekerUsername: string) => {
//   return appNetworkManager.post(`/buycv`, {
//     EmployerUsername: getUsername(),
//     JobSeekerUsername: seekerUsername
//   });
// };
// export const getIdeaBuycv = () => {
//   return appNetworkManager.get(`/buycv?EmployerUsername=${getUsername()}`);
// };
