import {
  PostSeekerCourseBackgroundModel,
  PostSeekerDesireModel,
  PostSeekerEducationBackgroundModel,
  PostSeekerPersonalModel,
  PostSeekerSkillBackgroundModel,
  PostSeekerWorkBackgroundModel
} from '../models';
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const postSeekerPersonal = (payload: PostSeekerPersonalModel) =>
  appNetworkManager.post(`/jobseekerpersonal`, { ...payload, Username: getUsername() });

export const getSeekerPersonal = (username: string | null | undefined) => {
  return appNetworkManager.get(`/jobseekerpersonal?Username=${username ?? getUsername()}`);
};

export const postSeekerWorkBackground = (payload: PostSeekerWorkBackgroundModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/jobseekerwork`, { ...rest, Username: getUsername() });
};

export const getSeekerWorkBackground = (username: string | null | undefined) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(`/jobseekerwork?Username=${username ?? getUsername()}`);
};
export const deleteSeekerWorkBackground = (id: number | string) => {
  return appNetworkManager.delete(`/jobseekerwork?ID=${id}`);
};

// education
export const postSeekerEducationBackground = (payload: PostSeekerEducationBackgroundModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/jobseekeredu`, { ...rest, Username: getUsername() });
};

export const getSeekerEducationBackground = (username: string | null | undefined) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(`/jobseekeredu?Username=${username ?? getUsername()}`);
};
export const deleteSeekerEducationBackground = (id: number | string) => {
  return appNetworkManager.delete(`/jobseekeredu?ID=${id}`);
};

// course
export const postSeekerCourseBackground = (payload: PostSeekerCourseBackgroundModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/jobseekercourse`, { ...rest, Username: getUsername() });
};

export const getSeekerCourseBackground = (username: string | null | undefined) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(`/jobseekercourse?Username=${username ?? getUsername()}`);
};
export const deleteSeekerCourseBackground = (id: number | string) => {
  return appNetworkManager.delete(`/jobseekercourse?ID=${id}`);
};
// skill
export const postSeekerSkillBackground = (payload: PostSeekerSkillBackgroundModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/jobseekerskill`, { ...rest, Username: getUsername() });
};

export const getSeekerSkillBackground = (username: string | null | undefined) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(`/jobseekerskill?Username=${username ?? getUsername()}`);
};
export const deleteSeekerSkillBackground = (id: number | string) => {
  return appNetworkManager.delete(`/jobseekerskill?ID=${id}`);
};

// desire
export const postSeekerDesire = (payload: PostSeekerDesireModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/jobseekerdesirefield`, { ...rest, Username: getUsername() });
};

export const getSeekerDesire = (username: string | null | undefined) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(`/jobseekerdesirefield?Username=${username ?? getUsername()}`);
};
export const deleteSeekerDesire = (id: number | string) => {
  return appNetworkManager.delete(`/jobseekerdesirefield?ID=${id}`);
};

// register request
export const postSeekerRegisterRequest = () => {
  return appNetworkManager.post(`/jobseekerregreq`, { Username: getUsername(), Status: 'Pending' });
};
export const putSeekerRegisterRequest = (payload: { Status: String }) => {
  return appNetworkManager.put(`/jobseekerregreq`, { ...payload, Username: getUsername() });
};

export const getSeekerRegisterRequest = () => {
  return appNetworkManager.get(`/jobseekerregreq?Username=${getUsername()}`);
};
