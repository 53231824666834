// import { useState } from 'react';
// import LocationIcon from '../../../assets/icons/consultants/location.svg';
// import StarIcon from '../../../assets/icons/consultants/star.svg';
// import LikeIcon from '../../../assets/icons/consultants/like.svg';
// import CheckIcon from '../../../assets/icons/consultants/check.svg';
// import EducationIcon from '../../../assets/icons/consultants/education.svg';
import { Button, Col, FlexboxGrid, Tabs } from 'rsuite';
import { useParams } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import { SeekerPersonal } from './../../seekers/seeker-personal';
import { SeekerWorkBackground } from './../../seekers/seeker-work-background';
import { SeekerEducationBackground } from './../../seekers/seeker-education-background';
import { SeekerCourseBackground } from './../../seekers/seeker-course-background';
import { SeekerDesire } from './../../seekers/seeker-desiere';
import { Spacer } from './../../../components/basic/spacer';
import { useUser } from '../../../contexts/user';
import { ROLE_CONVERTOR } from '../../../utils/helpers';
import { handlePostCorporationBuycv, handleGetCorporationBuycv } from '../../../api-handler';
import { SeekerSkillBackground } from './../../seekers/seeker-skill-background';

export const SingleSeeker = () => {
  const { isUserRole, user } = useUser();
  const { username } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [activeKey, setActiveKey] = useState<any>('WorkBackground');
  // const [showRequestConsult, setShowRequestConsult] = useState(false);
  const [isBought, setIsBought] = useState(false);
  const handleBuySeeker = useCallback(async () => {
    if (!username) return;
    const res = await handlePostCorporationBuycv(username);
    // todo add payment around here
    if (res.ok) {
      setIsBought(true);
    }
  }, [username]);

  useEffect(() => {
    (async () => {
      const res = await handleGetCorporationBuycv();
      if (res.ok) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const bought = res.data.filter((item: any) => item.JobSeekerUsername == username);
        console.log(res.data);
        setIsBought(bought.length > 0);
      }
    })();
  }, [username]);
  return (
    <FlexboxGrid>
      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
        <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 md:p-8 w-full">
          <>
            <h4 className="text-h4 text-[--rs-primary-800] mb-3">
              اطلاعات کارجو {isBought ? '(خریداری شده)' : null}
            </h4>
            <Tabs activeKey={activeKey} onSelect={setActiveKey} appearance="subtle">
              <Tabs.Tab eventKey="WorkBackground" title="سابقه شغلی">
                <Spacer s={4} />
                <SeekerWorkBackground disabledMode={true} isAdmin={true} username={username} />
              </Tabs.Tab>
              <Tabs.Tab eventKey="EducationBackground" title="سابقه تحصیلی">
                <Spacer s={4} />
                <SeekerEducationBackground disabledMode={true} isAdmin={true} username={username} />
              </Tabs.Tab>
              <Tabs.Tab eventKey="CourseBackground" title="دوره های گذرانده">
                <Spacer s={4} />
                <SeekerCourseBackground disabledMode={true} isAdmin={true} username={username} />
              </Tabs.Tab>
              <Tabs.Tab eventKey="SkillBackground" title="مهارت ها">
                <Spacer s={4} />
                <SeekerSkillBackground disabledMode={true} isAdmin={true} username={username} />
              </Tabs.Tab>
              <Tabs.Tab eventKey="Desire" title="زمینه های مورد علاقه">
                <Spacer s={4} />
                <SeekerDesire disabledMode={true} isAdmin={true} username={username} />
              </Tabs.Tab>
              <Tabs.Tab eventKey="Personal" title="اطلاعات پایه">
                <Spacer s={4} />
                {!isUserRole('Corporation') ? (
                  <div className="h-[20vh] text-center text-[18px] mt-8">
                    شما در نقش
                    <strong> {ROLE_CONVERTOR[user.selectedRole]} </strong>
                    هستید
                    <br />
                    برای مشاهده اطلاعات پایه باید نقش <strong> کسب و کار </strong> را دارا باشید.
                    <br />
                    در صورتی که درخواست شما تایید شده باشد، می توانید نقش
                    <strong> کسب و کار </strong> را انتخاب کنید و اطلاعات پایه را مشاهده کنید.
                  </div>
                ) : !isBought ? (
                  <Button appearance="primary" onClick={handleBuySeeker}>
                    خرید این رزومه
                  </Button>
                ) : (
                  <SeekerPersonal disabledMode={true} isAdmin={true} username={username} />
                )}
              </Tabs.Tab>
            </Tabs>
          </>
        </div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};
