/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteInvestmentGood,
  deleteInvestmentService,
  getInvestmentGood,
  getInvestmentPersonal,
  getInvestmentRegisterRequest,
  getInvestment,
  getInvestmentService,
  postInvestmentGood,
  postInvestmentPersonal,
  postInvestmentRegisterRequest,
  postInvestmentService,
  putInvestmentRegisterRequest
} from '../core';
import {
  PostInvestmentGoodModel,
  PostRequestInvestmentDataModel,
  PostInvestmentServiceModel
} from '../models';
import { showToast } from '../utils/toast';
import { handleUpdateAny } from './global';

export const handlePostInvestmentPersonal = async (payload: PostRequestInvestmentDataModel) => {
  try {
    const { data, status } = await postInvestmentPersonal(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetInvestmentPersonal = async (
  username: string | null | undefined,
  formId: string | null | undefined
) => {
  try {
    const { data, status } = await getInvestmentPersonal(username, formId);
    // return { ok: true, payload };
    if (status === 200) {
      return {
        data: {
          ...data.Data
        },
        ok: true
      };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutInvestmentPersonal = (
  payload: PostRequestInvestmentDataModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'ProfitableInvestmentRequestForm');
};

// good
export const handlePostInvestmentGood = async (payload: PostInvestmentGoodModel) => {
  try {
    const { data, status } = await postInvestmentGood(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInvestmentGood = async (username: string | null | undefined, formID: any) => {
  try {
    const { data, status } = await getInvestmentGood(username, formID);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutInvestmentGood = async (
  payload: PostInvestmentGoodModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'FormGoodsTable');
};
export const handleDeleteInvestmentGood = async (id: number | string) => {
  try {
    const { data, status } = await deleteInvestmentGood(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// service
export const handlePostInvestmentService = async (payload: PostInvestmentServiceModel) => {
  try {
    const { data, status } = await postInvestmentService(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInvestmentService = async (
  username: string | null | undefined,
  formID: any
) => {
  try {
    const { data, status } = await getInvestmentService(username, formID);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutInvestmentService = async (
  payload: PostInvestmentServiceModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'FormServicesTable');
};
export const handleDeleteInvestmentService = async (id: number | string) => {
  try {
    const { data, status } = await deleteInvestmentService(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// // request
// eslint-disable-next-line @typescript-eslint/naming-convention
export const handlePostInvestmentRegisterRequest = async (FormID: any) => {
  try {
    const { data, status } = await postInvestmentRegisterRequest(FormID);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutInvestmentRegisterRequest = async (payload: {
  Status: string;
  FormID: any;
}) => {
  try {
    const { data, status } = await putInvestmentRegisterRequest(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInvestmentRegisterRequest = async () => {
  try {
    const { data, status } = await getInvestmentRegisterRequest();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInvestments = async () => {
  try {
    const { data, status } = await getInvestment();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// export const handlePostInvestmentBuycv = async (seekerUsername: string) => {
//   try {
//     const { data, status } = await postInvestmentBuycv(seekerUsername);
//     // return { ok: true, payload };
//     if (status === 200) {
//       return { data: data.Data, ok: true };
//     } else {
//       showToast(data.Message, 'error');
//     }
//     console.log('NOT 200', data);
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     if (e.response?.status === 404) {
//       console.log('not submitted');
//       return { data: {}, ok: false };
//     }
//     console.log(e.response?.data.Message?.FA);
//     showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
//   }
//   return { ok: false };
// };
// export const handleGetInvestmentBuycv = async () => {
//   try {
//     const { data, status } = await getInvestmentBuycv();
//     // return { ok: true, payload };
//     if (status === 200) {
//       return { data: data.Data, ok: true };
//     } else {
//       showToast(data.Message, 'error');
//     }
//     console.log('NOT 200', data);
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     if (e.response?.status === 404) {
//       console.log('not submitted');
//       return { data: [], ok: false };
//     }
//     console.log(e.response?.data.Message?.FA);
//     showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
//   }
//   return { ok: false };
// };
