// import { Button } from 'rsuite';
// import { useState } from 'react';
import { Spacer } from '../../../components/basic/spacer';
import { MarketingPersonal } from '../../forms/marketing/marketing';
import { MarketingGood } from '../../forms/marketing/marketing-good';
import { MarketingService } from '../../forms/marketing/marketing-service';
import { useParams } from 'react-router-dom';
import { UserBasicDataView } from '../users/basic-data';
// import {
//   // handleAcceptMarketing,
//   handleGetMarketingRequest
//   // handleTerminateMarketing
// } from '../../../api-handler';
// import { ValueType } from 'rsuite/esm/Checkbox';
// import { useNavigate } from 'react-router-dom';
// import { STATUS_CONVERTOR } from '../../../utils/helpers';
// import { showToast } from '../../../utils/toast';
// import { useData } from '../../../contexts/data';

export const AdminRegisterMarketingSingle = () => {
  // const navigate = useNavigate();
  // const { marketingPersonal } = useData();

  // const [loading, setLoading] = useState(false);
  const { username } = useParams();
  // const [accept, setAccept] = useState<ValueType>('Pending');
  // const [wasAccept, setWasAccept] = useState<ValueType>('Pending');
  // const [whyReject, setWhyReject] = useState('');

  // const onAcceptMarketing = useCallback(async () => {
  //   if (username) {
  //     setLoading(true);
  //     if (accept === 'Terminated') {
  //       const res = await handleTerminateMarketing(username, whyReject);
  //       if (res.ok) {
  //         showToast(
  //           `وضعیت با موفقیت به "${STATUS_CONVERTOR[accept]?.default}" تغییر کرد`,
  //           'success'
  //         );
  //         navigate('/admin/register-marketing-list');
  //       }
  //       setLoading(false);
  //       return;
  //     }
  //     const res = await handleAcceptMarketing(
  //       accept,
  //       username,
  //       whyReject,
  //       wasAccept === 'Accepted',
  //       marketingPersonal
  //     );
  //     if (res.ok) {
  //       showToast(`وضعیت با موفقیت به "${STATUS_CONVERTOR[accept]?.default}" تغییر کرد`, 'success');
  //       navigate('/admin/register-marketing-list');
  //     }
  //     setLoading(false);
  //   }
  // }, [username, accept, whyReject, wasAccept]);

  // useEffect(() => {
  //   (async () => {
  //     const res = await handleGetMarketingRequest(username ?? '');
  //     if (res.ok) {
  //       setAccept(res.data.Status);
  //       setWasAccept(res.data.Status);
  //       setWhyReject(res.data.Description);
  //     }
  //   })();
  // }, [username]);

  return (
    <>
      <h4 className="text-h4"></h4>
      {username ? (
        <>
          <UserBasicDataView username={username} />
          <Spacer s={4} />
          <MarketingPersonal disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={4} />
          <MarketingGood disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={4} />
          <MarketingService disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={2} />
          {/* <Divider /> */}
          {/* <h4 className="text-h4">وضعیت مورد نظر را انتخاب کنید</h4>
          <RadioGroup
            className="flex mb-4"
            value={accept}
            onChange={(value: ValueType, _event: SyntheticEvent<Element, Event>) =>
              setAccept(value)
            }>
            <div className="flex">
              <Radio className="" value={'Accepted'} dir="rtl">
                تایید
              </Radio>
              <Radio className="" value={'Pending'}>
                در انتظار تایید
              </Radio>
              <Radio className="" value={'Rejected'}>
                رفع نقص
              </Radio>
              <Radio className="" value={'Suspended'}>
                تعلیق
              </Radio>
              <Radio className="" value={'Terminated'}>
                مختومه (پاک کردن تمام اطلاعات)
              </Radio>
            </div>
          </RadioGroup> */}
          {/* {accept === 'Accepted' ? (
            <>
              <Button
                appearance="primary"
                className="mr-auto mt-8 w-[120px]"
                type="button"
                onClick={() => onAcceptMarketing()}
                loading={loading}>
                تایید کسب و کار
              </Button>
            </>
          ) : accept === 'Rejected' || accept === 'Terminated' || accept === 'Suspended' ? (
            <>
              <span>علت</span>
              <Input as="textarea" value={whyReject} onChange={setWhyReject} rows={4} />
              <Button
                appearance="primary"
                className="mr-auto mt-8 w-[120px]"
                type="button"
                onClick={() => onAcceptMarketing()}
                loading={loading}>
                {accept === 'Terminated' ? 'رد' : accept === 'Rejected' ? 'رفع نقص' : 'تعلیق'} کسب و
                کار
              </Button>
            </>
          ) : (
            <Button
              appearance="primary"
              className="mr-auto mt-8 w-[120px]"
              type="button"
              onClick={() => onAcceptMarketing()}
              loading={loading}>
              ثبت در انتظار
            </Button>
          )} */}
        </>
      ) : null}
    </>
  );
};
