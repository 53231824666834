import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Input, InputPicker, Radio, RadioGroup, FlexboxGrid } from 'rsuite';
import TextField from '../../components/form/textfield';
import Field from '../../components/form/field';

import { showToast } from '../../utils/toast';
import { Datepicker } from '@ijavad805/react-datepicker';

import {
  handlePostCorporationPersonal,
  handleGetCorporationPersonal,
  handlePutCorporationPersonal
} from '../../api-handler';
import { CorporationPersonalFormSchema } from '../../models';

import { ROUTES } from '../../router';
import { base64ToJson, convertDateToSlashi, jsonToBase64 } from '../../utils/helpers';
import { UploadField } from '../../components/form/uploader';
import { CountryStateCity } from '../../components/form/country-state-city';
import TextAreaField from '../../components/form/textarea-field';
import { CustomLoader } from '../../components/basic/loader';
import EducationDegreeOptions from '../../data/global/education-degree.json';
import { useData } from '../../contexts/data';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any

export const CorporationPersonal = (props: {
  disabledMode?: boolean;
  isAdmin?: boolean;
  username?: string;
}) => {
  const navigate = useNavigate();
  const { setCorporationPersonal } = useData();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initial, setInitial] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});

  const [sex, setSex] = useState('آقا');

  const [timeStamp, setTimeStamp] = useState<Date>(new Date());
  const [timeStamp2, setTimeStamp2] = useState<Date>(new Date());
  const [loadingUpload, setLoadingUpload] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadedPICNIDUrl, setUploadedPICNIDUrl] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadedPICGLedgerUrl, setUploadedPICGLedgerUrl] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadedPICLastChangedUrl, setUploadedPICLastChangedUrl] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initPICGLedgerJson, setInitPICGLedgerJson] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initPICNIDJson, setInitPICNIDJson] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initPICLastChangedJson, setInitPICLastChangedJson] = useState<any>([]);

  const normalizeFormValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fv: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const PICNIDJson: any = [...initPICNIDJson];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(uploadedPICNIDUrl).forEach((file: any) => {
        PICNIDJson.push(uploadedPICNIDUrl[file]);
      });
      const PICNIDEncoded = jsonToBase64(PICNIDJson);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const PICGLedgerJson: any = [...initPICGLedgerJson];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(uploadedPICGLedgerUrl).forEach((file: any) => {
        PICGLedgerJson.push(uploadedPICGLedgerUrl[file]);
      });
      const PICGLedgerEncoded = jsonToBase64(PICGLedgerJson);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const PICLastChangedJson: any = [...initPICLastChangedJson];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(uploadedPICLastChangedUrl).forEach((file: any) => {
        PICLastChangedJson.push(uploadedPICLastChangedUrl[file]);
      });
      const PICLastChangedEncoded = jsonToBase64(PICLastChangedJson);

      setInitPICNIDJson(PICNIDJson);
      setInitPICGLedgerJson(PICGLedgerJson);
      setInitPICLastChangedJson(PICLastChangedJson);

      const payload = {
        ...fv,
        Sex: sex === 'آقا',
        CellPhone: `+98${fv.CellPhone.substring(1)}`,
        DateOfEstablishment: new Date(timeStamp),
        CEOBirthDate: new Date(timeStamp2),
        // Country: 'ایران',
        Country: formValue['Country-t'] == 'ایران' ? 'ایران' : formValue.Country ?? 'ایران',
        PICNID: PICNIDEncoded,
        PICGLedger: PICGLedgerEncoded,
        PICLastChanged: PICLastChangedEncoded
      };
      if (payload['Country-t']) delete payload['Country-t'];

      if (payload.File) delete payload.File;

      return payload;
    },
    [
      uploadedPICNIDUrl,
      uploadedPICGLedgerUrl,
      uploadedPICLastChangedUrl,
      initPICNIDJson,
      initPICGLedgerJson,
      initPICLastChangedJson
    ]
  );

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }
    console.log(formValue);
    if (!formValue) return;
    const payload = normalizeFormValue(formValue);
    if (!payload) return;
    setLoading(true);
    if (editMode) {
      // todo check update
      const updateRes = await handlePutCorporationPersonal(payload, 'Username', initial.Username);
      if (updateRes.ok) {
        showToast('مشخصات فردی به روزرسانی شد!', 'success');
        navigate(ROUTES.roles.reqCorporation.intro);
      }
      setLoading(false);
      return;
    }
    console.log(payload);
    const res = await handlePostCorporationPersonal(payload);
    if (res.ok) {
      showToast('مشخصات فردی ثبت شد!', 'success');
      navigate(ROUTES.roles.reqCorporation.intro);
      setLoading(false);
      return;
    }
    setLoading(false);
  }, [
    formValue,
    formError,
    timeStamp,
    timeStamp2,
    sex,
    formRef,
    uploadedPICNIDUrl,
    uploadedPICGLedgerUrl,
    uploadedPICLastChangedUrl,
    initPICNIDJson,
    initPICGLedgerJson,
    initPICLastChangedJson
  ]);

  const handleGetInitialData = async (username: string | null | undefined) => {
    setUploadedPICNIDUrl({});
    setUploadedPICGLedgerUrl({});
    setUploadedPICLastChangedUrl({});
    setLoading(true);
    const res = await handleGetCorporationPersonal(username);
    if (res.ok) {
      setCorporationPersonal(res.data);
      setInitial(res.data);
      setFormValue(res.data);
      setSex(res.data.Sex ? 'آقا' : 'خانم');
      setTimeStamp(res.data.DateOfEstablishment);
      setTimeStamp2(res.data.CEOBirthDate);
      setEditMode(true);
      setInitPICGLedgerJson(base64ToJson(res.data?.PICGLedger));
      setInitPICNIDJson(base64ToJson(res.data?.PICNID));
      setInitPICLastChangedJson(base64ToJson(res.data?.PICLastChanged));
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetInitialData(props.isAdmin ? props.username : null);
    })();
  }, [props.username]);

  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <h4 className="text-h4">
        {props.isAdmin ? 'مشخصات کسب و کار' : 'مشخصات کسب و کار خود را وارد کنید'}
      </h4>

      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={CorporationPersonalFormSchema}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading}
        disabled={props.disabledMode}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={16} className="max-w-[100%] !px-0">
            <FlexboxGrid>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
                <Field
                  accepter={Datepicker}
                  name="DateOfEstablishment"
                  lang={'fa'}
                  theme={'green'}
                  format={'D MMMM yyyy'}
                  value={convertDateToSlashi(timeStamp)}
                  input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(val: any) => setTimeStamp(new Date(val))}
                  label="تاریخ تاسیس"
                  dir="ltr"
                  full
                  req
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
                <TextField
                  name="FullName"
                  label="نام کسب و کار"
                  type="text"
                  accepter={Input}
                  dir="rtl"
                  full
                  placeholder=""
                  req
                />
              </FlexboxGrid.Item>

              <FlexboxGrid className="w-full">
                <CountryStateCity
                  hasCountry={true}
                  setFormValue={setFormValue}
                  stateOptions={{
                    default: formValue?.State,
                    name: 'State',
                    label: 'استان',
                    colspan: 24,
                    sm: 24,
                    md: 8
                  }}
                  cityOptions={{
                    default: formValue?.City,
                    name: 'City',
                    label: 'شهر',
                    colspan: 24,
                    sm: 24,
                    md: 8
                  }}
                  countryOptions={{
                    default: formValue?.Country,
                    name: 'Country',
                    label: 'کشور',
                    colspan: 24,
                    sm: 24,
                    md: 8
                  }}
                />
              </FlexboxGrid>
            </FlexboxGrid>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] !px-0">
            <FlexboxGrid>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <TextAreaField name="Address" label="آدرس ثبتی" dir="rtl" full req placeholder="" />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="PostalCode"
              label="کدپستی ثبتی"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="RegistrationNumber"
              label="شماره ثبت / مجوز"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="NID"
              label="شناسه ملی"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              accepter={InputPicker}
              data={[
                { label: 'دولتی', value: 'دولتی' },
                { label: 'خصوصی', value: 'خصوصی' },
                { label: 'تعاونی', value: 'تعاونی' }
              ]}
              name="TypeLegal"
              label="نوع شخصیت حقوقی"
              dir="rtl"
              full
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEOFirstName"
              label="نام مدیرعامل / نماینده قانونی "
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEOLastName"
              label="نام خانوادگی مدیرعامل / نماینده قانونی "
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEONID"
              label="کد ملی مدیرعامل / نماینده قانونی"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEORID"
              label="شماره شناسنامه مدیرعامل / نماینده قانونی "
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEOFathersName"
              label="نام پدر مدیرعامل / نماینده قانونی"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="Email"
              label="ایمیل شخصیت حقوقی"
              type="email"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CellPhone"
              label="شماره تلفن همراه"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="FixNumber"
              label="شماره تماس ثابت شخصیت حقوقی"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="Website"
              label="آدرس سایت شخصیت حقوقی"
              type="text"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEOFieldOfStudy"
              label="رشته تحصیلی مدیرعامل / نماینده قانونی"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              accepter={InputPicker}
              data={EducationDegreeOptions}
              name="CEOEducationOfThe"
              label="میزان تحصیلات مدیرعامل / نماینده قانونی"
              dir="rtl"
              full
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEODirection"
              label="گرایش تحصیلات مدیرعامل / نماینده قانونی"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              accepter={Datepicker}
              name="CEOBirthDate"
              lang={'fa'}
              theme={'green'}
              format={'D MMMM yyyy'}
              value={convertDateToSlashi(timeStamp2)}
              input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(val: any) => setTimeStamp2(new Date(val))}
              label="تاریخ تولد مدیرعامل / نماینده قانونی"
              dir="ltr"
              full
              req
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              name="Sex"
              label="جنسیت مدیرعامل / نماینده قانونی"
              accepter={RadioGroup}
              value={sex}
              onChange={(value: string) => setSex(value)}>
              <div className="flex">
                <Radio className="w-[50%]" value={'خانم'}>
                  خانم
                </Radio>
                <Radio className="w-[50%]" value={'آقا'}>
                  آقا
                </Radio>
              </div>
            </Field>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <UploadField
              label="تصویر نامه رسمی معرفی مدیر عامل"
              req
              full
              setLoadingUpload={setLoadingUpload}
              name="PICGLedger"
              fileName="PICGLedger"
              domain="Uploads/"
              initJson={initPICGLedgerJson}
              setInitJson={setInitPICGLedgerJson}
              uploadedUrl={uploadedPICGLedgerUrl}
              setUploadedUrl={setUploadedPICGLedgerUrl}
              readOnly={props.isAdmin ?? props.disabledMode}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <UploadField
              label="تصویر آگهی ثبتی آخرین تغییرات"
              req
              full
              setLoadingUpload={setLoadingUpload}
              name="PICLastChanged"
              fileName="PICLastChanged"
              domain="Uploads/"
              initJson={initPICLastChangedJson}
              setInitJson={setInitPICLastChangedJson}
              uploadedUrl={uploadedPICLastChangedUrl}
              setUploadedUrl={setUploadedPICLastChangedUrl}
              readOnly={props.isAdmin ?? props.disabledMode}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <UploadField
              label="تصویر کارت ملی مدیرعامل / نماینده قانونی"
              req
              full
              setLoadingUpload={setLoadingUpload}
              name="PICNID"
              fileName="PICNID"
              domain="Uploads/"
              initJson={initPICNIDJson}
              setInitJson={setInitPICNIDJson}
              uploadedUrl={uploadedPICNIDUrl}
              setUploadedUrl={setUploadedPICNIDUrl}
              readOnly={props.isAdmin ?? props.disabledMode}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        {!props.disabledMode ? (
          <div className="flex justify-between">
            <Button
              appearance="primary"
              className="mr-auto mt-8"
              type="submit"
              loading={loading || loadingUpload}>
              مرحله بعد
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  );
};
