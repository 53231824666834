import {
  PostFounderGoodModel,
  PostRequestFounderDataModel,
  PostFounderServiceModel
} from '../models';
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const postFounderPersonal = (payload: PostRequestFounderDataModel) =>
  appNetworkManager.post(`/investmentform`, { ...payload, Username: getUsername() });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFounderPersonal = (username: string | null | undefined, formID: any) => {
  return appNetworkManager.get(
    `/investmentform?Username=${username ?? getUsername()}&ID=${formID}`
  );
};

// good
export const postFounderGood = (payload: PostFounderGoodModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formgoods`, {
    ...rest,
    Username: getUsername(),
    Form: 'founder'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFounderGood = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formgoods?Username=${username ?? getUsername()}&Form=founder&FormID=${formID}`
  );
};
export const deleteFounderGood = (id: number | string) => {
  return appNetworkManager.delete(`/formgoods?ID=${id}`);
};

// service
export const postFounderService = (payload: PostFounderServiceModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formservices`, {
    ...rest,
    Username: getUsername(),
    Form: 'founder'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFounderService = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formservices?Username=${username ?? getUsername()}&Form=founder&FormID=${formID}`
  );
};
export const deleteFounderService = (id: number | string) => {
  return appNetworkManager.delete(`/formservices?ID=${id}`);
};

// register request
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const postFounderRegisterRequest = (FormID: any) => {
  return appNetworkManager.post(`/investmentformregreq`, {
    Username: getUsername(),
    Status: 'Pending',
    FormID
  });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putFounderRegisterRequest = (payload: { Status: String; FormID: any }) => {
  return appNetworkManager.put(`/investmentformregreq`, { ...payload, Username: getUsername() });
};

export const getFounderRegisterRequest = () => {
  return appNetworkManager.get(`/investmentformregreq?Username=${getUsername()}`);
};
export const getFounders = () => {
  return appNetworkManager.get(`/founders`);
};
// export const postFounderBuycv = (seekerUsername: string) => {
//   return appNetworkManager.post(`/buycv`, {
//     EmployerUsername: getUsername(),
//     JobSeekerUsername: seekerUsername
//   });
// };
// export const getFounderBuycv = () => {
//   return appNetworkManager.get(`/buycv?EmployerUsername=${getUsername()}`);
// };
