import {
  getConsultReportByConsultant,
  getConsultReportByUser,
  getConsultRequestsOfAConsultant,
  getConsultRequestsOfAUser,
  getSession,
  patchSessionDoneByConsultant,
  postConsultReportByConsultant,
  postConsultReportByUser,
  postConsultRequest,
  postSessionTimeByConsultant,
  putSessionTimeByConsultant,
  putSessionTimeByUser
} from '../core/consult';
import { ConsultReportByConsultant, ConsultReportByUser, ConsultRequest } from '../models/consult';
import { showToast } from '../utils/toast';

export const handlePostConsultReportByConsultant = async (payload: ConsultReportByConsultant) => {
  try {
    const { data, status } = await postConsultReportByConsultant(payload);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetConsultReportByConsultant = async (id: number) => {
  try {
    const { data, status } = await getConsultReportByConsultant(id);
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePostConsultReportByUser = async (payload: ConsultReportByUser) => {
  try {
    const { data, status } = await postConsultReportByUser(payload);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetConsultReportByUser = async (id: number) => {
  try {
    const { data, status } = await getConsultReportByUser(id);
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePostConsultRequest = async (payload: ConsultRequest) => {
  try {
    const { data, status } = await postConsultRequest(payload);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlePostSessionTimeByConsultant = async (id: number, sessionTimes: any) => {
  try {
    const { data, status } = await postSessionTimeByConsultant(id, sessionTimes);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutSessionTimeByUser = async (
  id: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sessionTimes: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedTime: any
) => {
  try {
    const { data, status } = await putSessionTimeByUser(id, sessionTimes, selectedTime);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutSessionTimeByConsultant = async (
  id: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  address: string
) => {
  try {
    const { data, status } = await putSessionTimeByConsultant(id, address);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePatchSessionDoneByConsultant = async (id: number) => {
  try {
    const { data, status } = await patchSessionDoneByConsultant(id);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetSession = async (id: number) => {
  try {
    const { data, status } = await getSession(id);
    // return { ok: true, payload };
    if (status === 200) {
      console.log(data);
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetConsultRequestsOfAConsultant = async (username: string) => {
  try {
    const { data, status } = await getConsultRequestsOfAConsultant(username);
    if (status === 200) {
      console.log(data);
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    if (e.response?.status === 404) {
      showToast('درخواستی موجود نیست!', 'error');
      return { ok: false };
    }
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetConsultRequestsOfAUser = async (username: string) => {
  try {
    const { data, status } = await getConsultRequestsOfAUser(username);
    if (status === 200) {
      console.log(data);
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    if (e.response?.status === 404) {
      showToast('درخواستی موجود نیست!', 'error');
      return { ok: false };
    }
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
