/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getUserProfilePicture,
  postUserProfilePicture,
  putUserProfilePictureUser,
  getUserProfilePictureByUsername
} from '../core';
import { showToast } from '../utils/toast';
import { handleUpdateAny } from './global';

export const handleGetUserProfilePicture = async () => {
  try {
    const { data, status } = await getUserProfilePicture();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetUserProfilePictureByUsername = async (username: string) => {
  try {
    const { data, status } = await getUserProfilePictureByUsername(username);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePostUserProfilePicture = async (payload: any) => {
  try {
    const { data, status } = await postUserProfilePicture(payload);
    if (status === 200) {
      console.log(data);
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutUserProfilePictureUser = async (payload: any) => {
  try {
    const { data, status } = await putUserProfilePictureUser(payload);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutUserProfilePictureAdmin = async (
  id: number,
  status: string,
  description: string | null
) => {
  return handleUpdateAny({ Status: status, Description: description }, 'ID', id, 'LogoPicture');
};
