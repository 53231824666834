/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, createContext, useState, ReactChild, useContext, useCallback } from 'react';
import { Button, ButtonToolbar, Input, Modal } from 'rsuite';

interface ModalContext {
  test?: string;
  setTest?: any;
  modalFunc?: any;
  modalArgs?: any;
  modalBody?: any;
  modalTitle?: any;
  open?: any;
  handleOpen?: any;
  handleClose?: any;
  handleSuccessClose?: any;
  setModalFunc?: any;
  setModalArgs?: any;
  setModalBody?: any;
  setModalTitle?: any;
  setOpen?: any;
  hasTextArea?: boolean;
  setHasTextArea?: any;
}

const context = createContext<ModalContext>({});

const { Provider } = context;

interface Props {
  children: ReactChild | ReactChild[];
}

export const ModalProvider: FC<Props> = ({ children }) => {
  // const [test, setTest] = useState("5");

  type CallbackFunctionWithArgs = (...args: any[]) => void;

  const [modalFunc, setModalFunc] = useState<CallbackFunctionWithArgs>();
  const [modalArgs, setModalArgs] = useState();
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalOkButtonText, setModalOkButton] = useState('');
  const [modalCloseButton, setModalCloseButton] = useState('');
  const [reversedButton, setReversedButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [hasTextArea, setHasTextArea] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');

  const handleOpen = useCallback(
    (
      func: any,
      arg: any,
      body = 'آیا از تصمیم خود اطمینان دارید؟',
      title = '',
      okButton = 'بلی',
      closeButton = 'خیر',
      reversed = false,
      hasTextAreaa = false
    ) => {
      console.log(arg);
      setModalFunc(func);
      setModalArgs(arg);
      setModalBody(body);
      setModalTitle(title);
      setModalOkButton(okButton);
      setModalCloseButton(closeButton);
      setReversedButton(reversed);
      setOpen(true);
      setHasTextArea(hasTextAreaa);
      setTextAreaValue('');
    },
    []
  );
  const handleSuccessClose = useCallback(() => {
    setOpen(false);
    if (modalFunc) {
      if (hasTextArea) {
        modalFunc(modalArgs, textAreaValue);
      } else {
        modalFunc(modalArgs);
      }
    }
  }, [modalFunc, modalArgs, textAreaValue]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Provider
      value={{
        // test,
        // setTest,
        // modalFunc,
        // modalArgs,
        // modalBody,
        // modalTitle,
        open,
        handleOpen,
        handleClose,
        handleSuccessClose,
        // setModalFunc,
        // setModalArgs,
        // setModalBody,
        // setModalTitle,
        setOpen
      }}>
      {children}
      <Modal open={open} onClose={handleClose} size="md">
        <Modal.Header className="">
          <Modal.Title className="py-2 text-center">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          {modalBody}
          {hasTextArea ? (
            <div className=" mt-2">
              <Input value={textAreaValue} onChange={setTextAreaValue} as="textarea" rows={5} />
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="flex justify-between mt-4">
            <Button
              onClick={reversedButton ? handleClose : handleSuccessClose}
              appearance="primary"
              className="btn-outline">
              {modalOkButtonText}
            </Button>
            <Button
              onClick={reversedButton ? handleSuccessClose : handleClose}
              appearance="default"
              className="btn-outline-error !ml-0">
              {modalCloseButton}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </Provider>
  );
};

export const useModal = () => useContext(context);
