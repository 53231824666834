import { Button, Col, FlexboxGrid, Form, Input, InputPicker } from 'rsuite';
import { useCallback, useRef, useState } from 'react';
import Field from '../../../components/form/field';
import { showToast } from '../../../utils/toast';
import { handlePostSeekerDesire, handlePutSeekerDesire } from '../../../api-handler';
import {
  PostSeekerDesireModel,
  defaultSeekerDesireFormValue,
  defaultSeekerDesireSchema
} from '../../../models';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router';
import seekerCategoryOptions from '../../../data/seeker/seeker-category.json';
import seekerCategory2Optionss from '../../../data/seeker/seeker-category-2.json';
import TextField from './../../../components/form/textfield';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const seekerCategory2Options: any = seekerCategory2Optionss;
export const DesireForm = (props: {
  readonly?: boolean;
  handleAddDoingData: (newData: PostSeekerDesireModel) => void;
  defaultValue?: PostSeekerDesireModel | null;
  hideNextButton?: boolean;
  forceReadOnly?: boolean;
  disabledMode?: boolean;
  hasEditMode?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  isAdmin: boolean;
  onDelete: (id: string | number) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [disabledMode, setDisabledMode] = useState(props.hasEditMode);
  const [forceReadOnly] = useState(props.forceReadOnly);
  const [readOnly, setReadOnly] = useState(props.readonly);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>(
    props.defaultValue ?? defaultSeekerDesireFormValue
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [defaultValue, setDefaultValue] = useState<any>(
    props.defaultValue ?? defaultSeekerDesireFormValue
  );

  const handleSubmit = useCallback(
    async (isNext: boolean, updateMode: boolean) => {
      if (formRef.current && !formRef.current.check()) {
        console.log('formError', formError);
        return;
      }

      if (!formValue) return;

      const payload = {
        ...formValue
      };

      console.log(payload);
      setLoading(true);
      if (updateMode) {
        if (payload.Username) delete payload.Username;
        const ress = await handlePutSeekerDesire(payload, 'ID', payload.ID);
        if (ress.ok) {
          showToast('فرم به روزرسانی شد!', 'success');
          setDisabledMode(true);
          // setForceReadOnly(true);
          setReadOnly(true);
          setLoading(false);
          setDefaultValue(payload);
          if (isNext) {
            navigate(ROUTES.roles.reqSeeker.tnc);
            return;
          }
        }
        setLoading(false);
        return;
      }
      const res = await handlePostSeekerDesire(payload);
      setLoading(false);
      if (res.ok) {
        showToast('فرم ثبت شد!', 'success');
        // or getting submitted data from backend
        if (isNext) {
          navigate(ROUTES.roles.reqSeeker.tnc);
          return;
        }
        props.handleAddDoingData({ ...payload, ID: res.data.ID });
        setFormValue(defaultSeekerDesireFormValue);
      }
    },
    [formValue, formError, formRef]
  );

  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={defaultSeekerDesireSchema}
      onSubmit={() => handleSubmit(false, false)}
      formValue={formValue}
      checkTrigger="change"
      className="mt-6 text-dark"
      readOnly={forceReadOnly ?? loading}
      disabled={disabledMode}>
      <FlexboxGrid className="bg-[--rs-primary-50] rounded-md border-[1px] border-[--rs-primary-500] p-3">
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
          <Field
            name="LayerOne"
            label="زمینه اصلی"
            accepter={InputPicker}
            data={seekerCategoryOptions}
            dir="rtl"
            full
            req
            readOnly={readOnly}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
          <Field
            name="Layertwo"
            label="زمینه فرعی"
            accepter={InputPicker}
            data={seekerCategory2Options[formValue.LayerOne]}
            dir="rtl"
            full
            req
            disabled={!formValue.LayerOne || disabledMode}
            readOnly={readOnly}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
          <TextField
            name="LayerThree"
            label="زمینه سطح 3"
            type="text"
            accepter={Input}
            value="-"
            dir="rtl"
            full
            placeholder=""
            // req
            readOnly={readOnly}
            disabled
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
          {props.isLast && !props.isAdmin ? (
            <div className="flex justify-between">
              {defaultValue.ID ? (
                <Button
                  appearance="primary"
                  type="button"
                  onClick={() => {
                    setDisabledMode(false);
                    // setForceReadOnly(false);
                    setReadOnly(false);
                  }}
                  loading={loading}>
                  ویرایش
                </Button>
              ) : null}
              {!props.isFirst ? (
                <Button
                  appearance="ghost"
                  type="button"
                  className="mr-auto"
                  onClick={() => {
                    props.onDelete(defaultValue.ID ?? null);
                  }}
                  loading={loading}>
                  حذف
                </Button>
              ) : null}
            </div>
          ) : null}
          {props.hasEditMode && !props.isAdmin ? (
            <div>
              {disabledMode ? (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      setDisabledMode(false);
                      // setForceReadOnly(false);
                      setReadOnly(false);
                    }}
                    loading={loading}>
                    ویرایش
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      props.onDelete(defaultValue.ID ?? null);
                    }}
                    loading={loading}>
                    حذف
                  </Button>
                </div>
              ) : (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      handleSubmit(false, true);
                    }}
                    loading={loading}>
                    به روزرسانی
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      setFormValue(defaultValue);
                      setDisabledMode(true);
                      // setForceReadOnly(true);
                      setReadOnly(true);
                    }}
                    loading={loading}>
                    لغو
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {!props.isLast ? null : (
        <>
          <div className="flex justify-between">
            <Button
              appearance="primary"
              className="ml-auto mt-4"
              type="button"
              onClick={() => handleSubmit(false, false)}
              loading={loading}>
              {props.hideNextButton ? 'ثبت زمینه' : 'افزودن زمینه جدید'}
            </Button>
          </div>
          {props.hideNextButton ? null : (
            <div className="flex justify-between">
              <Button
                appearance="default"
                className="ml-auto mt-8"
                type="button"
                onClick={() => {
                  navigate(ROUTES.roles.reqSeeker.skillBackground);
                }}
                loading={loading}>
                مرحله قبل
              </Button>
              <Button
                appearance="primary"
                className="mr-auto mt-8"
                type="button"
                onClick={() => handleSubmit(true, false)}
                loading={loading}>
                ثبت و مرحله بعد
              </Button>
            </div>
          )}
        </>
      )}
    </Form>
  );
};
