/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, FlexboxGrid, Button, Input } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CustomLoader } from './../../../components/basic/loader';
import { handleGetCorporations, handleGetUserProfilePictureByUsername } from '../../../api-handler';
import { Location, User } from 'react-iconly';
import { base64ToJson } from '../../../utils/helpers';
import { UPLOAD_FILE_URL } from '../../../core';

export const CorporationsList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [pics, setPics] = useState<any>({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetCorporations();

      if (res.ok) {
        setData(res.data);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      // for (let i = 0; i < data.length; i++) {
      data.forEach(async (item) => {
        const res = await handleGetUserProfilePictureByUsername(item.Username);
        if (res.ok) {
          setPics((pre: any) => {
            return { ...pre, [item.Username]: res.data };
          });
        }
      });
      // }
      console.log('asdadadasdasdas');
    })();
  }, [data]);

  useEffect(() => {
    setFilteredData(
      data.filter((item: any) => {
        return (
          item.FullName.toLowerCase().search(search.trim().toLowerCase()) >= 0 &&
          item.City.toLowerCase().search(city.trim().toLowerCase()) >= 0 &&
          item.State.toLowerCase().search(state.trim().toLowerCase()) >= 0
        );
      })
    );
  }, [search, city, state, data]);

  return (
    <>
      <h4 className="text-h4 mb-4">{'لیست کسب و کار ها'}</h4>
      <FlexboxGrid>
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            <FlexboxGrid.Item
              as={Col}
              colspan={24}
              sm={24}
              md={24}
              lg={6}
              className="max-w-[100%] mb-3">
              <label>
                نام کسب و کار
                <Input
                  type="text"
                  placeholder="جستجو نام کسب و کار"
                  className="mt-1 mb-4 !w-full"
                  onChange={setSearch}
                  value={search}
                />
              </label>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              as={Col}
              colspan={24}
              sm={24}
              md={24}
              lg={6}
              className="max-w-[100%] mb-3">
              <label>
                استان
                <Input
                  type="text"
                  placeholder="استان"
                  className="mt-1 mb-4 !w-full"
                  onChange={setState}
                  value={state}
                />
              </label>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              as={Col}
              colspan={24}
              sm={24}
              md={24}
              lg={6}
              className="max-w-[100%] mb-3">
              <label>
                شهر
                <Input
                  type="text"
                  placeholder="شهر"
                  className="mt-1 mb-4 !w-full"
                  onChange={setCity}
                  value={city}
                />
              </label>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              as={Col}
              colspan={24}
              sm={24}
              md={24}
              lg={6}
              className="max-w-[100%] mb-3"></FlexboxGrid.Item>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {filteredData.map((item: any) => {
              return (
                <FlexboxGrid.Item
                  key={item.ID.toString()}
                  as={Col}
                  colspan={24}
                  sm={12}
                  md={8}
                  lg={6}
                  className="max-w-[100%] mb-3">
                  <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 w-full">
                    {pics[item.Username]?.Status === 'Accepted' ? (
                      <div
                        className="flex items-center mt-2 w-[50%] mx-auto rounded-full justify-center"
                        style={{ aspectRatio: 1 }}>
                        <img
                          src={`${UPLOAD_FILE_URL}?${base64ToJson(pics[item.Username]?.PictureLink)[0]?.url}&Attachment=False`}
                          alt=""
                          className="w-full"
                        />
                      </div>
                    ) : (
                      <div
                        className="flex items-center mt-2 bg-[--rs-primary-50] w-[50%] mx-auto rounded-full"
                        style={{ aspectRatio: 1 }}></div>
                    )}
                    <div className="flex">
                      <div className="mr-3 mt-5">
                        <div className="flex items-center mb-1">
                          <div className="p-2 rounded-full bg-[--rs-primary-50]  text-[--rs-primary-500] ml-2">
                            <User set="bulk" />
                          </div>
                          <p className="text-b1 mb-0 text-[--rs-primary-400]">
                            <strong>نام کسب و کار:</strong> {item.FullName}
                          </p>
                        </div>
                        <div className="flex items-center mb-1">
                          <div className="p-2 rounded-full bg-[--rs-primary-50]  text-[--rs-primary-500] ml-2">
                            <Location set="bulk" />
                          </div>
                          <p className="text-b1 mb-0 text-[--rs-primary-400]">
                            <strong>استان و شهر:</strong> {item.State}
                            {' - '}
                            {item.City}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center mt-6">
                      <Button
                        appearance="primary"
                        className="mx-auto min-w-[50%] mb-2 inline-block"
                        type="button"
                        onClick={() => navigate(`/corporations/${item.Username}`)}>
                        مشاهده اطلاعات
                      </Button>
                    </div>
                  </div>
                </FlexboxGrid.Item>
              );
            })}
            {filteredData.length == 0 ? (
              <div className="text-center text-h4 h-[30vh] flex justify-center items-center w-full">
                کسب و کاری یافت نشد!
              </div>
            ) : null}
          </>
        )}
      </FlexboxGrid>
    </>
  );
};
