import { getWorkshops, getWorkshop, getUserBoughtEvents, getWorkshopBoughts } from '../core';
import { showToast } from '../utils/toast';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleGetWorkshop = async (id: any) => {
  try {
    const { data, status } = await getWorkshop(id);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetWorkshops = async () => {
  try {
    const { data, status } = await getWorkshops();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: [], ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetUserBoughtEvents = async () => {
  try {
    const { data, status } = await getUserBoughtEvents();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: [], ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetWorkshopBoughts = async (id: string | undefined) => {
  try {
    const { data, status } = await getWorkshopBoughts(id);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: [], ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
