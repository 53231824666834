import {
  PostInvestmentGoodModel,
  PostRequestInvestmentDataModel,
  PostInvestmentServiceModel
} from '../models';
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const postInvestmentPersonal = (payload: PostRequestInvestmentDataModel) =>
  appNetworkManager.post(`/profitableinvestmentform`, {
    ...payload,
    Username: getUsername()
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInvestmentPersonal = (username: string | null | undefined, formID: any) => {
  return appNetworkManager.get(
    `/profitableinvestmentform?Username=${username ?? getUsername()}&ID=${formID}`
  );
};

// good
export const postInvestmentGood = (payload: PostInvestmentGoodModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formgoods`, {
    ...rest,
    Username: getUsername(),
    Form: 'investment'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInvestmentGood = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formgoods?Username=${username ?? getUsername()}&Form=investment&FormID=${formID}`
  );
};
export const deleteInvestmentGood = (id: number | string) => {
  return appNetworkManager.delete(`/formgoods?ID=${id}`);
};

// service
export const postInvestmentService = (payload: PostInvestmentServiceModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formservices`, {
    ...rest,
    Username: getUsername(),
    Form: 'investment'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInvestmentService = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formservices?Username=${username ?? getUsername()}&Form=investment&FormID=${formID}`
  );
};
export const deleteInvestmentService = (id: number | string) => {
  return appNetworkManager.delete(`/formservices?ID=${id}`);
};

// register request
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const postInvestmentRegisterRequest = (FormID: any) => {
  return appNetworkManager.post(`/profitableinvestmentregreq`, {
    Username: getUsername(),
    Status: 'Pending',
    FormID
  });
};
export const putInvestmentRegisterRequest = (payload: { Status: String }) => {
  return appNetworkManager.put(`/profitableinvestmentregreq`, {
    ...payload,
    Username: getUsername()
  });
};

export const getInvestmentRegisterRequest = () => {
  return appNetworkManager.get(`/profitableinvestmentregreq?Username=${getUsername()}`);
};
export const getInvestment = () => {
  return appNetworkManager.get(`/`);
};
// export const postInvestmentBuycv = (seekerUsername: string) => {
//   return appNetworkManager.post(`/buycv`, {
//     EmployerUsername: getUsername(),
//     JobSeekerUsername: seekerUsername
//   });
// };
// export const getInvestmentBuycv = () => {
//   return appNetworkManager.get(`/buycv?EmployerUsername=${getUsername()}`);
// };
