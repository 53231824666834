/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteInqueryGood,
  deleteInqueryService,
  getInqueryGood,
  getInqueryPersonal,
  getInqueryRegisterRequest,
  getInquery,
  getInqueryService,
  postInqueryGood,
  postInqueryPersonal,
  postInqueryRegisterRequest,
  postInqueryService,
  putInqueryRegisterRequest
} from '../core';
import {
  PostInqueryGoodModel,
  PostRequestInqueryDataModel,
  PostInqueryServiceModel
} from '../models';
import { showToast } from '../utils/toast';
import { handleUpdateAny } from './global';

export const handlePostInqueryPersonal = async (payload: PostRequestInqueryDataModel) => {
  try {
    const { data, status } = await postInqueryPersonal(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetInqueryPersonal = async (
  username: string | null | undefined,
  formId: string | null | undefined
) => {
  try {
    const { data, status } = await getInqueryPersonal(username, formId);
    // return { ok: true, payload };
    if (status === 200) {
      return {
        data: {
          ...data.Data
        },
        ok: true
      };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutInqueryPersonal = (
  payload: PostRequestInqueryDataModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'ServiceInquiryForm');
};

// good
export const handlePostInqueryGood = async (payload: PostInqueryGoodModel) => {
  try {
    const { data, status } = await postInqueryGood(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInqueryGood = async (username: string | null | undefined, formID: any) => {
  try {
    const { data, status } = await getInqueryGood(username, formID);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutInqueryGood = async (
  payload: PostInqueryGoodModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'FormGoodsTable');
};
export const handleDeleteInqueryGood = async (id: number | string) => {
  try {
    const { data, status } = await deleteInqueryGood(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// service
export const handlePostInqueryService = async (payload: PostInqueryServiceModel) => {
  try {
    const { data, status } = await postInqueryService(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInqueryService = async (username: string | null | undefined, formID: any) => {
  try {
    const { data, status } = await getInqueryService(username, formID);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutInqueryService = async (
  payload: PostInqueryServiceModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'FormServicesTable');
};
export const handleDeleteInqueryService = async (id: number | string) => {
  try {
    const { data, status } = await deleteInqueryService(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// // request
// eslint-disable-next-line @typescript-eslint/naming-convention
export const handlePostInqueryRegisterRequest = async (FormID: any) => {
  try {
    const { data, status } = await postInqueryRegisterRequest(FormID);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutInqueryRegisterRequest = async (payload: { Status: string; FormID: any }) => {
  try {
    const { data, status } = await putInqueryRegisterRequest(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInqueryRegisterRequest = async () => {
  try {
    const { data, status } = await getInqueryRegisterRequest();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInquerys = async () => {
  try {
    const { data, status } = await getInquery();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// export const handlePostInqueryBuycv = async (seekerUsername: string) => {
//   try {
//     const { data, status } = await postInqueryBuycv(seekerUsername);
//     // return { ok: true, payload };
//     if (status === 200) {
//       return { data: data.Data, ok: true };
//     } else {
//       showToast(data.Message, 'error');
//     }
//     console.log('NOT 200', data);
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     if (e.response?.status === 404) {
//       console.log('not submitted');
//       return { data: {}, ok: false };
//     }
//     console.log(e.response?.data.Message?.FA);
//     showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
//   }
//   return { ok: false };
// };
// export const handleGetInqueryBuycv = async () => {
//   try {
//     const { data, status } = await getInqueryBuycv();
//     // return { ok: true, payload };
//     if (status === 200) {
//       return { data: data.Data, ok: true };
//     } else {
//       showToast(data.Message, 'error');
//     }
//     console.log('NOT 200', data);
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     if (e.response?.status === 404) {
//       console.log('not submitted');
//       return { data: [], ok: false };
//     }
//     console.log(e.response?.data.Message?.FA);
//     showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
//   }
//   return { ok: false };
// };
