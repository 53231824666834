import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export interface RequestRecruitmentDataFormValueModel {
  ID?: String;
  Username?: String;
  CompanyName: String;
  CEOName: String;
  RegistrationNumber: String;
  NationalIDNumber: String;
  CEONationalID: String;
  CompanyAddress: String;
  Industry: String;
  JobTitle: String;
  NumberOfPositions: String;
  Gender: String;
  InsuranceDetails: String;
  Salary: String;
  TransportServiceDetails: String;
  JobDescription: String;
  EmploymentType: String;
  WorkHours: String;
  ExperienceRequired: String;
  RequiredSkills: String;
}

export interface PostRequestRecruitmentDataModel extends RequestRecruitmentDataFormValueModel {
  FormID: String;
}

export const RequestRecruitmentDataFormSchema = Schema.Model({
  CompanyName: StringType(''),
  CEOName: StringType('').isRequired('الزامی است!'),
  RegistrationNumber: StringType('').isRequired('الزامی است!'),
  NationalIDNumber: StringType('').isRequired('الزامی است!'),
  CEONationalID: StringType('').isRequired('الزامی است!'),
  CompanyAddress: StringType('').isRequired('الزامی است!'),
  JobTitle: StringType('').isRequired('الزامی است!'),
  Industry: StringType('').isRequired('الزامی است!'),
  NumberOfPositions: StringType('').isRequired('الزامی است!'),
  Gender: StringType('').isRequired('الزامی است!'),
  InsuranceDetails: StringType('').isRequired('الزامی است!'),
  Salary: StringType('').isRequired('الزامی است!'),
  TransportServiceDetails: StringType(''),
  JobDescription: StringType('').isRequired('الزامی است!'),
  EmploymentType: StringType('').isRequired('الزامی است!'),
  WorkHours: StringType('').isRequired('الزامی است!'),
  ExperienceRequired: StringType('').isRequired('الزامی است!'),
  RequiredSkills: StringType('').isRequired('الزامی است!')
});

// good
export interface RecruitmentGoodFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
  Produced: Boolean;
  PICs: string;
}
export const defaultRecruitmentGoodFormValue = {
  Name: '',
  Description: '',
  Produced: '',
  PICs: '',
  File: ''
};

export const defaultRecruitmentGoodSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
  // PICs: StringType('').isRequired('الزامی است!')
});

export interface PostRecruitmentGoodModel extends RecruitmentGoodFormValueModel {
  // StudyNow: boolean;
  // Country: String;
  // Start_Date: String;
  // End_Date?: String;
}

// service
export interface RecruitmentServiceFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
}
export const defaultRecruitmentServiceFormValue = {
  Name: '',
  Description: ''
};

export const defaultRecruitmentServiceSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
});

export interface PostRecruitmentServiceModel extends RecruitmentServiceFormValueModel {}
