import { ValueType } from 'rsuite/esm/Checkbox';
import { appNetworkManager, authNetworkManager } from './network-manager';
import { AdminConsultantRegisterModel } from '../models/admin';

export const putAcceptConsultant = (accept: ValueType, username: string, whyReject: string) =>
  appNetworkManager.put(`/consregreq`, {
    Status: accept,
    Username: username,
    Description: whyReject
  });
export const deleteRejeCtonsultant = (username: string, whyReject: string) => {
  console.log(whyReject);
  return appNetworkManager.delete(`/rejectconsultant?Username=${username}`);
};
export const getConsultantsRequests = () => appNetworkManager.get(`/consregreqs`);
// todo: backend
export const getConsultantRequest = (username: string) =>
  appNetworkManager.get(`/consregreqone?Username=${username}`);

export const getAdminConsultantsRegisters = () => appNetworkManager.get(`/consultants`);

export const getAdminConsultantRegister = (username: string) =>
  appNetworkManager.get(`/consultant?Username=${username}`);

export const postAdminConsultantRegister = (payload: AdminConsultantRegisterModel) =>
  appNetworkManager.post(`/consultant`, payload);

export const putAdminConsultantRegister = (payload: AdminConsultantRegisterModel) => {
  // const { Username, ...rest } = payload;
  return appNetworkManager.put(`/consultant`, payload);
};

export const postAdminLoginWithPassword = (username: string, password: string) =>
  authNetworkManager.post(`/admin/loginwp`, { Username: username, Password: password });

// seeker
export const putAcceptSeeker = (accept: ValueType, username: string, whyReject: string) =>
  appNetworkManager.put(`/jobseekerregreq`, {
    Status: accept,
    Username: username,
    Description: whyReject
  });
export const deleteRejectSeeker = (username: string, whyReject: string) => {
  console.log(whyReject);
  return appNetworkManager.delete(`/rejectjobseeker?Username=${username}`);
};
export const getSeekersRequests = () => appNetworkManager.get(`/jobseekerregreqs`);
// todo: backend
export const getSeekerRequest = (username: string) =>
  appNetworkManager.get(`/jobseekerregreq?Username=${username}`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAdminSeekersRegisters = (payload: any) => {
  if (payload.LayerOne && !payload.LayerTwo) {
    return appNetworkManager.get(encodeURI(`/jobseekerdesirefield2?LayerOne=${payload.LayerOne}`));
  } else if (payload.LayerOne && payload.LayerTwo && !payload.LayerThree) {
    return appNetworkManager.get(
      encodeURI(`/jobseekerdesirefield2?LayerOne=${payload.LayerOne}&LayerTwo=${payload.LayerTwo}`)
    );
  } else if (payload.LayerOne && payload.LayerTwo && payload.LayerThree) {
    return appNetworkManager.get(
      encodeURI(
        `/jobseekerdesirefield2?LayerOne=${payload.LayerOne}&LayerTwo=${payload.LayerTwo}&LayerThree=${payload.LayerThree}`
      )
    );
  } else {
    return appNetworkManager.get(encodeURI(`/jobseekerdesirefield2`));
  }
};

// Corporation
export const putAcceptCorporation = (accept: ValueType, username: string, whyReject: string) =>
  appNetworkManager.put(`/corporationregreq`, {
    Status: accept,
    Username: username,
    Description: whyReject
  });
export const deleteRejectCorporation = (username: string, whyReject: string) => {
  console.log(whyReject);
  return appNetworkManager.delete(`/rejectcorporation?Username=${username}`);
};
export const getCorporationsRequests = () => appNetworkManager.get(`/corporationregreqs`);
// todo: backend
export const getCorporationRequest = (username: string) =>
  appNetworkManager.get(`/corporationregreq?Username=${username}`);
export const getAdminCorporationsRegisters = () => appNetworkManager.get(`/corporations`);

// founders
export const getFoundersRequests = () => appNetworkManager.get(`/investmentformregreqs`);
// todo: backend
export const getFounderRequest = (username: string) =>
  appNetworkManager.get(`/investmentformregreq?Username=${username}`);
export const getAdminFoundersRegisters = () => appNetworkManager.get(`/`);

// orgenizational projects
export const getOrganizationalProjectsRequests = () =>
  appNetworkManager.get(`/orgprojectformregreqs`);
// todo: backend
export const getOrganizationalProjectsRequest = (username: string) =>
  appNetworkManager.get(`/orgprojectformregreq?Username=${username}`);
export const getAdminOrganizationalProjectsRegisters = () => appNetworkManager.get(`/`);

// orgenizational trainings
export const getOrganizationalTrainingsRequests = () =>
  appNetworkManager.get(`/organizationaltrainingformregreqs`);
// todo: backend
export const getOrganizationalTrainingsRequest = (username: string) =>
  appNetworkManager.get(`/organizationaltrainingformregreq?Username=${username}`);
export const getAdminOrganizationalTrainingsRegisters = () => appNetworkManager.get(`/`);
// ecruitment
export const getRecruitmentRequests = () => appNetworkManager.get(`/specialrecruitmentregreqs`);
// todo: backend
export const getRecruitmentRequest = (username: string) =>
  appNetworkManager.get(`/specialrecruitmentregreq?Username=${username}`);
export const getAdminRecruitmentRegisters = () => appNetworkManager.get(`/`);
// Investmen
export const getInvestmentRequests = () => appNetworkManager.get(`/profitableinvestmentregreqs`);
// todo: backend
export const getInvestmentRequest = (username: string) =>
  appNetworkManager.get(`/profitableinvestmentregreq?Username=${username}`);
export const getAdminInvestmentRegisters = () => appNetworkManager.get(`/`);
// marketing
export const getMarketingRequests = () => appNetworkManager.get(`/marketingsalesformregreqs`);
// todo: backend
export const getMarketingRequest = (username: string) =>
  appNetworkManager.get(`/marketingsalesformregreq?Username=${username}`);
export const getAdminMarketingRegisters = () => appNetworkManager.get(`/`);
// idea
export const getIdeaRequests = () => appNetworkManager.get(`/ideatransformationformregreqs`);
// todo: backend
export const getIdeaRequest = (username: string) =>
  appNetworkManager.get(`/ideatransformationformregreq?Username=${username}`);
export const getAdminIdeaRegisters = () => appNetworkManager.get(`/`);
// inquery
export const getInqueryRequests = () => appNetworkManager.get(`/serviceinquiryformregreqs`);
// todo: backend
export const getInqueryRequest = (username: string) =>
  appNetworkManager.get(`/serviceinquiryformregreq?Username=${username}`);
export const getAdminInqueryRegisters = () => appNetworkManager.get(`/`);

// jobseekers
export const postJobseeker = (payload: string) => appNetworkManager.post(`/jobseekers`, payload);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postCorporation = (payload: string) => appNetworkManager.post(`/corporation`, payload);

export const getPictures = () => appNetworkManager.get(`/pictures`);

export const getAllComments = () => appNetworkManager.get(`/jobseekersreports`);

export const putChangeCommentStatus = (consultId: string | undefined, isAccepted: boolean) =>
  appNetworkManager.put(`/consrepuser`, {
    ConsultID: consultId,
    Answer: isAccepted
  });
