import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export interface RequestFounderDataFormValueModel {
  ID?: String;
  Username?: String;
  CompanyName?: String;
  CEOName: String;
  CEONationalID: String;
  InvestmentType: String;
  InvestmentAmount: String;
  PlansAndIdeas: String;
  EmployeeDetails: String;
  BusinessDetails: String;
  CustomerDetails: String;
  GeographicDistribution: String;
  CompanyWebsite: String;
  CompanyAddress: String;
  RequestReason: String;
}

export interface PostRequestFounderDataModel extends RequestFounderDataFormValueModel {}

export const RequestFounderDataFormSchema = Schema.Model({
  CompanyName: StringType(''),
  CEOName: StringType('').isRequired('الزامی است!'),
  CEONationalID: StringType('').isRequired('الزامی است!'),
  InvestmentType: StringType('').isRequired('الزامی است!'),
  InvestmentAmount: StringType('').isRequired('الزامی است!'),
  PlansAndIdeas: StringType('').isRequired('الزامی است!'),
  EmployeeDetails: StringType(''),
  BusinessDetails: StringType('').isRequired('الزامی است!'),
  CustomerDetails: StringType('').isRequired('الزامی است!'),
  GeographicDistribution: StringType('').isRequired('الزامی است!'),
  CompanyWebsite: StringType(''),
  CompanyAddress: StringType('').isRequired('الزامی است!'),
  RequestReason: StringType('').isRequired('الزامی است!')
});

// good
export interface FounderGoodFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
  Produced: Boolean;
  PICs: string;
}
export const defaultFounderGoodFormValue = {
  Name: '',
  Description: '',
  Produced: '',
  PICs: '',
  File: ''
};

export const defaultFounderGoodSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
  // PICs: StringType('').isRequired('الزامی است!')
});

export interface PostFounderGoodModel extends FounderGoodFormValueModel {
  FormID: String;
  // StudyNow: boolean;
  // Country: String;
  // Start_Date: String;
  // End_Date?: String;
}

// service
export interface FounderServiceFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
}
export const defaultFounderServiceFormValue = {
  Name: '',
  Description: ''
};

export const defaultFounderServiceSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
});

export interface PostFounderServiceModel extends FounderServiceFormValueModel {}
