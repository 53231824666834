import { Header } from './../../components/headers/header';
import { Col, FlexboxGrid } from 'rsuite';
import { LayoutProps } from './props';
import { Sidebar } from './../../components/sidebar/sidebar';

export const Layout = (props: LayoutProps) => (
  <>
    <Header />
    <FlexboxGrid className="w-full mt-12 mb-5">
      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={6}>
        <Sidebar page={props.sidebar.page} step={props.sidebar.step} />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={18}>
        <div className="bg-[--rs-white] p-8 rounded-md border-[1px] border-[--rs-primary-300] ml-2 mr-2 md:mr-6 md:ml-16">
          {props.children}
        </div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  </>
);
