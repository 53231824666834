import {
  getUser,
  getUserProfile,
  getUserRoles,
  postLoginWithPassword,
  postSignupUser,
  postSignupUser2
} from '../core';
import { showToast } from '../utils/toast';

export const handleGetUserProfile = async (username: string) => {
  try {
    const { data, status } = await getUserProfile(username);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetUserRoles = async (username: string) => {
  try {
    const { data, status } = await getUserRoles(username);
    if (status === 200) {
      console.log(data.Data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { data: data.Data.map((item: any) => item.Role), ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleLoginWithPassword = async (username: string, password: string) => {
  try {
    const { data, status } = await postLoginWithPassword(username, password);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleSignupUser = async (payload: any) => {
  try {
    const { data, status } = await postSignupUser(payload);
    const res1 = await postSignupUser2(payload);
    if (status === 200 && res1.status === 200) {
      const res = await handleLoginWithPassword(payload.Username, payload.Password);
      if (res.ok) {
        return res;
      }
      return { data: data.Data, ok: true, notLoggedIn: true };
    } else {
      showToast(data.Data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleIsUserExists = async (username: string) => {
  try {
    const { data, status } = await getUser(username);
    if (status === 200) {
      return { data: data.Data ? true : false, ok: true };
    } else {
      showToast(data.Data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      return { data: false, ok: true };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
