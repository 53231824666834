/* eslint-disable @typescript-eslint/no-explicit-any */
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import WorkshopImage from '../../../assets/images/workshop-1.png';

SwiperCore.use([Autoplay, Pagination]);
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Col, FlexboxGrid } from 'rsuite';
// import { UPLOAD_FILE_URL } from '../../../core';
// import { base64ToJson } from '../../../utils/helpers';
import { useEffect, useState, useCallback } from 'react';
// import { handleGetCorporationGood } from '../../../api-handler';
import { CustomLoader } from '../../../components/basic/loader';
import { RegisterEvent } from './register-event';
// import { FormatDateTime } from '../../../utils/helpers';
// import { faIR } from 'date-fns-jalali/locale';
import { useParams } from 'react-router-dom';
import { handleGetWorkshop } from '../../../api-handler/workshop';
import { AdminWorkshopUsersList } from '../../admin/workshop-users/workshop-users-list';
import { useUser } from '../../../contexts/user';

export const Event = () => {
  const { isUserAdmin } = useUser();
  // const navigate = useNavigate();
  const { EventID } = useParams();

  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const handleGetInitialData = useCallback(async (eventID: any) => {
    setLoading(true);
    const res3 = await handleGetWorkshop(eventID); //todo: change the api
    if (res3.ok) {
      setSelectedEvent(res3.data);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    handleGetInitialData(EventID);
  }, [EventID]);
  if (loading) return <CustomLoader />;
  return (
    <FlexboxGrid>
      <FlexboxGrid.Item
        as={Col}
        colspan={24}
        sm={24}
        md={24}
        className="max-w-[100%] mt-[-10px] !px-0">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true
          }}
          modules={[Autoplay]}>
          <SwiperSlide className=" p-0 overflow-hidden relative bg-[--rs-primary-50]">
            <div
              className="w-[70%] max-w-[500px] mx-auto  flex items-center justify-center aspect-[.7] sm:aspect-[.7] md:aspect-[.7] mt-8 border-[1px] border-[var(--rs-primary-300)] rounded-md"
              style={{ overflow: 'hidden' }}>
              <img src={WorkshopImage} className="min-w-[100%] min-h-[100%]" alt="" />
              <div className="absolute top-[20%] md:top-[40%] right-[5%] w-full">
                {/* <h2
                      className="text-[24px] md:text-[40px] px-4 md:px-6 py-2 md:py-4 bg-[#fff8] text-[#000] w-fit max-w-[90%] rounded-md"
                      style={{ lineHeight: '100%' }}>
                      {event.Title}
                    </h2>
                    <p className="text-[12px] md:text-[16px] px-4 md:px-6 py-2 md:py-4 bg-[#fff8] text-[#000] w-fit max-w-[90%] rounded-md mt-2">
                      {event.Subtitle}
                    </p> */}

                {/* <div className="flex mt-2 flex-row items-center">
                      {user.isLoggedIn ? (
                        <Button appearance="primary" type="button" className="block">
                          <a href="#register" className="text-[#fff]">
                            ثبت‌نام در رویداد
                          </a>
                        </Button>
                      ) : (
                        <Button
                          appearance="primary"
                          type="button"
                          className="block"
                          onClick={() => {
                            navigate(`/auth/login?redirect=/events/${event.ID}`);
                          }}>
                          برای شرکت در رویداد ابتدا وارد شوید
                        </Button>
                      )}
                      <div className="text-[12px] font-bold px-4  py-2.5 mr-3 bg-[var(--rs-primary-100)] text-[var(--rs-primary-800)] rounded-md">
                        {FormatDateTime(event.DateTime, faIR)}
                      </div> */}
                {/* </div> */}
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {selectedEvent ? <RegisterEvent event={selectedEvent} /> : null}
        <div className="max-w-[1200px] w-[90%] mx-auto ">
          {selectedEvent && isUserAdmin() ? <AdminWorkshopUsersList /> : null}
        </div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};
