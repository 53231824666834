import {
  PostConsultantConsultationBackgroundModel,
  PostConsultantEducationBackgroundModel,
  PostConsultantPersonalModel,
  PostConsultantSocialModel,
  PostConsultantWorkBackgroundModel
} from '../models';
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const postConsultantPersonal = (payload: PostConsultantPersonalModel) =>
  appNetworkManager.post(`/conspersonal`, { ...payload, Username: getUsername() });

export const getConsultantPersonal = (username: string | null | undefined) => {
  return appNetworkManager.get(`/conspersonal?Username=${username ?? getUsername()}`);
};
// work
export const postConsultantWorkBackground = (payload: PostConsultantWorkBackgroundModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/conswork`, { ...rest, Username: getUsername() });
};

export const getConsultantWorkBackground = (username: string | null | undefined) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(`/conswork?Username=${username ?? getUsername()}`);
};
export const deleteConsultantWorkBackground = (id: number | string) => {
  return appNetworkManager.delete(`/conswork?ID=${id}`);
};

// education
export const postConsultantEducationBackground = (
  payload: PostConsultantEducationBackgroundModel
) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/consedu`, { ...rest, Username: getUsername() });
};

export const getConsultantEducationBackground = (username: string | null | undefined) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(`/consedu?Username=${username ?? getUsername()}`);
};
export const deleteConsultantEducationBackground = (id: number | string) => {
  return appNetworkManager.delete(`/consedu?ID=${id}`);
};

// consultation
export const postConsultantConsultationBackground = (
  payload: PostConsultantConsultationBackgroundModel
) => {
  return appNetworkManager.post(`/consrecord`, { ...payload, Username: getUsername() });
};

export const getConsultantConsultationBackground = (username: string | null | undefined) => {
  return appNetworkManager.get(`/consrecord?Username=${username ?? getUsername()}`);
};
export const deleteConsultantConsultationBackground = (id: number | string) => {
  return appNetworkManager.delete(`/consrecord?ID=${id}`);
};

// social
export const postConsultantSocial = (payload: PostConsultantSocialModel) => {
  return appNetworkManager.post(`/conssocial`, { ...payload, Username: getUsername() });
};

export const getConsultantSocial = (username: string | null | undefined) => {
  return appNetworkManager.get(`/conssocial?Username=${username ?? getUsername()}`);
};

// register request
export const postConsultantRegisterRequest = (payload: { Group: string }) => {
  return appNetworkManager.post(`/consregreq`, { ...payload, Username: getUsername() });
};
export const putConsultantRegisterRequest = (payload: { Group: string; Status: String }) => {
  return appNetworkManager.put(`/consregreq`, { ...payload, Username: getUsername() });
};

export const getConsultantRegisterRequest = () => {
  return appNetworkManager.get(`/consregreq?Username=${getUsername()}&Group=Consultants`);
};

export const getConsultantComments = (username: string) => {
  return appNetworkManager.get(`/conscomment?ConsultantUsername=${username}`);
};
