import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export interface RequestOrganizationalProjectsDataFormValueModel {
  ID?: String;
  Username?: String;
  CompanyName: String;
  CEOName: String;
  CEONationalID: String;
  ProjectType: String;
  StaffCount: String;
  BusinessDetails: String;
  CustomerDetails: String;
  GeographicDistribution: String;
  CompanyWebsite: String;
  CompanyAddress: String;
  RequestReason: String;
}

export interface PostRequestOrganizationalProjectsDataModel
  extends RequestOrganizationalProjectsDataFormValueModel {
  FormID: String;
}

export const RequestOrganizationalProjectsDataFormSchema = Schema.Model({
  CompanyName: StringType(''),
  CEOName: StringType('').isRequired('الزامی است!'),
  CEONationalID: StringType('').isRequired('الزامی است!'),
  ProjectType: StringType('').isRequired('الزامی است!'),
  StaffCount: StringType('').isRequired('الزامی است!'),
  BusinessDetails: StringType('').isRequired('الزامی است!'),
  CustomerDetails: StringType('').isRequired('الزامی است!'),
  GeographicDistribution: StringType('').isRequired('الزامی است!'),
  CompanyWebsite: StringType(''),
  CompanyAddress: StringType('').isRequired('الزامی است!'),
  RequestReason: StringType('').isRequired('الزامی است!')
});

// good
export interface OrganizationalProjectsGoodFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
  Produced: Boolean;
  PICs: string;
}
export const defaultOrganizationalProjectsGoodFormValue = {
  Name: '',
  Description: '',
  Produced: '',
  PICs: '',
  File: ''
};

export const defaultOrganizationalProjectsGoodSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
  // PICs: StringType('').isRequired('الزامی است!')
});

export interface PostOrganizationalProjectsGoodModel
  extends OrganizationalProjectsGoodFormValueModel {
  // StudyNow: boolean;
  // Country: String;
  // Start_Date: String;
  // End_Date?: String;
}

// service
export interface OrganizationalProjectsServiceFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
}
export const defaultOrganizationalProjectsServiceFormValue = {
  Name: '',
  Description: ''
};

export const defaultOrganizationalProjectsServiceSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
});

export interface PostOrganizationalProjectsServiceModel
  extends OrganizationalProjectsServiceFormValueModel {}
