/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteMarketingGood,
  deleteMarketingService,
  getMarketingGood,
  getMarketingPersonal,
  getMarketingRegisterRequest,
  getMarketing,
  getMarketingService,
  postMarketingGood,
  postMarketingPersonal,
  postMarketingRegisterRequest,
  postMarketingService,
  putMarketingRegisterRequest
} from '../core';
import {
  PostMarketingGoodModel,
  PostRequestMarketingDataModel,
  PostMarketingServiceModel
} from '../models';
import { showToast } from '../utils/toast';
import { handleUpdateAny } from './global';

export const handlePostMarketingPersonal = async (payload: PostRequestMarketingDataModel) => {
  try {
    const { data, status } = await postMarketingPersonal(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetMarketingPersonal = async (
  username: string | null | undefined,
  formId: string | null | undefined
) => {
  try {
    const { data, status } = await getMarketingPersonal(username, formId);
    // return { ok: true, payload };
    if (status === 200) {
      return {
        data: {
          ...data.Data
        },
        ok: true
      };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutMarketingPersonal = (
  payload: PostRequestMarketingDataModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'MarketingSalesForm');
};

// good
export const handlePostMarketingGood = async (payload: PostMarketingGoodModel) => {
  try {
    const { data, status } = await postMarketingGood(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetMarketingGood = async (username: string | null | undefined, formID: any) => {
  try {
    const { data, status } = await getMarketingGood(username, formID);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutMarketingGood = async (
  payload: PostMarketingGoodModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'FormGoodsTable');
};
export const handleDeleteMarketingGood = async (id: number | string) => {
  try {
    const { data, status } = await deleteMarketingGood(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// service
export const handlePostMarketingService = async (payload: PostMarketingServiceModel) => {
  try {
    const { data, status } = await postMarketingService(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetMarketingService = async (
  username: string | null | undefined,
  formID: any
) => {
  try {
    const { data, status } = await getMarketingService(username, formID);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutMarketingService = async (
  payload: PostMarketingServiceModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'FormServicesTable');
};
export const handleDeleteMarketingService = async (id: number | string) => {
  try {
    const { data, status } = await deleteMarketingService(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// // request
// eslint-disable-next-line @typescript-eslint/naming-convention
export const handlePostMarketingRegisterRequest = async (FormID: any) => {
  try {
    const { data, status } = await postMarketingRegisterRequest(FormID);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutMarketingRegisterRequest = async (payload: {
  Status: string;
  FormID: any;
}) => {
  try {
    const { data, status } = await putMarketingRegisterRequest(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetMarketingRegisterRequest = async () => {
  try {
    const { data, status } = await getMarketingRegisterRequest();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetMarketings = async () => {
  try {
    const { data, status } = await getMarketing();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// export const handlePostMarketingBuycv = async (seekerUsername: string) => {
//   try {
//     const { data, status } = await postMarketingBuycv(seekerUsername);
//     // return { ok: true, payload };
//     if (status === 200) {
//       return { data: data.Data, ok: true };
//     } else {
//       showToast(data.Message, 'error');
//     }
//     console.log('NOT 200', data);
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     if (e.response?.status === 404) {
//       console.log('not submitted');
//       return { data: {}, ok: false };
//     }
//     console.log(e.response?.data.Message?.FA);
//     showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
//   }
//   return { ok: false };
// };
// export const handleGetMarketingBuycv = async () => {
//   try {
//     const { data, status } = await getMarketingBuycv();
//     // return { ok: true, payload };
//     if (status === 200) {
//       return { data: data.Data, ok: true };
//     } else {
//       showToast(data.Message, 'error');
//     }
//     console.log('NOT 200', data);
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     if (e.response?.status === 404) {
//       console.log('not submitted');
//       return { data: [], ok: false };
//     }
//     console.log(e.response?.data.Message?.FA);
//     showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
//   }
//   return { ok: false };
// };
