import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export interface RequestInqueryDataFormValueModel {
  ID?: String;
  Username?: String;
  CompanyName: String;
  CEOName: String;
  CEONationalID: String;
  InquiryType: String;
  InquiryDescription: String;
  BusinessDetails: String;
  CompanyWebsite: String;
  CompanyAddress: String;
  RequestReason: String;
}

export interface PostRequestInqueryDataModel extends RequestInqueryDataFormValueModel {
  FormID: String;
}

export const RequestInqueryDataFormSchema = Schema.Model({
  CompanyName: StringType('').isRequired('الزامی است!'),
  CEOName: StringType('').isRequired('الزامی است!'),
  CEONationalID: StringType('').isRequired('الزامی است!'),
  InquiryType: StringType('').isRequired('الزامی است!'),
  InquiryDescription: StringType('').isRequired('الزامی است!'),
  BusinessDetails: StringType('').isRequired('الزامی است!'),
  CompanyWebsite: StringType(''),
  CompanyAddress: StringType('').isRequired('الزامی است!'),
  RequestReason: StringType('').isRequired('الزامی است!')
});

// good
export interface InqueryGoodFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
  Produced: Boolean;
  PICs: string;
}
export const defaultInqueryGoodFormValue = {
  Name: '',
  Description: '',
  Produced: '',
  PICs: '',
  File: ''
};

export const defaultInqueryGoodSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
  // PICs: StringType('').isRequired('الزامی است!')
});

export interface PostInqueryGoodModel extends InqueryGoodFormValueModel {
  // StudyNow: boolean;
  // Country: String;
  // Start_Date: String;
  // End_Date?: String;
}

// service
export interface InqueryServiceFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
}
export const defaultInqueryServiceFormValue = {
  Name: '',
  Description: ''
};

export const defaultInqueryServiceSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
});

export interface PostInqueryServiceModel extends InqueryServiceFormValueModel {}
