import { Button, Form, Input, Schema } from 'rsuite';
import TextField from '../../../../components/form/textfield';
import { useCallback, useRef, useState } from 'react';
// import { Link } from 'react-router-dom';
import { handleIsUserExists } from '../../../../api-handler';
import { phoneNumberToUsername } from '../../../../utils/helpers';

const { StringType } = Schema.Types;
const model = Schema.Model({
  Username: StringType('')
    .pattern(/^0[0-9]{10}$/, 'شماره تلفن 11 رقمی را با صفر و بدون کد کشور وارد کنید!')
    .isRequired('لطفا شماره تلفن همراه را وارد کنید.')
});

export const LoginPhone = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setStep: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  step: any;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }
    console.log('submitted', formValue);
    setLoading(true);
    const username = phoneNumberToUsername(formValue.Username);
    const res = await handleIsUserExists(username);
    if (res.ok) {
      if (res.data === true) {
        props.setStep({
          ...props.step,
          action: 'password',
          data: { Username: username }
        });
      } else {
        props.setStep({
          ...props.step,
          action: 'signup',
          data: { Username: username }
        });
      }
    }
    setLoading(false);
  }, [formValue, formError, props]);

  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={model}
      onSubmit={handleSubmit}
      checkTrigger="change"
      readOnly={loading}
      className="mt-8 text-dark">
      <TextField
        name="Username"
        label="شماره تلفن همراه"
        type="number"
        accepter={Input}
        dir="ltr"
        placeholder="09123456789"
        pattern="[0-9]"
      />
      {/* <Link className="text-b3 text-[--rs-primary-500] mt-1 block" to="/">
        رمز عبور را فراموش کردید؟
      </Link> */}
      <Button
        appearance="primary"
        type="submit"
        className="w-[50%] mx-auto !block mt-8"
        loading={loading}>
        ادامه
      </Button>
    </Form>
  );
};
