/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteOrganizationalTrainingsGood,
  deleteOrganizationalTrainingsService,
  getOrganizationalTrainingsGood,
  getOrganizationalTrainingsPersonal,
  getOrganizationalTrainingsRegisterRequest,
  getOrganizationalTrainings,
  getOrganizationalTrainingsService,
  postOrganizationalTrainingsGood,
  postOrganizationalTrainingsPersonal,
  postOrganizationalTrainingsRegisterRequest,
  postOrganizationalTrainingsService,
  putOrganizationalTrainingsRegisterRequest
} from '../core';
import {
  PostOrganizationalTrainingsGoodModel,
  PostRequestOrganizationalTrainingsDataModel,
  PostOrganizationalTrainingsServiceModel
} from '../models';
import { showToast } from '../utils/toast';
import { handleUpdateAny } from './global';

export const handlePostOrganizationalTrainingsPersonal = async (
  payload: PostRequestOrganizationalTrainingsDataModel
) => {
  try {
    const { data, status } = await postOrganizationalTrainingsPersonal(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetOrganizationalTrainingsPersonal = async (
  username: string | null | undefined,
  formID: any
) => {
  try {
    const { data, status } = await getOrganizationalTrainingsPersonal(username, formID);
    // return { ok: true, payload };
    if (status === 200) {
      return {
        data: {
          ...data.Data
        },
        ok: true
      };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutOrganizationalTrainingsPersonal = (
  payload: PostRequestOrganizationalTrainingsDataModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'OrganizationalTrainingForm');
};

// good
export const handlePostOrganizationalTrainingsGood = async (
  payload: PostOrganizationalTrainingsGoodModel
) => {
  try {
    const { data, status } = await postOrganizationalTrainingsGood(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetOrganizationalTrainingsGood = async (
  username: string | null | undefined,
  formID: any
) => {
  try {
    const { data, status } = await getOrganizationalTrainingsGood(username, formID);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutOrganizationalTrainingsGood = async (
  payload: PostOrganizationalTrainingsGoodModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'FormGoodsTable');
};
export const handleDeleteOrganizationalTrainingsGood = async (id: number | string) => {
  try {
    const { data, status } = await deleteOrganizationalTrainingsGood(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// service
export const handlePostOrganizationalTrainingsService = async (
  payload: PostOrganizationalTrainingsServiceModel
) => {
  try {
    const { data, status } = await postOrganizationalTrainingsService(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetOrganizationalTrainingsService = async (
  username: string | null | undefined,
  formID: any
) => {
  try {
    const { data, status } = await getOrganizationalTrainingsService(username, formID);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutOrganizationalTrainingsService = async (
  payload: PostOrganizationalTrainingsServiceModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'FormServicesTable');
};
export const handleDeleteOrganizationalTrainingsService = async (id: number | string) => {
  try {
    const { data, status } = await deleteOrganizationalTrainingsService(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// // request
// eslint-disable-next-line @typescript-eslint/naming-convention
export const handlePostOrganizationalTrainingsRegisterRequest = async (FormID: any) => {
  try {
    const { data, status } = await postOrganizationalTrainingsRegisterRequest(FormID);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutOrganizationalTrainingsRegisterRequest = async (payload: {
  Status: string;
  FormID: any;
}) => {
  try {
    const { data, status } = await putOrganizationalTrainingsRegisterRequest(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const handleGetOrganizationalTrainingsRegisterRequest = async (FormID: any) => {
  try {
    const { data, status } = await getOrganizationalTrainingsRegisterRequest(FormID);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetOrganizationalTrainingss = async () => {
  try {
    const { data, status } = await getOrganizationalTrainings();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// export const handlePostOrganizationalTrainingsBuycv = async (seekerUsername: string) => {
//   try {
//     const { data, status } = await postOrganizationalTrainingsBuycv(seekerUsername);
//     // return { ok: true, payload };
//     if (status === 200) {
//       return { data: data.Data, ok: true };
//     } else {
//       showToast(data.Message, 'error');
//     }
//     console.log('NOT 200', data);
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     if (e.response?.status === 404) {
//       console.log('not submitted');
//       return { data: {}, ok: false };
//     }
//     console.log(e.response?.data.Message?.FA);
//     showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
//   }
//   return { ok: false };
// };
// export const handleGetOrganizationalTrainingsBuycv = async () => {
//   try {
//     const { data, status } = await getOrganizationalTrainingsBuycv();
//     // return { ok: true, payload };
//     if (status === 200) {
//       return { data: data.Data, ok: true };
//     } else {
//       showToast(data.Message, 'error');
//     }
//     console.log('NOT 200', data);
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (e: any) {
//     if (e.response?.status === 404) {
//       console.log('not submitted');
//       return { data: [], ok: false };
//     }
//     console.log(e.response?.data.Message?.FA);
//     showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
//   }
//   return { ok: false };
// };
