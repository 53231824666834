import { Form, InputGroup, InputProps } from 'rsuite';

interface TextFieldProps extends InputProps {
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accepter: any;
  hideLabel?: boolean;
  formGroupStyle?: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onIconCLick?: any;
  eyeVisible: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onIcon: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  offIcon?: any;
  full?: boolean;
}

export default function IconTextField(props: TextFieldProps) {
  const {
    name,
    label,
    accepter,
    hideLabel,
    formGroupStyle,
    onIconCLick,
    eyeVisible,
    onIcon,
    offIcon,
    full,
    ...rest
  } = props;

  return (
    <Form.Group
      controlId={`${name}`}
      style={formGroupStyle}
      className={`mb-5 max-w-[100%] ${full ? 'full' : ''}`}>
      {hideLabel ? null : <Form.ControlLabel>{label}</Form.ControlLabel>}

      <InputGroup className="flex-row-reverse max-w-[100%] mt-1">
        <Form.Control name={name} accepter={accepter} className="w-[100%]" {...rest} />
        <InputGroup.Addon onClick={onIconCLick} className="cursor-pointer">
          {eyeVisible ? onIcon : offIcon}
        </InputGroup.Addon>
      </InputGroup>
    </Form.Group>
  );
}
