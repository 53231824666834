import {
  PostOrganizationalTrainingsGoodModel,
  PostRequestOrganizationalTrainingsDataModel,
  PostOrganizationalTrainingsServiceModel
} from '../models';
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const postOrganizationalTrainingsPersonal = (
  payload: PostRequestOrganizationalTrainingsDataModel
) =>
  appNetworkManager.post(`/organizationaltrainingform`, {
    ...payload,
    Username: getUsername()
  });

export const getOrganizationalTrainingsPersonal = (
  username: string | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formID: any
) => {
  return appNetworkManager.get(
    `/organizationaltrainingform?Username=${username ?? getUsername()}&ID=${formID}`
  );
};

// good
export const postOrganizationalTrainingsGood = (payload: PostOrganizationalTrainingsGoodModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formgoods`, {
    ...rest,
    Username: getUsername(),
    Form: 'organizational-trainings'
  });
};

export const getOrganizationalTrainingsGood = (
  username: string | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formID: any
) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formgoods?Username=${username ?? getUsername()}&Form=organizational-trainings&FormID=${formID}`
  );
};
export const deleteOrganizationalTrainingsGood = (id: number | string) => {
  return appNetworkManager.delete(`/formgoods?ID=${id}`);
};

// service
export const postOrganizationalTrainingsService = (
  payload: PostOrganizationalTrainingsServiceModel
) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formservices`, {
    ...rest,
    Username: getUsername(),
    Form: 'organizational-trainings'
  });
};

export const getOrganizationalTrainingsService = (
  username: string | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formID: any
) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formservices?Username=${username ?? getUsername()}&Form=organizational-trainings&FormID=${formID}`
  );
};
export const deleteOrganizationalTrainingsService = (id: number | string) => {
  return appNetworkManager.delete(`/formservices?ID=${id}`);
};

// register request
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const postOrganizationalTrainingsRegisterRequest = (FormID: any) => {
  return appNetworkManager.post(`/organizationaltrainingformregreq`, {
    Username: getUsername(),
    Status: 'Pending',
    FormID
  });
};
export const putOrganizationalTrainingsRegisterRequest = (payload: { Status: String }) => {
  return appNetworkManager.put(`/organizationaltrainingformregreq`, {
    ...payload,
    Username: getUsername()
  });
};

export const getOrganizationalTrainingsRegisterRequest = (payload: {
  Status: String;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormID: any;
}) => {
  return appNetworkManager.put(`/organizationaltrainingformregreq`, {
    ...payload,
    Username: getUsername()
  });
};
export const getOrganizationalTrainings = () => {
  return appNetworkManager.get(`/organizationaltrainingformregreqs`);
};
// export const postOrganizationalTrainingsBuycv = (seekerUsername: string) => {
//   return appNetworkManager.post(`/buycv`, {
//     EmployerUsername: getUsername(),
//     JobSeekerUsername: seekerUsername
//   });
// };
// export const getOrganizationalTrainingsBuycv = () => {
//   return appNetworkManager.get(`/buycv?EmployerUsername=${getUsername()}`);
// };
