import { Button, Col, FlexboxGrid, Form, Input } from 'rsuite';
import TextField from '../../../../components/form/textfield';
import { useCallback, useRef, useState } from 'react';
import TextAreaField from '../../../../components/form/textarea-field';
import { showToast } from '../../../../utils/toast';
import {
  handlePostOrganizationalTrainingsService,
  handlePutOrganizationalTrainingsService
} from '../../../../api-handler';
import {
  PostOrganizationalTrainingsServiceModel,
  defaultOrganizationalTrainingsServiceFormValue,
  defaultOrganizationalTrainingsServiceSchema
} from '../../../../models';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../../../router';

export const ServiceForm = (props: {
  readonly?: boolean;
  handleAddDoingData: (newData: PostOrganizationalTrainingsServiceModel) => void;
  defaultValue?: PostOrganizationalTrainingsServiceModel | null;
  hideNextButton?: boolean;
  forceReadOnly?: boolean;
  disabledMode?: boolean;
  hasEditMode?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  isAdmin: boolean;
  onDelete: (id: string | number) => void;
}) => {
  const [searchParams] = useSearchParams();
  const [FormID] = useState(searchParams.get('FormID'));
  const [loading, setLoading] = useState(false);
  const [loadingUpload] = useState(false);
  const navigate = useNavigate();
  const [disabledMode, setDisabledMode] = useState(props.hasEditMode);
  const [forceReadOnly] = useState(props.forceReadOnly);
  const [readOnly, setReadOnly] = useState(props.readonly);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>(
    props.defaultValue ?? defaultOrganizationalTrainingsServiceFormValue
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [defaultValue, setDefaultValue] = useState<any>(
    props.defaultValue ?? defaultOrganizationalTrainingsServiceFormValue
  );

  const handleSubmit = useCallback(
    async (isNext: boolean, updateMode: boolean) => {
      if (formRef.current && !formRef.current.check()) {
        console.log('formError', formError);
        return;
      }

      if (!formValue) return;
      const payload = {
        ...formValue,
        FormID
      };
      if (payload.File) delete payload.File;

      setLoading(true);
      if (updateMode) {
        if (payload.Username) delete payload.Username;
        const ress = await handlePutOrganizationalTrainingsService(payload, 'ID', payload.ID);
        if (ress.ok) {
          showToast('فرم به روزرسانی شد!', 'success');
          setDisabledMode(true);
          // setForceReadOnly(true);
          setReadOnly(true);
          setLoading(false);
          setDefaultValue(payload);
          if (isNext) {
            navigate(`${ROUTES.roles.reqOrganizationalTrainings.tnc}?FormID=${FormID}`);

            return;
          }
        }
        setLoading(false);
        return;
      }
      const res = await handlePostOrganizationalTrainingsService(payload);
      setLoading(false);
      if (res.ok) {
        showToast('فرم ثبت شد!', 'success');
        // or getting submitted data from backend
        if (isNext) {
          navigate(`${ROUTES.roles.reqOrganizationalTrainings.tnc}?FormID=${FormID}`);

          return;
        }
        props.handleAddDoingData({ ...payload, ID: res.data.ID });
        setFormValue(defaultOrganizationalTrainingsServiceFormValue);
      }
    },
    [formValue, formError, formRef, FormID]
  );

  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={defaultOrganizationalTrainingsServiceSchema}
      onSubmit={() => handleSubmit(false, false)}
      formValue={formValue}
      checkTrigger="change"
      className="mt-6 text-dark"
      readOnly={forceReadOnly ?? loading}
      disabled={disabledMode}>
      <FlexboxGrid className="bg-[--rs-primary-50] rounded-md border-[1px] border-[--rs-primary-500] p-3">
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%] !px-0">
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
            <TextField
              name="Name"
              label="نام خدمت"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
              req
              readOnly={readOnly}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
          <TextAreaField
            name="Description"
            label={'توضیحات'}
            dir="rtl"
            full
            req
            rows={5}
            placeholder=""
            readOnly={readOnly}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
          {props.isLast && !props.isAdmin ? (
            <div className="flex justify-between">
              {defaultValue.ID ? (
                <Button
                  appearance="primary"
                  type="button"
                  onClick={() => {
                    setDisabledMode(false);
                    // setForceReadOnly(false);
                    setReadOnly(false);
                  }}
                  loading={loading || loadingUpload}>
                  ویرایش
                </Button>
              ) : null}
              {!props.isFirst ? (
                <Button
                  appearance="ghost"
                  type="button"
                  className="mr-auto"
                  onClick={() => {
                    props.onDelete(defaultValue.ID ?? null);
                  }}
                  loading={loading || loadingUpload}>
                  حذف
                </Button>
              ) : null}
            </div>
          ) : null}
          {props.hasEditMode && !props.isAdmin ? (
            <div>
              {disabledMode ? (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      setDisabledMode(false);
                      // setForceReadOnly(false);
                      setReadOnly(false);
                    }}
                    loading={loading || loadingUpload}>
                    ویرایش
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      props.onDelete(defaultValue.ID ?? null);
                    }}
                    loading={loading || loadingUpload}>
                    حذف
                  </Button>
                </div>
              ) : (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      handleSubmit(false, true);
                    }}
                    loading={loading || loadingUpload}>
                    به روزرسانی
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      setFormValue(defaultValue);
                      setDisabledMode(true);
                      // setForceReadOnly(true);
                      setReadOnly(true);
                    }}
                    loading={loading || loadingUpload}>
                    لغو
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {!props.isLast ? null : (
        <>
          <div className="flex justify-between">
            <Button
              appearance="primary"
              className="ml-auto mt-4"
              type="button"
              onClick={() => handleSubmit(false, false)}
              loading={loading || loadingUpload}>
              {props.hideNextButton ? 'ثبت خدمت' : 'افزودن خدمت جدید'}
            </Button>
          </div>
          {props.hideNextButton ? null : (
            <div className="flex justify-between">
              <Button
                appearance="default"
                className="ml-auto mt-8"
                type="button"
                onClick={() => {
                  navigate(`${ROUTES.roles.reqOrganizationalTrainings.good}?FormID=${FormID}`);
                }}
                loading={loading || loadingUpload}>
                مرحله قبل
              </Button>
              <Button
                appearance="primary"
                className="mr-auto mt-8"
                type="button"
                onClick={() => handleSubmit(true, false)}
                loading={loading || loadingUpload}>
                ثبت و مرحله بعد
              </Button>
            </div>
          )}
        </>
      )}
    </Form>
  );
};
