import { Col, FlexboxGrid } from 'rsuite';
import { ReactComponent as UserIcon } from '../../../../assets/icons/roles/user.svg';
import { ReactComponent as VendorIcon } from '../../../../assets/icons/roles/vendor.svg';
import { ReactComponent as ConsultantIcon } from '../../../../assets/icons/roles/consultant.svg';

import { Link } from 'react-router-dom';

export const AdminRolesRequests = () => {
  return (
    <>
      <h4 className="text-h3 mt-4">درخواست های نقش</h4>

      <FlexboxGrid className="mt-4">
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={8}
          className="max-w-[100%] mb-2">
          <Link to={`/admin/register-seeker-list`}>
            <div
              className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
              <div className="flex items-center flex-col w-full pt-5">
                <h3 className="text-h3 mb-4 text-[--rs-primary-800]">کارجو</h3>
                <div className="w-full h-auto mx-auto">
                  <UserIcon className="mx-auto" />
                </div>
              </div>
            </div>
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={8}
          className="max-w-[100%] mb-2">
          <Link to={'/admin/register-corporation-list'}>
            <div
              className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
              <div className="flex items-center flex-col w-full pt-5">
                <h3 className="text-h3 mb-4 text-[--rs-primary-800]">کسب و کار</h3>
                <div className="w-full h-auto mx-auto">
                  <VendorIcon className="mx-auto" />
                </div>
              </div>
            </div>
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={8}
          className="max-w-[100%] mb-2">
          <Link to={'/admin/register-consultant-list'}>
            <div
              className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
              <div className="flex items-center flex-col w-full pt-5">
                <h3 className="text-h3 mb-4 text-[--rs-primary-800]">مشاور</h3>
                <div className="w-full h-auto mx-auto">
                  <ConsultantIcon className="mx-auto" />
                </div>
              </div>
            </div>
          </Link>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};
