import { Button } from 'rsuite';
import { ConsulteeMeetingReport } from '../../consult/consultee-meeting-report';
import { showToast } from '../../../utils/toast';
import { handlePutChangeCommentStatus } from '../../../api-handler';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';

export const AdminCommentSingle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const handleAcceptComment = useCallback(async () => {
    const res = await handlePutChangeCommentStatus(id, true);
    if (res.ok) {
      showToast('با موفقیت تایید شد', 'success');
      navigate('/admin/comments-list');
    }
  }, [id]);
  const handleRejectComment = useCallback(async () => {
    const res = await handlePutChangeCommentStatus(id, false);
    if (res.ok) {
      showToast('با موفقیت پنهان شد', 'success');
      navigate('/admin/comments-list');
    }
  }, [id]);
  return (
    <>
      <ConsulteeMeetingReport isAdmin={true} />
      <div className="flex items-center justify-between">
        <Button appearance="primary" onClick={handleAcceptComment}>
          تایید نظر
        </Button>
        <Button appearance="ghost" onClick={handleRejectComment}>
          پنهان کردن نظر
        </Button>
      </div>
    </>
  );
};
