/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Form, Input, InputPicker, FlexboxGrid } from 'rsuite';
import TextAreaField from '../../../components/form/textarea-field';
import TextField from '../../../components/form/textfield';
import Field from '../../../components/form/field';
import { CustomLoader } from '../../../components/basic/loader';

import { showToast } from '../../../utils/toast';

import {
  handlePostIdeaPersonal,
  handleGetIdeaPersonal,
  handlePutIdeaPersonal
} from '../../../api-handler';
import { RequestIdeaDataFormSchema } from '../../../models';

import { ROUTES } from '../../../router';

import FounderZoneOptions from '../../../data/global/founder-zone.json';
import IdeaTypeOptions from '../../../data/global/idea-type.json';
export const IdeaPersonal = (props: {
  disabledMode?: boolean;
  isAdmin?: boolean;
  username?: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [FormID] = useState(searchParams.get('FormID'));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initial, setInitial] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normalizeFormValue = (fv: any) => {
    let payload = {
      ...fv
    };
    if (payload.CompanyPhone) {
      payload = { ...payload };
    }

    return payload;
  };

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }

    if (!formValue) return;
    const payload = normalizeFormValue(formValue);
    if (!payload) return;
    setLoading(true);
    if (editMode) {
      const updateRes = await handlePutIdeaPersonal(payload, 'ID', initial.ID);
      if (updateRes.ok) {
        showToast('مشخصات به روزرسانی شد!', 'success');
        navigate(`${ROUTES.roles.reqIdea.tnc}?FormID=${FormID}`);
        // navigate(ROUTES.roles.reqIdea.good);
      }
      setLoading(false);
      return;
    }
    const res = await handlePostIdeaPersonal(payload);
    if (res.ok) {
      showToast('مشخصات ثبت شد!', 'success');
      const formID = res.data.Data.Data.ID;
      navigate(`${ROUTES.roles.reqIdea.tnc}?FormID=${formID}`);
      // navigate(ROUTES.roles.reqIdea.good);
      setLoading(false);
      return;
    }
    setLoading(false);
  }, [formValue, formError, formRef, FormID]);

  const handleGetInitialData = async (username: string | null | undefined) => {
    if (FormID) {
      setLoading(true);
      const res = await handleGetIdeaPersonal(username, FormID);
      if (res.ok) {
        setInitial(res.data);
        setFormValue(res.data);
        setEditMode(true);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetInitialData(props.isAdmin ? props.username : null);
    })();
  }, [props.username]);

  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <h4 className="text-h4">
        {props.isAdmin
          ? 'درخواست همکاری تبدیل ایده به محصول'
          : 'درخواست همکاری تبدیل ایده به محصول'}
      </h4>

      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={RequestIdeaDataFormSchema}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading}
        disabled={props.disabledMode}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CompanyName"
              label="نام کسب‌و‌کار"
              type="text"
              accepter={Input}
              full
              dir="rtl"
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEOName"
              label="نام و نام خانوادگی"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEONationalID"
              label="کد ملی"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="IdeaName"
              label="عنوان ایده"
              type="text"
              accepter={Input}
              dir="rtle"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <FlexboxGrid className="max-w-[100%] px-0">
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <Field
                  name="IdeaCategory"
                  label="نوع ایده"
                  accepter={InputPicker}
                  data={IdeaTypeOptions}
                  dir="rtl"
                  full
                  req
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="IdeaType"
              label="توضیح مختصر از ایده"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="DistributionDetails"
              label="توضیحات نحوه توزیع و ارائه محصول"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="BusinessDetails"
              label="توضیحات در خصوص کسب‌و‌کار"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="CustomerDetails"
              label="توضیحات در خصوص مشتریان"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          {/* <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CollaborationType"
              label="نوع همکاری"
              dir="rtl"
              accepter={Input}
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item> */}
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              name="GeographicDistribution"
              label="گستردگی جغرافیایی مکان توزیع و فروش"
              accepter={InputPicker}
              data={FounderZoneOptions}
              dir="rtl"
              full
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CompanyWebsite"
              label="آدرس سایت"
              type="text"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
            <TextAreaField name="CompanyAddress" label="آدرس" dir="rtl" full req placeholder="" />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
            <TextAreaField
              name="RequestReason"
              label="دلیل درخواست"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        {!props.disabledMode ? (
          <div className="flex justify-between">
            <Button appearance="primary" className="mr-auto mt-8" type="submit" loading={loading}>
              مرحله بعد
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  );
};
