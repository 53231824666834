import {
  PostRecruitmentGoodModel,
  PostRequestRecruitmentDataModel,
  PostRecruitmentServiceModel
} from '../models';
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const postRecruitmentPersonal = (payload: PostRequestRecruitmentDataModel) =>
  appNetworkManager.post(`/specialrecruitmentform`, {
    ...payload,
    Username: getUsername()
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRecruitmentPersonal = (username: string | null | undefined, formID: any) => {
  return appNetworkManager.get(
    `/specialrecruitmentform?Username=${username ?? getUsername()}&ID=${formID}`
  );
};

// good
export const postRecruitmentGood = (payload: PostRecruitmentGoodModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formgoods`, {
    ...rest,
    Username: getUsername(),
    Form: 'recruitment'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRecruitmentGood = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formgoods?Username=${username ?? getUsername()}&Form=recruitment&FormID=${formID}`
  );
};
export const deleteRecruitmentGood = (id: number | string) => {
  return appNetworkManager.delete(`/formgoods?ID=${id}`);
};

// service
export const postRecruitmentService = (payload: PostRecruitmentServiceModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formservices`, {
    ...rest,
    Username: getUsername(),
    Form: 'recruitment'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRecruitmentService = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formservices?Username=${username ?? getUsername()}&Form=recruitment&FormID=${formID}`
  );
};
export const deleteRecruitmentService = (id: number | string) => {
  return appNetworkManager.delete(`/formservices?ID=${id}`);
};

// register request
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const postRecruitmentRegisterRequest = (FormID: any) => {
  return appNetworkManager.post(`/specialrecruitmentregreq`, {
    Username: getUsername(),
    Status: 'Pending',
    FormID
  });
};
export const putRecruitmentRegisterRequest = (payload: { Status: String }) => {
  return appNetworkManager.put(`/specialrecruitmentregreq`, {
    ...payload,
    Username: getUsername()
  });
};

export const getRecruitmentRegisterRequest = () => {
  return appNetworkManager.get(`/specialrecruitmentregreq?Username=${getUsername()}`);
};
export const getRecruitment = () => {
  return appNetworkManager.get(`/`);
};
// export const postRecruitmentBuycv = (seekerUsername: string) => {
//   return appNetworkManager.post(`/buycv`, {
//     EmployerUsername: getUsername(),
//     JobSeekerUsername: seekerUsername
//   });
// };
// export const getRecruitmentBuycv = () => {
//   return appNetworkManager.get(`/buycv?EmployerUsername=${getUsername()}`);
// };
