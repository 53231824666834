/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Form, Input, InputPicker, FlexboxGrid } from 'rsuite';
import TextAreaField from '../../../components/form/textarea-field';
import TextField from '../../../components/form/textfield';
import Field from '../../../components/form/field';
import { CustomLoader } from '../../../components/basic/loader';

import { showToast } from '../../../utils/toast';

import {
  handlePostInqueryPersonal,
  handleGetInqueryPersonal,
  handlePutInqueryPersonal
} from '../../../api-handler';
import { RequestInqueryDataFormSchema } from '../../../models';

import { ROUTES } from '../../../router';

import InqueryTypeOptions from '../../../data/global/inquery-type.json';

export const InqueryPersonal = (props: {
  disabledMode?: boolean;
  isAdmin?: boolean;
  username?: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [FormID] = useState(searchParams.get('FormID'));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initial, setInitial] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normalizeFormValue = (fv: any) => {
    let payload = {
      ...fv,
      InquiryType: fv.InquiryType == 'سایر' ? fv.InqueryTypeDescription : fv.InquiryType
    };
    if (payload.CompanyPhone) {
      payload = { ...payload };
    }

    return payload;
  };

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }

    if (!formValue) return;
    const payload = normalizeFormValue(formValue);
    if (!payload) return;
    setLoading(true);
    if (editMode) {
      const updateRes = await handlePutInqueryPersonal(payload, 'ID', initial.ID);

      if (updateRes.ok) {
        showToast('مشخصات به روزرسانی شد!', 'success');
        navigate(`${ROUTES.roles.reqInquery.good}?FormID=${FormID}`);
      }
      setLoading(false);
      return;
    }
    const res = await handlePostInqueryPersonal(payload);
    if (res.ok) {
      showToast('مشخصات ثبت شد!', 'success');
      const formID = res.data.Data.Data.ID;
      navigate(`${ROUTES.roles.reqInquery.good}?FormID=${formID}`);
      setLoading(false);
      return;
    }
    setLoading(false);
  }, [formValue, formError, formRef, FormID]);

  const handleGetInitialData = async (username: string | null | undefined) => {
    if (FormID) {
      setLoading(true);
      const res = await handleGetInqueryPersonal(username, FormID);
      if (res.ok) {
        setInitial(res.data);
        setFormValue(res.data);
        setEditMode(true);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetInitialData(props.isAdmin ? props.username : null);
    })();
  }, [props.username]);

  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <h4 className="text-h4">
        {props.isAdmin ? 'درخواست استعلام و ارائه خدمات' : 'درخواست استعلام و ارائه خدمات'}
      </h4>

      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={RequestInqueryDataFormSchema}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading}
        disabled={props.disabledMode}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CompanyName"
              label="نام"
              type="text"
              accepter={Input}
              full
              dir="rtl"
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEOName"
              label="نام و نام خانوادگی"
              type="text"
              accepter={Input}
              dir="rtl"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="CEONationalID"
              label="کد ملی"
              type="number"
              accepter={Input}
              dir="ltr"
              full
              placeholder=""
              req
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <FlexboxGrid>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <Field
                  name="InquiryType"
                  label="نوع استعلام محصول درخواستی"
                  accepter={InputPicker}
                  data={InqueryTypeOptions}
                  dir="rtl"
                  full
                  req
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <TextField
                  name="CompanyWebsite"
                  label="آدرس سایت"
                  type="text"
                  accepter={Input}
                  dir="ltr"
                  full
                  placeholder=""
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>

          {formValue.InquiryType == 'سایر' ? (
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <TextAreaField
                name="InqueryTypeDescription"
                label="توضیح نوع استعلام محصول درخواستی"
                dir="rtl"
                full
                req
                placeholder=""
              />
            </FlexboxGrid.Item>
          ) : null}
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="InquiryDescription"
              label="مشخصات محصول درخواستی"
              dir="rtl"
              full
              req
              placeholder="تعداد یا مقدار و توضیحات کیفی"
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="BusinessDetails"
              label="توضیحات در خصوص کسب و کار"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
            <TextAreaField
              name="CompanyAddress"
              label="آدرس کسب‌و‌کار"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
            <TextAreaField
              name="RequestReason"
              label="دلیل درخواست"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        {!props.disabledMode ? (
          <div className="flex justify-between">
            <Button appearance="primary" className="mr-auto mt-8" type="submit" loading={loading}>
              مرحله بعد
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  );
};
