/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Col, FlexboxGrid, Input, InputPicker } from 'rsuite';
import Field from './field';
import cities from '../../data/global/cities.json';
import states from '../../data/global/states.json';

export const CountryStateCity = ({
  setFormValue,
  hasCountry,
  cityOptions,
  stateOptions,
  countryOptions
}: any) => {
  const [theState, seTheState] = useState<
    | '_'
    | 'آذربایجان شرقی'
    | 'آذربایجان غربی'
    | 'اردبیل'
    | 'اصفهان'
    | 'البرز'
    | 'ایلام'
    | 'بوشهر'
    | 'تهران'
    | 'چهارمحال و بختیاری'
    | 'خراسان جنوبی'
    | 'خراسان رضوی'
    | 'خراسان شمالی'
    | 'خوزستان'
    | 'زنجان'
    | 'سمنان'
    | 'سیستان و بلوچستان'
    | 'فارس'
    | 'قزوین'
    | 'قم'
    | 'کردستان'
    | 'کرمان'
    | 'کرمانشاه'
    | 'کهگیلویه و بویراحمد'
    | 'گلستان'
    | 'لرستان'
    | 'گیلان'
    | 'مازندران'
    | 'مرکزی'
    | 'هرمزگان'
    | 'همدان'
    | 'یزد'
  >(stateOptions.default ?? '');
  const [country, setCountry] = useState(countryOptions.default ?? 'ایران');
  return (
    <>
      {hasCountry ? (
        <>
          <FlexboxGrid.Item
            as={Col}
            colspan={countryOptions.colspan}
            sm={countryOptions.sm}
            md={countryOptions.md}
            className="max-w-[100%]">
            <Field
              name={`${countryOptions.name}-t`}
              label={countryOptions.label}
              accepter={InputPicker}
              data={[
                { label: 'ایران', value: 'ایران' },
                { label: 'سایر', value: 'سایر' }
              ]}
              dir="rtl"
              full
              onChange={(val: any) => setCountry(val)}
              defaultValue="ایران"
              value={country == 'ایران' ? 'ایران' : 'سایر'}
              req
            />
          </FlexboxGrid.Item>
          {country !== 'ایران' ? (
            <FlexboxGrid.Item
              as={Col}
              colspan={countryOptions.colspan}
              sm={countryOptions.sm}
              md={countryOptions.md}
              className="max-w-[100%]">
              <Field
                name={countryOptions.name}
                label={`نام ${countryOptions.label}`}
                value={country == 'سایر' ? '' : country}
                onChange={(val: any) => setCountry(val)}
                accepter={Input}
                dir="rtl"
                full
                defaultValue=""
                req
              />
            </FlexboxGrid.Item>
          ) : null}
        </>
      ) : null}
      {country !== 'ایران' ? null : (
        <FlexboxGrid.Item
          as={Col}
          colspan={stateOptions.colspan}
          sm={stateOptions.sm}
          md={stateOptions.md}
          className="max-w-[100%]">
          <Field
            name={stateOptions.name}
            label={stateOptions.label}
            accepter={InputPicker}
            data={states}
            dir="rtl"
            onChange={(val: any) => {
              setFormValue((pre: any) => {
                return { ...pre, [cityOptions.name]: '' };
              });
              seTheState(val);
            }}
            full
            req
            readOnly={stateOptions.readOnly}
          />
        </FlexboxGrid.Item>
      )}
      {country !== 'ایران' ? (
        <FlexboxGrid.Item
          as={Col}
          colspan={cityOptions.colspan}
          sm={cityOptions.sm}
          md={cityOptions.md}
          className="max-w-[100%]">
          <Field
            name={cityOptions.name}
            label={cityOptions.label}
            accepter={Input}
            dir="rtl"
            full
            req
            readOnly={cityOptions.readOnly}
          />
        </FlexboxGrid.Item>
      ) : (
        <FlexboxGrid.Item
          as={Col}
          colspan={cityOptions.colspan}
          sm={cityOptions.sm}
          md={cityOptions.md}
          className="max-w-[100%]">
          <Field
            name={cityOptions.name}
            label={cityOptions.label}
            accepter={InputPicker}
            data={cities[theState]}
            placeholder={cityOptions.default ?? 'انتخاب کنید'}
            dir="rtl"
            full
            req
            readOnly={cityOptions.readOnly}
          />
        </FlexboxGrid.Item>
      )}
    </>
  );
};
