import {
  PostInqueryGoodModel,
  PostRequestInqueryDataModel,
  PostInqueryServiceModel
} from '../models';
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const postInqueryPersonal = (payload: PostRequestInqueryDataModel) =>
  appNetworkManager.post(`/serviceinquiryform`, {
    ...payload,
    Username: getUsername()
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInqueryPersonal = (username: string | null | undefined, formID: any) => {
  return appNetworkManager.get(
    `/serviceinquiryform?Username=${username ?? getUsername()}&ID=${formID}`
  );
};

// good
export const postInqueryGood = (payload: PostInqueryGoodModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formgoods`, {
    ...rest,
    Username: getUsername(),
    Form: 'inquery'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInqueryGood = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formgoods?Username=${username ?? getUsername()}&Form=inquery&FormID=${formID}`
  );
};
export const deleteInqueryGood = (id: number | string) => {
  return appNetworkManager.delete(`/formgoods?ID=${id}`);
};

// service
export const postInqueryService = (payload: PostInqueryServiceModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formservices`, {
    ...rest,
    Username: getUsername(),
    Form: 'inquery'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInqueryService = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formservices?Username=${username ?? getUsername()}&Form=inquery&FormID=${formID}`
  );
};
export const deleteInqueryService = (id: number | string) => {
  return appNetworkManager.delete(`/formservices?ID=${id}`);
};

// register request
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const postInqueryRegisterRequest = (FormID: any) => {
  return appNetworkManager.post(`/serviceinquiryformregreq`, {
    Username: getUsername(),
    Status: 'Pending',
    FormID
  });
};
export const putInqueryRegisterRequest = (payload: { Status: String }) => {
  return appNetworkManager.put(`/serviceinquiryformregreq`, {
    ...payload,
    Username: getUsername()
  });
};

export const getInqueryRegisterRequest = () => {
  return appNetworkManager.get(`/serviceinquiryformregreq?Username=${getUsername()}`);
};
export const getInquery = () => {
  return appNetworkManager.get(`/organizationalprojects`);
};
// export const postInqueryBuycv = (seekerUsername: string) => {
//   return appNetworkManager.post(`/buycv`, {
//     EmployerUsername: getUsername(),
//     JobSeekerUsername: seekerUsername
//   });
// };
// export const getInqueryBuycv = () => {
//   return appNetworkManager.get(`/buycv?EmployerUsername=${getUsername()}`);
// };
