export interface CardProps {
  children: React.ReactNode;
  className?: string;
  newClassName?: string;
}
export const Card = (props: CardProps) => (
  <div
    className={
      !props.newClassName
        ? `${props.className ?? null} bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-2 py-8 px-[1rem] md:px-[1.5rem] w-fit max-w-[90%] `
        : props.newClassName
    }>
    {props.children}
  </div>
);
