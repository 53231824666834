/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  postSeekerPersonal,
  getSeekerPersonal,
  getSeekerWorkBackground,
  postSeekerWorkBackground,
  deleteSeekerWorkBackground,
  postSeekerEducationBackground,
  deleteSeekerEducationBackground,
  getSeekerEducationBackground,
  getSeekerCourseBackground,
  postSeekerCourseBackground,
  deleteSeekerCourseBackground,
  postSeekerRegisterRequest,
  putSeekerRegisterRequest,
  getSeekerRegisterRequest,
  deleteSeekerDesire,
  getSeekerDesire,
  postSeekerDesire,
  deleteSeekerSkillBackground,
  getSeekerSkillBackground,
  postSeekerSkillBackground
} from '../core';
import {
  PostSeekerCourseBackgroundModel,
  PostSeekerDesireModel,
  PostSeekerEducationBackgroundModel,
  PostSeekerPersonalModel,
  PostSeekerSkillBackgroundModel,
  PostSeekerWorkBackgroundModel
} from '../models';
import { showToast } from '../utils/toast';
import { handleUpdateAny } from './global';

export const handlePostSeekerPersonal = async (payload: PostSeekerPersonalModel) => {
  try {
    const { data, status } = await postSeekerPersonal(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetSeekerPersonal = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getSeekerPersonal(username);
    // return { ok: true, payload };
    if (status === 200) {
      return {
        data: {
          ...data.Data,
          CellPhone: `0${data.Data.CellPhone.substring(3)}`
        },
        ok: true
      };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutSeekerPersonal = (
  payload: PostSeekerPersonalModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'JobSeekerP');
};

// ------------------work------------------
export const handlePostSeekerWorkBackground = async (payload: PostSeekerWorkBackgroundModel) => {
  try {
    const { data, status } = await postSeekerWorkBackground(payload);
    // return { ok: true, payload };
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetSeekerWorkBackground = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getSeekerWorkBackground(username);
    // return { ok: true, payload };
    if (status === 200) {
      return { ok: true, data: data.Data };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleDeleteSeekerWorkBackground = async (id: number | string) => {
  try {
    const { data, status } = await deleteSeekerWorkBackground(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutSeekerWorkBackground = (
  payload: PostSeekerPersonalModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'JobSeekerW');
};

// education
export const handlePostSeekerEducationBackground = async (
  payload: PostSeekerEducationBackgroundModel
) => {
  try {
    const { data, status } = await postSeekerEducationBackground(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetSeekerEducationBackground = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getSeekerEducationBackground(username);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutSeekerEducationBackground = async (
  payload: PostSeekerPersonalModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'JobSeekerE');
};
export const handleDeleteSeekerEducationBackground = async (id: number | string) => {
  try {
    const { data, status } = await deleteSeekerEducationBackground(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// course
export const handlePostSeekerCourseBackground = async (
  payload: PostSeekerCourseBackgroundModel
) => {
  try {
    const { data, status } = await postSeekerCourseBackground(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetSeekerCourseBackground = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getSeekerCourseBackground(username);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutSeekerCourseBackground = async (
  payload: PostSeekerCourseBackgroundModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'JobSeekerC');
};
export const handleDeleteSeekerCourseBackground = async (id: number | string) => {
  try {
    const { data, status } = await deleteSeekerCourseBackground(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
// skill
export const handlePostSeekerSkillBackground = async (payload: PostSeekerSkillBackgroundModel) => {
  try {
    const { data, status } = await postSeekerSkillBackground(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetSeekerSkillBackground = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getSeekerSkillBackground(username);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutSeekerSkillBackground = async (
  payload: PostSeekerSkillBackgroundModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'JobSeekerS');
};
export const handleDeleteSeekerSkillBackground = async (id: number | string) => {
  try {
    const { data, status } = await deleteSeekerSkillBackground(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// desire
export const handlePostSeekerDesire = async (payload: PostSeekerDesireModel) => {
  try {
    const { data, status } = await postSeekerDesire(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetSeekerDesire = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getSeekerDesire(username);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutSeekerDesire = async (
  payload: PostSeekerDesireModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'JobSeekerDesiredFields');
};
export const handleDeleteSeekerDesire = async (id: number | string) => {
  try {
    const { data, status } = await deleteSeekerDesire(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// request
export const handlePostSeekerRegisterRequest = async () => {
  try {
    const { data, status } = await postSeekerRegisterRequest();
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutSeekerRegisterRequest = async (payload: { Status: string }) => {
  try {
    const { data, status } = await putSeekerRegisterRequest(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetSeekerRegisterRequest = async () => {
  try {
    const { data, status } = await getSeekerRegisterRequest();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetSeekerInfo = async (username: string) => {
  try {
    const res1 = await getSeekerPersonal(username);
    const res2 = await getSeekerWorkBackground(username);
    const res3 = await getSeekerEducationBackground(username);
    const res4 = await getSeekerCourseBackground(username);
    const res5 = await getSeekerDesire(username);
    // return { ok: true, payload };
    if (res1.status === 200) {
      return {
        data: {
          Personal: res1.data.Data,
          WorkBackground: res2.data.Data,
          EducationBackground: res3.data.Data,
          CourseBackground: res4.data.Data,
          Desire: res5.data.Data
        },
        ok: true
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: [], ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
