import { Rate } from 'rsuite';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { handleGetConsultantComments } from '../../../api-handler';
import { CONSULT_REPORT_PC, FormatDate } from '../../../utils/helpers';
import { faIR } from 'date-fns-jalali/locale';

const RenderPositives = ({ survey }: { survey: string }) => {
  const s = JSON.parse(survey.replaceAll("'", '"'));
  console.log(s);
  if (!survey) return null;
  const pros = s.Pros;
  const cons = s.Cons;
  return (
    <>
      {Object.keys(pros).map((item: string, index: number) => {
        if (!pros[item]) return null;
        return (
          <div key={index} className="flex items-center">
            <PlusIcon style={{ color: 'var(--rs-success-600)', marginLeft: '4px', width: 16 }} />
            {CONSULT_REPORT_PC.pros[item]}
          </div>
        );
      })}
      {Object.keys(cons).map((item: string, index: number) => {
        if (!cons[item]) return null;
        return (
          <div key={index} className="flex items-center">
            <MinusIcon style={{ color: 'var(--rs-error-600)', marginLeft: '4px', width: 16 }} />
            {CONSULT_REPORT_PC.cons[item]}
          </div>
        );
      })}
    </>
  );
};
export const ConsultantComments = () => {
  const { username } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await handleGetConsultantComments(username ?? '');
      if (res.ok) {
        setData(res.data);
      }
    })();
  }, [username]);
  if (!data.length) return null;
  return (
    <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 md:p-8 w-full mt-4">
      <h4 className="text-h4 text-[--rs-primary-800] mb-4">نظرات کاربران</h4>
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data.map((item: any) => {
          return (
            <div
              key={item.ID}
              className=" bg-[--rs-primary-50] border-[--rs-primary-300] rounded-lg border-[1px] p-4 w-[80%] max-w-[600px]">
              <span className="flex items-center justify-between">
                <Rate size="sm" value={item.Score} color="yellow" readOnly />
              </span>
              <br />
              <span>{item.PublicReport}</span>
              <div className="mt-3">
                <RenderPositives survey={item.Survey} />
              </div>
              <div className="text-[--rs-primary-200] flex justify-end">
                {FormatDate(item.ReportTimeStamp, faIR)}
              </div>
            </div>
          );
        })
      }
    </div>
  );
};
