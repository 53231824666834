/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, createContext, useState, ReactChild, useContext } from 'react';
interface DataContext {
  consultantRegisterRequest?: any;
  setConsultantRegisterRequest?: any;
  seekerDesired?: any;
  setSeekerDesired?: any;
  seekerPersonal?: any;
  setSeekerPersonal?: any;
  corporationPersonal?: any;
  setCorporationPersonal?: any;
}

const context = createContext<DataContext>({});

const { Provider } = context;

interface Props {
  children: ReactChild | ReactChild[];
}

export const DataProvider: FC<Props> = ({ children }) => {
  const [consultantRegisterRequest, setConsultantRegisterRequest] = useState<any>();
  const [seekerDesired, setSeekerDesired] = useState<any>([]);
  const [seekerPersonal, setSeekerPersonal] = useState<any>({});
  const [corporationPersonal, setCorporationPersonal] = useState<any>({});

  return (
    <Provider
      value={{
        consultantRegisterRequest,
        setConsultantRegisterRequest,
        seekerDesired,
        setSeekerDesired,
        seekerPersonal,
        setSeekerPersonal,
        corporationPersonal,
        setCorporationPersonal
      }}>
      {children}
    </Provider>
  );
};

export const useData = () => useContext(context);
