import { useState, useEffect } from 'react';
import { PostRecruitmentGoodModel } from '../../../models';
import { Button } from 'rsuite';
import { handleDeleteRecruitmentGood, handleGetRecruitmentGood } from '../../../api-handler';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { showToast } from '../../utils/toast';
import { CustomLoader } from '../../../components/basic/loader';
import { showToast } from '../../../utils/toast';
import { ROUTES } from '../../../router';
// import { GoodForm } from './components/recruitment-good-form';
import { GoodForm } from './components/recruitment-good-form';

export const RecruitmentGood = (props: {
  disabledMode?: boolean;
  isAdmin: boolean;
  username?: string;
}) => {
  const [searchParams] = useSearchParams();
  const [FormID] = useState(searchParams.get('FormID'));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(true);

  const [data, setData] = useState<(PostRecruitmentGoodModel | null)[]>([]);
  const [showNew, setShowNew] = useState(true);

  const handleAddDoingData = (newData: PostRecruitmentGoodModel | null) => {
    return setData((prev) => {
      return [...prev, newData];
    });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (FormID) {
        const res = await handleGetRecruitmentGood(props.isAdmin ? props.username : null, FormID);
        if (res.ok) {
          setData(res.data);
          setEditMode(true);
          setShowNew(false);
        }
      }
      setLoading(false);
    })();
  }, [props.username]);

  const onDelete = async (id: string | number | null) => {
    if (!id) {
      setShowNew(false);
    } else {
      const dataCopy = [...data];
      setData(dataCopy.filter((item) => item?.ID !== id));
      try {
        const res = await handleDeleteRecruitmentGood(id);
        if (res.ok) {
          showToast('با موفقیت حذف شد.', 'success');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <>
      <h4 className="text-h4">{props.isAdmin ? 'کالا ها' : 'کالا های خود را وارد کنید'}</h4>

      {data.map((item) => {
        return (
          <GoodForm
            key={`${item?.ID}`}
            handleAddDoingData={handleAddDoingData}
            readonly={true}
            hideNextButton={false}
            defaultValue={item}
            isAdmin={props.isAdmin}
            disabledMode={props.disabledMode}
            hasEditMode={editMode}
            onDelete={onDelete}
          />
        );
      })}

      {editMode && showNew && !props.isAdmin ? (
        <GoodForm
          handleAddDoingData={handleAddDoingData}
          forceReadOnly={loading}
          isAdmin={props.isAdmin}
          disabledMode={false}
          isLast={true}
          isFirst={data.length < 1}
          onDelete={onDelete}
        />
      ) : null}

      {editMode && !showNew && !props.isAdmin ? (
        <Button
          appearance="primary"
          className="ml-auto mt-4"
          type="button"
          onClick={() => setShowNew(true)}>
          افزودن کالای جدید
        </Button>
      ) : null}

      {!editMode && data.length && showNew ? (
        <GoodForm
          handleAddDoingData={handleAddDoingData}
          forceReadOnly={loading}
          disabledMode={props.disabledMode}
          isLast={true}
          isAdmin={props.isAdmin}
          isFirst={data.length < 2}
          onDelete={onDelete}
        />
      ) : !editMode && !props.disabledMode ? (
        <Button
          appearance="primary"
          className="ml-auto mt-4"
          type="button"
          onClick={() => handleAddDoingData(null)}>
          افزودن کالای جدید
        </Button>
      ) : null}

      {editMode && !props.disabledMode && !showNew ? (
        <div className="flex justify-between">
          <Button
            appearance="default"
            className="ml-auto mt-8"
            type="button"
            onClick={() => {
              navigate(`${ROUTES.roles.reqRecruitment.personal}?FormID=${FormID}`);
            }}
            loading={loading}>
            مرحله قبل
          </Button>
          <Button
            appearance="primary"
            className="mr-auto mt-8"
            type="button"
            onClick={() => {
              navigate(`${ROUTES.roles.reqRecruitment.service}?FormID=${FormID}`);
            }}
            loading={loading}>
            مرحله بعد
          </Button>
        </div>
      ) : null}
    </>
  );
};
