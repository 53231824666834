import {
  PostMarketingGoodModel,
  PostRequestMarketingDataModel,
  PostMarketingServiceModel
} from '../models';
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const postMarketingPersonal = (payload: PostRequestMarketingDataModel) =>
  appNetworkManager.post(`/marketingsalesform`, {
    ...payload,
    Username: getUsername()
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMarketingPersonal = (username: string | null | undefined, formID: any) => {
  return appNetworkManager.get(
    `/marketingsalesform?Username=${username ?? getUsername()}&ID=${formID}`
  );
};

// good
export const postMarketingGood = (payload: PostMarketingGoodModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formgoods`, {
    ...rest,
    Username: getUsername(),
    Form: 'marketing'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMarketingGood = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formgoods?Username=${username ?? getUsername()}&Form=marketing&FormID=${formID}`
  );
};
export const deleteMarketingGood = (id: number | string) => {
  return appNetworkManager.delete(`/formgoods?ID=${id}`);
};

// service
export const postMarketingService = (payload: PostMarketingServiceModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/formservices`, {
    ...rest,
    Username: getUsername(),
    Form: 'marketing'
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMarketingService = (username: string | null | undefined, formID: any) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(
    `/formservices?Username=${username ?? getUsername()}&Form=marketing&FormID=${formID}`
  );
};
export const deleteMarketingService = (id: number | string) => {
  return appNetworkManager.delete(`/formservices?ID=${id}`);
};

// register request
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const postMarketingRegisterRequest = (FormID: any) => {
  return appNetworkManager.post(`/marketingsalesformregreq`, {
    Username: getUsername(),
    Status: 'Pending',
    FormID
  });
};
export const putMarketingRegisterRequest = (payload: { Status: String }) => {
  return appNetworkManager.put(`/marketingsalesformregreq`, {
    ...payload,
    Username: getUsername()
  });
};

export const getMarketingRegisterRequest = () => {
  return appNetworkManager.get(`/marketingsalesformregreq?Username=${getUsername()}`);
};
export const getMarketing = () => {
  return appNetworkManager.get(`/`);
};
// export const postMarketingBuycv = (seekerUsername: string) => {
//   return appNetworkManager.post(`/buycv`, {
//     EmployerUsername: getUsername(),
//     JobSeekerUsername: seekerUsername
//   });
// };
// export const getMarketingBuycv = () => {
//   return appNetworkManager.get(`/buycv?EmployerUsername=${getUsername()}`);
// };
