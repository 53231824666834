import { Header } from './../../components/headers/header';
import { LayoutProps } from './props';
import { Footer } from './../../components/footer/footer';

export const LayoutFullWithoutPadding = (props: LayoutProps) => (
  <>
    <Header />
    <div className="min-h-[52vh]">{props.children}</div>
    <Footer />
  </>
);
