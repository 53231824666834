/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef } from 'react';
import { Form, Input } from 'rsuite';

const Textarea = forwardRef((props: any, ref: any) => <Input {...props} ref={ref} as="textarea" />);
Textarea.displayName = 'Textarea';

export default function TextAreaField(props: any) {
  const { name, label, hideLabel, full, req, rows, ...rest } = props;
  return (
    <Form.Group controlId={`${name}`} className={`mb-5 max-w-[100%] ${full ? 'full' : ''}`}>
      {hideLabel ? null : (
        <Form.ControlLabel>
          {label} <span className="text-[--rs-error-500]">{req ? '*' : ''}</span>
        </Form.ControlLabel>
      )}
      <Form.Control
        name={name}
        accepter={Textarea}
        className="w-[100%]"
        rows={rows ?? 5}
        {...rest}
      />
    </Form.Group>
  );
}
