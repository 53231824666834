import { NavLink, useNavigate } from 'react-router-dom';
import { CustomNavLink } from '../route-handler/custom-nav';
import { ReactComponent as Logo } from '../../assets/images/brand/rcg2.svg';
import { useUser } from '../../contexts/user';
import { Dropdown } from 'rsuite';
import { ROLE_CONVERTOR } from '../../utils/helpers';
import { useState } from 'react';
export const Header = () => {
  const { isUserRole, user, handleSetSelectedRole, isUserAdmin } = useUser();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ul
      className={`lg:h-14 h-fit bg-[--rs-primary-200] flex flex-col items-center lg:flex-row text-xs text-[--rs-white] navbar-mobile ${isOpen ? 'open' : ''}`}>
      <div className="w-full lg:w-fit max-w-[90%] flex justify-between">
        <NavLink
          to={'https://cbcs.ir'}
          className={`nav-item flex items-center justify-between text-[--rs-primary-800] px-5 h-full pt-4 lg:pt-2 pb-1 lg:mt-0`}>
          <div className="max-h-[56px] min-w-[46px]">
            <Logo className="w-full h-[32px]" />
          </div>
        </NavLink>
        <div
          // style={{ display: isOpen ? 'flex' : 'none' }}
          className="lg:hidden flex justify-center items-center pt-2 scale-125"
          onClick={() => {
            setIsOpen(!isOpen);
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z" />
          </svg>
        </div>
      </div>
      {!user.isLoggedIn ? (
        <>
          <li className="h-full">
            {' '}
            <CustomNavLink to="https://cbcs.ir" title="خانه" />
          </li>
          <li className="h-full">
            {' '}
            <CustomNavLink to="/events" title="رویدادها" />
          </li>
          <li className="h-full">
            {' '}
            <CustomNavLink to="/consultants" title="لیست مشاوران" />
          </li>
          <li className="h-full">
            {' '}
            <CustomNavLink to="/seekers" title="لیست کارجویان" />
          </li>
          <li className="h-full">
            {' '}
            <CustomNavLink to="/corporations" title="لیست کسب و کار ها" />
          </li>
        </>
      ) : null}
      {user.isLoggedIn ? (
        <>
          {!isUserRole('Admin') ? (
            <>
              <li className="h-full">
                {' '}
                <CustomNavLink to="https://cbcs.ir" title="خانه" />
              </li>
              <li className="h-full">
                {' '}
                <CustomNavLink to="/events" title="رویدادها" />
              </li>
              <li className="h-full">
                {' '}
                <CustomNavLink to="/consultants" title="لیست مشاوران" />
              </li>
              <li className="h-full">
                {' '}
                <CustomNavLink to="/seekers" title="لیست کارجویان" />
              </li>
              <li className="h-full">
                {' '}
                <CustomNavLink to="/corporations" title="لیست کسب و کار ها" />
              </li>
            </>
          ) : null}
          {!isUserRole('Admin') ? (
            <li className="h-full">
              {' '}
              <CustomNavLink to="/choose-role" title="درخواست ها" />
            </li>
          ) : null}
          {isUserRole('Admin') ? (
            <>
              <li className="h-full">
                {' '}
                <CustomNavLink to="/admin/roles-requests" title="درخواست های نقش" />
              </li>
              <li className="h-full">
                {' '}
                <CustomNavLink to="/admin/requests" title="درخواست ها" />
              </li>
              <li className="h-full">
                {' '}
                <CustomNavLink to="/admin/profiles-list" title="درخواست های پروفایل" />
              </li>
              <li className="h-full">
                {' '}
                <CustomNavLink to="/admin/comments-list" title="کامنت ها" />
              </li>
              <li className="h-full">
                {' '}
                <CustomNavLink to="/events" title="رویدادها" />
              </li>
            </>
          ) : null}
          {!isUserRole('Consultant') && !isUserRole('Admin') ? (
            <li className="h-full">
              {' '}
              <CustomNavLink to="/user/requests" title="جلسات شما" />
            </li>
          ) : null}
          {isUserRole('Consultant') ? (
            <li className="h-full">
              {' '}
              <CustomNavLink to="/consultant/requests" title="جلسات شما" />
            </li>
          ) : null}
        </>
      ) : null}
      {/*  */}
      <li className="!h-full mr-auto lg:ml-5 flex flex-col lg:flex-row items-center lg:w-fit w-full">
        {user.isLoggedIn ? (
          <Dropdown
            title={`در نقش ${ROLE_CONVERTOR[user.selectedRole]} هستید`}
            className="custom-navlink-dropdown !min-h-[100%] flex flex-col h-full"
            appearance="primary">
            {user.Roles.map((role: string) => (
              <Dropdown.Item
                key={role}
                onClick={() => {
                  handleSetSelectedRole(role);
                  navigate('/');
                }}>
                {ROLE_CONVERTOR[role]}
              </Dropdown.Item>
            ))}
          </Dropdown>
        ) : null}
        {user.isLoggedIn ? <CustomNavLink to="/user/profile" title="پروفایل" /> : null}
        {user.isLoggedIn ? <CustomNavLink to="/logout" title="خروج" /> : null}
        {!user.isLoggedIn && isUserAdmin('Admin') ? (
          <CustomNavLink to="/logout" title="خروج" />
        ) : null}
        {user.isLoggedIn || isUserAdmin('Admin') ? null : (
          <CustomNavLink to="/auth/login" title="ورود / ثبت نام" />
        )}
        {/* <CustomNavLink to="/a" title="نام و نام خانوادگی" /> */}
      </li>
    </ul>
  );
};
