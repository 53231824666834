import { useEffect, useState } from 'react';
import { Button } from 'rsuite';
import { HandleNum2persian } from '../../../utils/helpers';
import { useUser } from '../../../contexts/user';
import { useNavigate, useParams } from 'react-router-dom';
import { APP_BASE_URL, getUsername } from '../../../core';
import { handleGetUserBoughtEvents } from '../../../api-handler';
// import { faIR } from 'date-fns-jalali/locale';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const RegisterEvent = (props: any) => {
  const { user, isUserAdmin } = useUser();
  const navigate = useNavigate();
  const { EventID } = useParams();

  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(props.event);
  const [hasBought, setHasBought] = useState(false);

  const initUserBought = async (eventId: any) => {
    setLoading(true);
    const res = await handleGetUserBoughtEvents();
    if (res.ok) {
      const filtered = res.data.filter((item: any) => item.WorkShopID == eventId);
      if (filtered.length) {
        setHasBought(true);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    setEvent(props.event);
    initUserBought(EventID);
  }, [props.event, EventID]);
  return (
    <div className="px-2 mb-8" id="register">
      <h1 className="md:text-h1 text-h4 text-center mt-8">{event.EventTitle}</h1>
      {/* <p className="text-center text-h5 mt-2">{event.Topics}</p> */}
      <div className="text-justify max-w-[1200px] mx-auto mt-4">
        <h5 className="text-h5">سرفصل ها</h5>
        <p>{event.Topics}</p>
        {/* <p className="text-[12px] md:text-[16px] px-4 md:px-6 py-2 md:py-4 bg-[#fff8] text-[#000] w-fit max-w-[90%] rounded-md mt-2">
          {event.Subtitle}
        </p> */}
        <div className="flex justify-center flex-wrap gap-4 mt-6">
          <div className="text-[12px] font-bold px-4  py-2.5 mb-2 bg-[var(--rs-primary-100)] text-[var(--rs-primary-800)] rounded-md">
            زمان: {event.DateAndTime}
            {/* زمان: {FormatDateTime(event.DateTime, faIR)} */}
          </div>
          <div className="text-[12px] font-bold px-4  py-2.5 mb-2 bg-[var(--rs-primary-100)] text-[var(--rs-primary-800)] rounded-md">
            مکان: {event.Location}
          </div>
        </div>
        <div className="flex justify-center flex-wrap gap-4 mt-3">
          <div className="text-[12px] font-bold px-4  py-2.5 mb-2 bg-[var(--rs-primary-100)] text-[var(--rs-primary-800)] rounded-md">
            مدرس/سخنران: {event.Speaker}
          </div>
          <div className="text-[12px] font-bold px-4  py-2.5 mb-2 bg-[var(--rs-primary-100)] text-[var(--rs-primary-800)] rounded-md">
            هزینه نام‌نویسی: {event.Fee?.toLocaleString()} تومان -{' '}
            {HandleNum2persian(event.Fee, 'تومان')}
          </div>
        </div>
        <div className="flex mt-2 flex-row items-center justify-center">
          {user.isLoggedIn && !isUserAdmin() ? (
            <Button
              appearance="primary"
              type="button"
              className="block"
              onClick={() => {
                // setIsAutoplay(false);
                // setSelectedEvent(event);
                // getBuyWorkshop(EventID);
                window.open(
                  `${APP_BASE_URL}/buyworkshop?WorkShopID=${EventID}&Username=${getUsername()}`
                );
              }}
              loading={loading}
              disabled={new Date() > new Date(event.RegistrationDeadline) || hasBought}
              size="lg">
              {new Date() > new Date(event.RegistrationDeadline)
                ? 'مهلت نام‌نویسی پایان یافته'
                : hasBought
                  ? 'شما قبلا در رویداد نام‌نویسی کردید'
                  : 'پرداخت و نام‌نویسی در رویداد'}
            </Button>
          ) : (
            <Button
              appearance="primary"
              type="button"
              className="block"
              onClick={() => {
                navigate(`/auth/login?redirect=/events/${EventID}`);
                // setIsAutoplay(false);
                // setSelectedEvent(event);
              }}
              size="lg">
              برای شرکت در رویداد ابتدا وارد شوید
            </Button>
          )}
        </div>
      </div>
      {/* <RegisterEventForm event={props.event} /> */}
    </div>
  );
};
