import { appNetworkManager } from './network-manager';

export const putAny = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any,
  selector: string,
  selectorVal: string | number,
  tableName: string
) => {
  return appNetworkManager.put(`/updateelement?Selector=${selector}&Tablename=${tableName}`, {
    [selector]: selectorVal,
    ...payload
  });
};
