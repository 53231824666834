import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// import { lazy } from 'react';

import { AuthGuard } from './guards/_auth-guard';
import { AdminAuthGuard } from './guards/_admin-guard copy';
import { Layout } from './layouts/layout';
import { FullLayout } from './layouts/full-layout';
import { LayoutFullWithoutBox } from './layouts/layout-full-without-box';

import { ConsultantPersonal } from '../pages/consultants/consultant-personal';
import { ConsultantWorkBackground } from '../pages/consultants/consultant-work-background';
import { ConsultantEducationBackground } from '../pages/consultants/consultant-education-background';
import { ConsultantConsultationBackground } from '../pages/consultants/consultant-consultation-background';
import { ConsultantSocial } from '../pages/consultants/consultant-social';
import { Auth } from '../pages/auth/auth';
import { ChooseRole } from '../pages/choose-role/choose-role';
import { ConsultantTnC } from '../pages/consultants/consultant-tnc';
import { ConsultantMeetingReport } from '../pages/consult/consultant-meeting-report';
import { Logout } from './../pages/auth/logout';
import { ConsulteeMeetingReport } from '../pages/consult/consultee-meeting-report';
// import { AdminRegisterConsultant } from '../pages/admin/consultants/register-consultatn';
import { AdminRegisterConsultantList } from '../pages/admin/consultants/consultants-register-list';
import { AdminRegisterConsultantSingle } from '../pages/admin/consultants/consultants-register-single';
import { ConsultantsList } from '../pages/public/consultants/consultants-list';
import { SingleConsultant } from '../pages/public/consultants/single-consultant';
import { ConsultRequests } from '../pages/consult/consult-requests';
import { SetConsultSession } from '../pages/consult/set-consult-session';
// import { LayoutWithoutBox } from './layouts/layout-without-box';
import { SeekerPersonal } from '../pages/seekers/seeker-personal';
import { SeekerWorkBackground } from '../pages/seekers/seeker-work-background';
import { SeekerEducationBackground } from '../pages/seekers/seeker-education-background';
import { SeekerCourseBackground } from '../pages/seekers/seeker-course-background';
import { SeekerTnC } from '../pages/seekers/seeker-tnc';
import { AdminRegisterSeekerList } from '../pages/admin/seekers/seekers-register-list';
import { AdminRegisterSeekerSingle } from '../pages/admin/seekers/seeker-register-single';
import { SeekerDesire } from '../pages/seekers/seeker-desiere';
import { CorporationPersonal } from '../pages/corporation/corporation-personal';
import { CorporationIntro } from '../pages/corporation/corporation-intro';
import { CorporationGood } from '../pages/corporation/corporation-good';
import { CorporationService } from '../pages/corporation/corporation-service';
import { CorporationTnC } from '../pages/corporation/corporation-tnc';
import { AdminRegisterCorporationList } from '../pages/admin/corporation/corporation-register-list';
import { AdminRegisterCorporationSingle } from '../pages/admin/corporation/corporation-register-single';
import { SingleSeeker } from '../pages/public/seekers/single-seeker';
import { CorporationsList } from '../pages/public/corporations/corporations-list';
import { SeekersList } from '../pages/public/seekers/seekers-list';
import { SingleCorporation } from '../pages/public/corporations/single-corporations';
import { GuestGuard } from './guards/_guest-guard';
import { UserProfile } from '../pages/user/profile/profile';
import { LayoutFullWithoutPadding } from './layouts/layout-full-without-padding';
import { AdminProfiles } from '../pages/admin/Profiles/profiles-list';
import { SeekerSkillBackground } from '../pages/seekers/seeker-skill-background';
import { AdminCommentsList } from '../pages/admin/comments/comments-list';
import { AdminCommentSingle } from '../pages/admin/comments/comment-single';
import { FounderPersonal } from '../pages/forms/founder/founder';
import { FounderGood } from '../pages/forms/founder/founder-good';
import { FounderService } from '../pages/forms/founder/founder-service';
// import { EmptyLayout } from './layouts/empty-layout';
import { FounderTnC } from './../pages/forms/founder/founder-tnc';
import { AdminRegisterFounderList } from '../pages/admin/founder/founder-register-list';
import { AdminRegisterFounderSingle } from '../pages/admin/founder/founder-register-single';
import { AdminRegisterOrganizationalProjectsList } from '../pages/admin/organizational-projects/organizational-projects-register-list';
import { AdminRegisterOrganizationalProjectsSingle } from './../pages/admin/organizational-projects/organizational-projects-register-single';
import { OrganizationalProjectsPersonal } from '../pages/forms/organizational-projects/organizational-projects';
import { OrganizationalProjectsGood } from '../pages/forms/organizational-projects/organizational-projects-good';
import { OrganizationalProjectsTnC } from '../pages/forms/organizational-projects/organizational-projects-tnc';
import { OrganizationalProjectsService } from '../pages/forms/organizational-projects/organizational-projects-service';
import { OrganizationalTrainingsPersonal } from '../pages/forms/organizational-trainings/organizational-trainings';
import { OrganizationalTrainingsGood } from '../pages/forms/organizational-trainings/organizational-trainings-good';
import { OrganizationalTrainingsTnC } from '../pages/forms/organizational-trainings/organizational-trainings-tnc';
import { OrganizationalTrainingsService } from '../pages/forms/organizational-trainings/organizational-trainings-service';
import { AdminRegisterOrganizationalTrainingsList } from '../pages/admin/organizational-trainings/organizational-trainings-register-list';
import { AdminRegisterOrganizationalTrainingsSingle } from './../pages/admin/organizational-trainings/organizational-trainings-register-single';
import { MarketingPersonal } from '../pages/forms/marketing/marketing';
import { MarketingGood } from '../pages/forms/marketing/marketing-good';
import { MarketingTnC } from '../pages/forms/marketing/marketing-tnc';
import { MarketingService } from '../pages/forms/marketing/marketing-service';
import { AdminRegisterMarketingList } from '../pages/admin/marketing/marketing-register-list';
import { AdminRegisterMarketingSingle } from './../pages/admin/marketing/marketing-register-single';
import { InqueryPersonal } from '../pages/forms/inquery/inquery';
import { InqueryGood } from '../pages/forms/inquery/inquery-good';
import { InqueryTnC } from '../pages/forms/inquery/inquery-tnc';
import { InqueryService } from '../pages/forms/inquery/inquery-service';
import { AdminRegisterInqueryList } from '../pages/admin/inquery/inquery-register-list';
import { AdminRegisterInquerySingle } from './../pages/admin/inquery/inquery-register-single';
import { IdeaPersonal } from '../pages/forms/idea/idea';
import { IdeaGood } from '../pages/forms/idea/idea-good';
import { IdeaTnC } from '../pages/forms/idea/idea-tnc';
import { IdeaService } from '../pages/forms/idea/idea-service';
import { AdminRegisterIdeaList } from '../pages/admin/idea/idea-register-list';
import { AdminRegisterIdeaSingle } from './../pages/admin/idea/idea-register-single';
import { AdminRequests } from '../pages/admin/proxy/requests/requests';
import { AdminRolesRequests } from '../pages/admin/proxy/role-requests/requests';
import { ProxyPages } from '../pages/proxy-pages/proxy-pages';
import { AdminRegisterRecruitmentSingle } from '../pages/admin/recruitment/recruitment-register-single';
import { AdminRegisterRecruitmentList } from '../pages/admin/recruitment/recruitment-register-list';
import { RecruitmentPersonal } from '../pages/forms/recruitment/recruitment';
import { RecruitmentGood } from '../pages/forms/recruitment/recruitment-good';
import { RecruitmentService } from '../pages/forms/recruitment/recruitment-service';
import { RecruitmentTnC } from '../pages/forms/recruitment/recruitment-tnc';
import { InvestmentTnC } from '../pages/forms/investment/investment-tnc';
import { InvestmentService } from '../pages/forms/investment/investment-service';
import { InvestmentGood } from '../pages/forms/investment/investment-good';
import { InvestmentPersonal } from '../pages/forms/investment/investment';
import { AdminRegisterInvestmentList } from '../pages/admin/investment/investment-register-list';
import { AdminRegisterInvestmentSingle } from '../pages/admin/investment/investment-register-single';
import { Events } from '../pages/public/events/events';
import { Event } from '../pages/public/events/event';
import { AfterPayment } from './../pages/public/events/after-payment';
import { AdminWorkshopUsersList } from '../pages/admin/workshop-users/workshop-users-list';
// lazy(() => import('./HeavyComponent'));
interface Props {
  name: string;
}

const Dummy = ({ name }: Props) => <p className="text-center">{name}</p>;
const CustomNavigator = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, [navigate, to]);
  return <></>;
};

export const ROUTES = {
  roles: {
    reqConsultant: {
      root: '/consultant',
      personal: '/consultant/personal',
      workBackground: '/consultant/work',
      educationBackground: '/consultant/education',
      consultationBackground: '/consultant/consultation',
      social: '/consultant/social',
      tnc: '/consultant/tnc'
    },
    reqSeeker: {
      root: '/seeker',
      personal: '/seeker/personal',
      workBackground: '/seeker/work',
      educationBackground: '/seeker/education',
      courseBackground: '/seeker/course',
      skillBackground: '/seeker/skill',
      desire: '/seeker/desire',
      tnc: '/seeker/tnc'
    },
    reqCorporation: {
      root: '/corporation',
      personal: '/corporation/personal',
      intro: '/corporation/intro',
      good: '/corporation/good',
      service: '/corporation/service',
      tnc: '/corporation/tnc'
    },
    reqFounder: {
      root: '/founder',
      personal: '/founder/personal',
      good: '/founder/good',
      service: '/founder/service',
      tnc: '/founder/tnc',
      list: '/founder/requests'
    },
    reqOrganizationalProjects: {
      root: '/organizational-projects',
      personal: '/organizational-projects/personal',
      good: '/organizational-projects/good',
      service: '/organizational-projects/service',
      tnc: '/organizational-projects/tnc',
      list: '/organizational-projects/requests'
    },
    reqOrganizationalTrainings: {
      root: '/organizational-trainings',
      personal: '/organizational-trainings/personal',
      good: '/organizational-trainings/good',
      service: '/organizational-trainings/service',
      tnc: '/organizational-trainings/tnc',
      list: '/organizational-trainings/requests'
    },
    reqMarketing: {
      root: '/marketing',
      personal: '/marketing/personal',
      good: '/marketing/good',
      service: '/marketing/service',
      tnc: '/marketing/tnc',
      list: '/marketing/requests'
    },
    reqInquery: {
      root: '/inquery',
      personal: '/inquery/personal',
      good: '/inquery/good',
      service: '/inquery/service',
      tnc: '/inquery/tnc',
      list: '/inquery/requests'
    },
    reqIdea: {
      root: '/idea',
      personal: '/idea/personal',
      good: '/idea/good',
      service: '/idea/service',
      tnc: '/idea/tnc',
      list: '/idea/requests'
    },
    reqRecruitment: {
      root: '/recruitment',
      personal: '/recruitment/personal',
      good: '/recruitment/good',
      service: '/recruitment/service',
      tnc: '/recruitment/tnc',
      list: '/recruitment/requests'
    },
    reqInvestment: {
      root: '/investment',
      personal: '/investment/personal',
      good: '/investment/good',
      service: '/investment/service',
      tnc: '/investment/tnc',
      list: '/investment/requests'
    }
  },
  events: {
    root: '/events'
  }
};

export const routes = (_isPing: boolean) => [
  {
    path: '/',
    element: (
      <AuthGuard>
        <CustomNavigator to="/choose-role" />
      </AuthGuard>
    )
  },

  {
    path: ROUTES.roles.reqInvestment.root,
    children: [
      {
        path: ROUTES.roles.reqInvestment.list,
        element: (
          <AuthGuard>
            <FullLayout sidebar={{ page: 'default', step: 1 }}>لیست</FullLayout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqInvestment.personal,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'idea', step: 1 }}>
              <InvestmentPersonal />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqInvestment.good,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'idea', step: 1 }}>
              <InvestmentGood isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqInvestment.service,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'idea', step: 1 }}>
              <InvestmentService isAdmin={false} />
              <></>
            </Layout>
          </AuthGuard>
        )
      },

      {
        path: ROUTES.roles.reqInvestment.tnc,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'idea', step: 2 }}>
              <InvestmentTnC />
            </Layout>
          </AuthGuard>
        )
      }
    ]
  },

  {
    path: ROUTES.roles.reqRecruitment.root,
    children: [
      {
        path: ROUTES.roles.reqRecruitment.list,
        element: (
          <AuthGuard>
            <FullLayout sidebar={{ page: 'default', step: 1 }}>لیست</FullLayout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqRecruitment.personal,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 1 }}>
              <RecruitmentPersonal />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqRecruitment.good,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 2 }}>
              <RecruitmentGood isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqRecruitment.service,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 3 }}>
              <RecruitmentService isAdmin={false} />
              <></>
            </Layout>
          </AuthGuard>
        )
      },

      {
        path: ROUTES.roles.reqRecruitment.tnc,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 4 }}>
              <RecruitmentTnC />
            </Layout>
          </AuthGuard>
        )
      }
    ]
  },

  {
    path: ROUTES.roles.reqIdea.root,
    children: [
      {
        path: ROUTES.roles.reqIdea.list,
        element: (
          <AuthGuard>
            <FullLayout sidebar={{ page: 'default', step: 1 }}>لیست</FullLayout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqIdea.personal,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'idea', step: 1 }}>
              <IdeaPersonal />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqIdea.good,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'idea', step: 1 }}>
              <IdeaGood isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqIdea.service,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'idea', step: 1 }}>
              <IdeaService isAdmin={false} />
              <></>
            </Layout>
          </AuthGuard>
        )
      },

      {
        path: ROUTES.roles.reqIdea.tnc,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'idea', step: 2 }}>
              <IdeaTnC />
            </Layout>
          </AuthGuard>
        )
      }
    ]
  },

  {
    path: ROUTES.roles.reqInquery.root,
    children: [
      {
        path: ROUTES.roles.reqInquery.list,
        element: (
          <AuthGuard>
            <FullLayout sidebar={{ page: 'default', step: 1 }}>لیست</FullLayout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqInquery.personal,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 1 }}>
              <InqueryPersonal />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqInquery.good,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 2 }}>
              <InqueryGood isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqInquery.service,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 3 }}>
              <InqueryService isAdmin={false} />
              <></>
            </Layout>
          </AuthGuard>
        )
      },

      {
        path: ROUTES.roles.reqInquery.tnc,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 4 }}>
              <InqueryTnC />
            </Layout>
          </AuthGuard>
        )
      }
    ]
  },

  {
    path: ROUTES.roles.reqMarketing.root,
    children: [
      {
        path: ROUTES.roles.reqMarketing.list,
        element: (
          <AuthGuard>
            <FullLayout sidebar={{ page: 'default', step: 1 }}>لیست</FullLayout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqMarketing.personal,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 1 }}>
              <MarketingPersonal />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqMarketing.good,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 2 }}>
              <MarketingGood isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqMarketing.service,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 3 }}>
              <MarketingService isAdmin={false} />
              <></>
            </Layout>
          </AuthGuard>
        )
      },

      {
        path: ROUTES.roles.reqMarketing.tnc,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 4 }}>
              <MarketingTnC />
            </Layout>
          </AuthGuard>
        )
      }
    ]
  },

  {
    path: ROUTES.roles.reqOrganizationalTrainings.root,
    children: [
      {
        path: ROUTES.roles.reqOrganizationalTrainings.list,
        element: (
          <AuthGuard>
            <FullLayout sidebar={{ page: 'default', step: 1 }}>لیست</FullLayout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqOrganizationalTrainings.personal,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 1 }}>
              <OrganizationalTrainingsPersonal />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqOrganizationalTrainings.good,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 2 }}>
              <OrganizationalTrainingsGood isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqOrganizationalTrainings.service,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 3 }}>
              <OrganizationalTrainingsService isAdmin={false} />
              <></>
            </Layout>
          </AuthGuard>
        )
      },

      {
        path: ROUTES.roles.reqOrganizationalTrainings.tnc,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 4 }}>
              <OrganizationalTrainingsTnC />
            </Layout>
          </AuthGuard>
        )
      }
    ]
  },

  {
    path: ROUTES.roles.reqOrganizationalProjects.root,
    children: [
      {
        path: ROUTES.roles.reqOrganizationalProjects.list,
        element: (
          <AuthGuard>
            <FullLayout sidebar={{ page: 'default', step: 1 }}>لیست</FullLayout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqOrganizationalProjects.personal,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 1 }}>
              <OrganizationalProjectsPersonal />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqOrganizationalProjects.good,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 2 }}>
              <OrganizationalProjectsGood isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqOrganizationalProjects.service,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 3 }}>
              <OrganizationalProjectsService isAdmin={false} />
              <></>
            </Layout>
          </AuthGuard>
        )
      },

      {
        path: ROUTES.roles.reqOrganizationalProjects.tnc,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 4 }}>
              <OrganizationalProjectsTnC />
            </Layout>
          </AuthGuard>
        )
      }
    ]
  },

  {
    path: ROUTES.roles.reqFounder.root,
    children: [
      {
        path: ROUTES.roles.reqFounder.list,
        element: (
          <AuthGuard>
            <FullLayout sidebar={{ page: 'default', step: 1 }}>لیست</FullLayout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqFounder.personal,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 1 }}>
              <FounderPersonal />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqFounder.good,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 2 }}>
              <FounderGood isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqFounder.service,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 3 }}>
              <></>
              <FounderService isAdmin={false} />
              <></>
            </Layout>
          </AuthGuard>
        )
      },

      {
        path: ROUTES.roles.reqFounder.tnc,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'founder', step: 4 }}>
              <FounderTnC />
            </Layout>
          </AuthGuard>
        )
      }
    ]
  },

  {
    path: ROUTES.roles.reqCorporation.root,
    children: [
      {
        path: ROUTES.roles.reqCorporation.personal,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'corporation', step: 1 }}>
              <CorporationPersonal />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqCorporation.intro,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'corporation', step: 2 }}>
              <CorporationIntro />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqCorporation.good,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'corporation', step: 3 }}>
              <CorporationGood isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqCorporation.service,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'corporation', step: 4 }}>
              <CorporationService isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },

      {
        path: ROUTES.roles.reqCorporation.tnc,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'corporation', step: 5 }}>
              <CorporationTnC />
            </Layout>
          </AuthGuard>
        )
      }
    ]
  },

  {
    path: ROUTES.roles.reqSeeker.root,
    children: [
      {
        path: ROUTES.roles.reqSeeker.personal,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'seeker', step: 1 }}>
              <SeekerPersonal />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqSeeker.workBackground,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'seeker', step: 2 }}>
              <SeekerWorkBackground isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqSeeker.educationBackground,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'seeker', step: 3 }}>
              <SeekerEducationBackground isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqSeeker.courseBackground,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'seeker', step: 4 }}>
              <SeekerCourseBackground isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqSeeker.skillBackground,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'seeker', step: 5 }}>
              <SeekerSkillBackground isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqSeeker.desire,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'seeker', step: 6 }}>
              <SeekerDesire isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqSeeker.tnc,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'seeker', step: 7 }}>
              <SeekerTnC />
            </Layout>
          </AuthGuard>
        )
      }
    ]
  },
  {
    path: ROUTES.roles.reqConsultant.root,
    children: [
      {
        path: ROUTES.roles.reqConsultant.personal,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'consultant', step: 1 }}>
              <ConsultantPersonal />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqConsultant.workBackground,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'consultant', step: 2 }}>
              <ConsultantWorkBackground isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqConsultant.educationBackground,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'consultant', step: 3 }}>
              <ConsultantEducationBackground isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqConsultant.consultationBackground,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'consultant', step: 4 }}>
              <ConsultantConsultationBackground isAdmin={false} />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqConsultant.social,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'consultant', step: 5 }}>
              <ConsultantSocial />
            </Layout>
          </AuthGuard>
        )
      },
      {
        path: ROUTES.roles.reqConsultant.tnc,
        element: (
          <AuthGuard>
            <Layout sidebar={{ page: 'consultant', step: 6 }}>
              <ConsultantTnC />
            </Layout>
          </AuthGuard>
        )
      }
    ]
  },

  {
    path: '/consultant/requests',
    element: (
      <AuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <ConsultRequests />
        </FullLayout>
      </AuthGuard>
    )
  },
  {
    path: '/user/requests',
    element: (
      <AuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <ConsultRequests />
        </FullLayout>
      </AuthGuard>
    )
  },
  {
    path: '/consultant/meeting-report/:id',
    element: (
      <AuthGuard>
        <Layout sidebar={{ page: 'consultant', step: 6 }}>
          <ConsultantMeetingReport />
        </Layout>
      </AuthGuard>
    )
  },
  {
    path: '/user/meeting-report/:id',
    element: (
      <AuthGuard>
        <Layout sidebar={{ page: 'default', step: 1 }}>
          <ConsulteeMeetingReport />
        </Layout>
      </AuthGuard>
    )
  },
  // {
  //   path: '/user/meeting-reports',
  //   element: (
  //     <Layout sidebar={{ page: 'default', step: 1 }}>
  //       <MeetingReports />
  //     </Layout>
  //   )
  // },
  {
    path: '/user/consult-sessions/:id',
    element: (
      <AuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <SetConsultSession asConsultant={false} />
        </FullLayout>
      </AuthGuard>
    )
  },
  {
    path: '/consultant/consult-sessions/:id',
    element: (
      <AuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <SetConsultSession asConsultant={true} />
        </FullLayout>
      </AuthGuard>
    )
  },
  {
    path: '/user/profile',
    element: (
      <AuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <UserProfile />
        </FullLayout>
      </AuthGuard>
    )
  },

  // Admin
  // {
  //   path: '/admin/register-consultant',
  //   element: (
  //     <Layout sidebar={{ page: 'default', step: 1 }}>
  //       <AdminRegisterConsultant />
  //     </Layout>
  //   )
  // },

  {
    path: '/admin/roles-requests',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRolesRequests />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/requests',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRequests />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-investment-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRegisterInvestmentList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-investment-single/:username',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <AdminRegisterInvestmentSingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-recruitment-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRegisterRecruitmentList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-recruitment-single/:username',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <AdminRegisterRecruitmentSingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-idea-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRegisterIdeaList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-idea-single/:username',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <AdminRegisterIdeaSingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-inquery-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRegisterInqueryList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-inquery-single/:username',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <AdminRegisterInquerySingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-marketing-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRegisterMarketingList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-marketing-single/:username',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <AdminRegisterMarketingSingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-organizational-trainings-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRegisterOrganizationalTrainingsList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-organizational-trainings-single/:username',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <AdminRegisterOrganizationalTrainingsSingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-organizational-projects-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRegisterOrganizationalProjectsList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-organizational-projects-single/:username',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <AdminRegisterOrganizationalProjectsSingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-founder-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRegisterFounderList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-founder-single/:username',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <AdminRegisterFounderSingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-consultant-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRegisterConsultantList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-consultant-single/:username',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <AdminRegisterConsultantSingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-seeker-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRegisterSeekerList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-seeker-single/:username',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <AdminRegisterSeekerSingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-corporation-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminRegisterCorporationList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/register-corporation-single/:username',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'default', step: 1 }}>
          <AdminRegisterCorporationSingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/profiles-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminProfiles />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/comment-single/:id/:reportId',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminCommentSingle />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/comments-list',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminCommentsList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/admin/events/:eventID/:eventName',
    element: (
      <AdminAuthGuard>
        <FullLayout sidebar={{ page: 'admin', step: 1 }}>
          <AdminWorkshopUsersList />
        </FullLayout>
      </AdminAuthGuard>
    )
  },
  {
    path: '/auth/admin',
    element: <Auth isAdminMode={true} />
  },
  {
    path: '/auth/login',
    element: <Auth isAdminMode={false} />
  },
  {
    path: '/logout',
    element: <Logout />
  },
  {
    path: '/choose-role',
    element: (
      <AuthGuard>
        <FullLayout sidebar={{ page: 'chooseRole', step: 1 }}>
          <ChooseRole />
        </FullLayout>
      </AuthGuard>
    )
  },
  // public
  {
    path: '/consultants',
    element: (
      <GuestGuard>
        <LayoutFullWithoutBox sidebar={{ page: 'default', step: 1 }}>
          <ConsultantsList />
        </LayoutFullWithoutBox>
      </GuestGuard>
    )
  },
  {
    path: '/consultants/:username',
    element: (
      <GuestGuard>
        <LayoutFullWithoutBox sidebar={{ page: 'default', step: 1 }}>
          <SingleConsultant />
        </LayoutFullWithoutBox>
      </GuestGuard>
    )
  },
  {
    path: '/seekers',
    element: (
      <GuestGuard>
        <LayoutFullWithoutBox sidebar={{ page: 'default', step: 1 }}>
          <SeekersList />
        </LayoutFullWithoutBox>
      </GuestGuard>
    )
  },
  {
    path: '/seekers/:username',
    element: (
      <GuestGuard>
        <LayoutFullWithoutBox sidebar={{ page: 'default', step: 1 }}>
          <SingleSeeker />
        </LayoutFullWithoutBox>
      </GuestGuard>
    )
  },
  {
    path: '/corporations',
    element: (
      <GuestGuard>
        <LayoutFullWithoutBox sidebar={{ page: 'default', step: 1 }}>
          <CorporationsList />
        </LayoutFullWithoutBox>
      </GuestGuard>
    )
  },
  {
    path: '/corporations/:username',
    element: (
      <GuestGuard>
        <LayoutFullWithoutPadding sidebar={{ page: 'default', step: 1 }}>
          <SingleCorporation />
        </LayoutFullWithoutPadding>
      </GuestGuard>
    )
  },

  {
    path: '/services/:service',
    element: (
      <GuestGuard>
        <LayoutFullWithoutPadding sidebar={{ page: 'default', step: 1 }}>
          <ProxyPages />
        </LayoutFullWithoutPadding>
      </GuestGuard>
    )
  },

  {
    path: ROUTES.events.root,
    element: (
      <GuestGuard>
        <LayoutFullWithoutPadding sidebar={{ page: 'default', step: 1 }}>
          <Events />
        </LayoutFullWithoutPadding>
      </GuestGuard>
    )
  },
  {
    path: `${ROUTES.events.root}/:EventID`,
    element: (
      <GuestGuard>
        <LayoutFullWithoutPadding sidebar={{ page: 'default', step: 1 }}>
          <Event />
        </LayoutFullWithoutPadding>
      </GuestGuard>
    )
  },
  {
    path: `/payment`,
    element: (
      <GuestGuard>
        <LayoutFullWithoutPadding sidebar={{ page: 'default', step: 1 }}>
          <AfterPayment />
        </LayoutFullWithoutPadding>
      </GuestGuard>
    )
  },
  {
    path: '*',
    element: (
      // <EmptyLayout>
      <Dummy name="404" />
      // </EmptyLayout>
    )
  }
];
