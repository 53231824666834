import { useState } from 'react';
import { LoginForm } from './components/login';
import { Card } from '../../components/basic/card';
import Logo from '../../assets/images/brand/rcg.png';

export const Auth = (props: { isAdminMode: boolean }) => {
  const [step, setStep] = useState({ action: 'login', mode: 'personal', role: 'user', data: {} });
  return (
    <div>
      <img
        src={Logo}
        alt="Logo"
        className="block mx-auto mb-[-20px] mt-16 xl:mt-22 max-w-[280px] w-full"
      />
      <Card className="mx-auto">
        <LoginForm step={step} setStep={setStep} isAdminMode={props.isAdminMode} />
      </Card>
    </div>
  );
};

export default Auth;
