import { putAny } from '../core';
import { showToast } from '../utils/toast';

export const handleUpdateAny = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any,
  selector: string,
  selectorVal: string | number,
  tableName: string
) => {
  try {
    const { data, status } = await putAny(payload, selector, selectorVal, tableName);
    if (status === 200) {
      console.log(data);
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
