import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const getUserProfilePicture = () => {
  return appNetworkManager.get(`/picture?Username=${getUsername()}`);
};
export const getUserProfilePictureByUsername = (username: string) => {
  return appNetworkManager.get(`/picture?Username=${username}`);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postUserProfilePicture = (payload: any) => {
  return appNetworkManager.post(`/picture`, { ...payload, Username: getUsername() });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putUserProfilePictureUser = (payload: any) => {
  return appNetworkManager.put(`/picture`, {
    ...payload,
    Username: getUsername(),
    Status: 'Pending'
  });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putUserProfilePictureAdmin = (payload: any) => {
  return appNetworkManager.put(`/picture`, {
    ...payload
  });
};
